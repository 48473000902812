export type TVideoUserState = {
  type: "video_join" | "video_leave";
  userId: number | undefined;
};

export type TLocalParticipant = {
  isMuted: boolean;
  hasCamera: boolean;
  isSharing: boolean;
  isSpaceSharing: boolean;
  blurredVideoBackground: boolean;
  imageVideoBackground: boolean;
  hasRaisedHand: boolean;
};

export type TAvailableDevice = {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
};

// list of available video effects this array will be used at toggleVideoBackground fn.
export enum AvailableVideoEffects {
  BlurredVideoBackground = "blurredVideoBackground",
  ImageVideoBackground = "imageVideoBackground",
}

export enum TVideoGrid {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export type TMessage = {
  type: EMessageType;
  data: {} | string;
};

export enum EMessageType {
  SPACE_SHARE_STARTED = "spaceShareStarted",
  SPACE_SHARE_STOPPED = "spaceShareStopped",
  RAISE_HAND = "raiseHand",
}
