import { Vector2 } from "three";
import create from "zustand";

type TInputService = {
  locked: boolean;
  start: (r3fCanvas: HTMLCanvasElement) => boolean;
  stop: () => boolean;
  isKeyDown: (keyName: TKeyName) => TIsDown;
  getMouseState: () => TMouseState;
  lockInput: () => void;
  enableInput: () => void;
};

type TKeyName = string;
type TIsDown = boolean;

type TKeyState = Map<TKeyName, TIsDown>;

type TMouseState = {
  position: Vector2;
  deltaPosition: Vector2;
  isDown: TIsDown;
};

const defaultMouseState = {
  position: new Vector2(),
  deltaPosition: new Vector2(),
  isDown: false,
};

// @ts-ignore
const inputService = create<TInputService>((set, get) => {
  let keyState: TKeyState | null = null;
  let mouseState: TMouseState = defaultMouseState;

  const canvasSize: Vector2 = new Vector2();

  const resetState = () => {
    keyState = new Map();
    mouseState = defaultMouseState;
  };

  let mouseMoveTimeout: any;
  const newMousePosition: Vector2 = new Vector2();

  const updateMouseState = (event) => {
    if (get().locked || !mouseState) return;

    if (mouseMoveTimeout) clearTimeout(mouseMoveTimeout);

    newMousePosition.set(event.offsetX, event.offsetY);

    // calc difference in pixel between new and current mouse position
    mouseState.deltaPosition.subVectors(newMousePosition, mouseState.position);
    // normalize mouse delta position between -1 and 1 on both axis
    mouseState.deltaPosition.divide(canvasSize);

    mouseState.position.copy(newMousePosition);

    // handle mouse move stop with a 60 fps tick ~= 67ms
    mouseMoveTimeout = setTimeout(
      () => mouseState?.deltaPosition.set(0.0, 0.0),
      67
    );
  };

  return {
    locked: false,

    start: (r3fCanvas) => {
      if (!r3fCanvas) return false;

      resetState();

      // set and handle canvas resize
      canvasSize.set(r3fCanvas.width, r3fCanvas.height);
      r3fCanvas.onresize = (event) =>
        canvasSize.set(r3fCanvas.width, r3fCanvas.height);

      // handle key up and down
      window.onkeydown = (event) => {
        keyState?.set(event.key.toLowerCase(), true);
      };
      window.onkeyup = (event) => {
        keyState?.set(event.key.toLowerCase(), false);
      };

      // set mouse position when reentering r3fCanvas
      r3fCanvas.onmouseenter = (event) =>
        mouseState?.position.set(event.offsetX, event.offsetY);
      // set mouse delta position to 0 when leaving canvas
      r3fCanvas.onmouseleave = () => mouseState?.deltaPosition.set(0.0, 0.0);

      // handle mouse down and up
      r3fCanvas.onmousedown = (event) => {
        if (!mouseState) return;

        // clear all input if user clicks with right mouse
        if (!event.button) {
          mouseState.isDown = true;
        } else keyState?.clear();
      };

      r3fCanvas.addEventListener("mousemove", (e) => {
        updateMouseState(e);
        if (get().getMouseState().isDown)
          document.body.style.cursor = "grabbing";
      });
      window.onmouseup = () => {
        if (!mouseState) return;

        mouseState.isDown = false;
        document.body.style.cursor = "auto";
      };

      set({ locked: false });
      return true;
    },
    stop: () => {
      return true;
    },
    isKeyDown: (name: string) => {
      const keyName: string = name.toLowerCase();

      if (!keyState?.has(keyName) || get().locked) return false;

      return keyState.get(keyName);
    },
    getMouseState: () => {
      return mouseState;
    },
    lockInput: () => {
      set({ locked: true });
    },
    enableInput: () => {
      set({ locked: false });
    },
  };
});

export default inputService;
