import create from "zustand";

import InfoNotification from "@/components/Dom/Notifications/InfoNotification";

import cmsService from "@/services/CmsService";
import debugService from "@/services/DebugService";
import {
  TDiscoverQuest,
  TDiscoverQuestAchievement,
  TDiscoverQuestAchievementResponse,
  TDiscoverQuestResponse,
  TDiscoverQuestState,
  TDiscoverTaskData,
  TDiscoverTaskState,
} from "@/services/DiscoverQuestService/types";
import moduleService from "@/services/ModuleService";
import navService, { EOverviewPanel } from "@/services/NavService";
import notificationService, {
  TNotification,
} from "@/services/NotificationService";
import { playerService, TPlayerData } from "@/services/PlayerService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import { appendSasParams } from "@/utils/Azure";
import { getImageUrl } from "@/utils/Strapi";

type TDiscoverQuestService = {
  quests: Map<TDiscoverQuest["id"], TDiscoverQuest> | null;
  currentQuestId: number | null;
  currentNotificationId: TNotification["id"] | null;
  showUi: boolean;

  startQuestIfExists: () => boolean;
  stopQuest: () => boolean;

  init: () => Promise<TDiscoverQuestService["quests"] | TDiscoverQuestResponse>;

  getUserAchievements: (
    userId: TUser["id"]
  ) => Promise<Array<TDiscoverQuestAchievement>>;

  flashUi: () => void;
};

const discoverQuestService = create<TDiscoverQuestService>((set, get) => {
  const parseDiscoverQuestData = (
    questsResponse: any
  ): TDiscoverQuestService["quests"] => {
    return new Map(
      questsResponse.map((quest) => {
        if (quest.tasks)
          quest.tasks = new Map(quest.tasks.map((task) => [task.id, task]));

        return [quest.id, quest];
      })
    );
  };

  const createQuestState = (
    quest: TDiscoverQuest
  ): TDiscoverQuestState | null => {
    if (!quest) return null;

    return {
      id: quest.id,
      completed: false,
      tasks: new Map<TDiscoverTaskData["id"], TDiscoverTaskState>(
        Array.from(quest.tasks.values()).map(({ id }) => [
          id,
          { id, completed: false },
        ])
      ),
    };
  };

  const initQuestState = (quest: TDiscoverQuest): boolean => {
    if (!quest) return false;

    // init quest state in player data and db
    const questInitState = createQuestState(quest) as TDiscoverQuestState;

    // this will only init the state if none is present for the current quest
    playerService.getState().updateQuestState(questInitState);

    return true;
  };

  const setTaskState = (state) => {
    const { id, completed } = state;
    const { currentQuestId, quests } = get();

    if (
      id === undefined ||
      completed === undefined ||
      !currentQuestId ||
      !quests
    )
      return false;

    // get all quests state from player data
    const { questState } = playerService.getState()
      .ownPlayerData as TPlayerData;
    if (!questState) return false;

    // check if quest state for current one is initialized
    const currentQuestState = questState.get(currentQuestId);
    if (!currentQuestState) return false;

    // update task state in current quest state
    currentQuestState.tasks.set(id, state);

    // set quest completed if all tasks are done
    if (
      Array.from(currentQuestState.tasks.values()).every(
        ({ completed }) => completed
      )
    )
      currentQuestState.completed = true;

    // update quest state in player data and persist in db
    playerService.getState().updateQuestState(currentQuestState);

    get().flashUi();

    if (currentQuestState.completed) {
      endQuest(quests.get(currentQuestId) as TDiscoverQuest);
    }

    return true;
  };

  const registerDiscoverEvents = () => {
    if (typeof window === "undefined") return;

    // register event handler for setting task state on event trigger
    window.addEventListener("MEDIA_AREA_CLICK", ({ detail }: CustomEvent) => {
      const { currentQuestId, quests } = get();
      if (!currentQuestId || !quests) return;

      const quest = quests.get(currentQuestId);

      if (!quest) return;

      for (const { event, id, relation } of quest.tasks.values()) {
        if (!event) {
          debugService
            .getState()
            .logError(
              `discoverQuestService::registerDiscoverEvents(): Invalid task event type = ${event}!`
            );
          return;
        }

        if (detail === relation) {
          setTaskState({ id, completed: true });
          break;
        }
      }
    });
  };

  registerDiscoverEvents();

  const setCurrentQuest = (quest: TDiscoverQuest): boolean => {
    if (!quest) return false;

    if (!initQuestState(quest)) return false;
    set({ currentQuestId: quest.id });
    get().flashUi();

    return true;
  };

  const startQuest = async (questId: TDiscoverQuest["id"]) => {
    const { quests, currentQuestId } = get();

    if (!quests || questId === currentQuestId) return false;

    // check if quest with id exists
    const quest = quests.get(questId);
    if (!quest) return false;

    const ownUser = userService.getState().ownUser as TUser;

    // start directly without showing notification if quest was started in past
    try {
      const ownPlayerData = await playerService
        .getState()
        .getPlayerData(ownUser.id);

      if (ownPlayerData) {
        playerService.setState({ ownPlayerData });

        const { questState } = ownPlayerData;

        if (questState) {
          const currentQuestState = questState.get(questId);

          if (!currentQuestState?.completed) return setCurrentQuest(quest);

          // return if quest was already completed
          return false;
        }
      }
    } catch (error) {
      debugService
        .getState()
        .logError(
          `discoverQuestService::startQuest(): Failed to get quest state from player data = ${error}`
        );
    }

    // set current quest and return if no start text or image exists
    const { startText, startImage } = quest;
    if (!startText || !startImage) return setCurrentQuest(quest);

    // show start notification for the quest
    appendSasParams(getImageUrl(startImage, "medium")).then((imageUrl) => {
      const currentNotificationId = notificationService
        .getState()
        .addNotification(
          <InfoNotification
            headline={`Hi ${ownUser.firstname}`}
            text={startText}
            subText={`Look for items with a star.`}
            buttonText={`Got it`}
            onButtonClick={() => {
              set({ currentNotificationId: null });
              setCurrentQuest(quest);
            }}
            imageUrl={imageUrl}
          />,
          {
            autoRemove: false,
            position: "bottom",
          }
        );

      set({ currentNotificationId });
    });

    return true;
  };
  const endQuest = (quest: TDiscoverQuest) => {
    setTimeout(() => {
      get().stopQuest();

      // set current quest and return if no start text or image exists
      const { endText, endImage, name } = quest;
      if (!endText || !endImage) return;

      // show end notification for the quest
      appendSasParams(getImageUrl(endImage, "medium")).then((imageUrl) =>
        notificationService.getState().addNotification(
          <InfoNotification
            headline={`Congratulations!`}
            text={endText}
            buttonText={`CLAIM YOUR REWARD`}
            onButtonClick={() => {
              if (name !== "Talent Tasks") return;

              navService.getState().setPanel(EOverviewPanel.PROFILE);
            }}
            imageUrl={imageUrl}
          />,
          {
            autoRemove: false,
            position: "bottom",
          }
        )
      );
    }, 3000);
  };

  return {
    quests: null,
    currentQuestId: null,
    showUi: false,
    currentNotificationId: null,

    // get all quests from the cms and parse into hash map data structure
    init: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const questsResponse = await cmsService
            .getState()
            .requestData("getDiscoverQuests");

          if (!questsResponse) {
            reject(TDiscoverQuestResponse.NO_QUESTS_FOUND);
            return;
          }

          set({
            quests: parseDiscoverQuestData(questsResponse),
          });

          resolve(get().quests);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },

    startQuestIfExists: () => {
      const currentModuleId = moduleService.getState().currentModuleId;
      const { quests } = get();

      if (!currentModuleId || !quests) return false;

      // automatically start quest for current module if one with with id exists
      for (const [questId, currentQuest] of quests)
        if (currentQuest.module.id === currentModuleId) {
          startQuest(questId);
          return true;
        }

      return false;
    },

    stopQuest: () => {
      const { currentNotificationId } = get();
      if (currentNotificationId)
        notificationService
          .getState()
          .removeNotification(currentNotificationId);

      set({ currentQuestId: null, showUi: false, currentNotificationId: null });
      return true;
    },

    getUserAchievements: (userId) => {
      return new Promise(async (resolve, reject) => {
        if (!userId) {
          reject(TDiscoverQuestAchievementResponse.INVALID_ARGUMENT);
          return;
        }

        try {
          const playerData = await playerService
            .getState()
            .getPlayerData(userId);
          const { quests } = get();

          if (!playerData?.questState || !quests) {
            debugService
              .getState()
              .logError(
                `discoverQuestService::getUserAchievements(): Failed getting user quest state!`
              );

            resolve([]);
            return;
          }

          // parse discover quest state into achievement data
          const state = Array.from(playerData.questState.values());
          const achievements = new Array<TDiscoverQuestAchievement>();

          for (const questState of state) {
            const quest = quests.get(questState.id) as TDiscoverQuest;
            if (!questState.completed) continue;

            const { id, name } = quest;

            achievements.push({
              id,
              name,
            });
          }

          resolve(achievements);
          return;
        } catch (error) {
          debugService
            .getState()
            .logError(
              `discoverQuestService::getUserAchievements(): Failed getting user quest state = ${error}`
            );

          reject(TDiscoverQuestAchievementResponse.NO_STATE);
          return;
        }
      });
    },

    flashUi: () => {
      set({ showUi: true });

      setTimeout(() => {
        set({ showUi: false });
      }, 3000);
    },
  };
});

export default discoverQuestService;
