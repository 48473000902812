import { TStrapiImage } from "@/types/StrapiImage";

import { TModule } from "@/services/ModuleService/types";
import { TMediaAreaData } from "@/services/SceneService";

export type TDiscoverEvent = "MEDIA_AREA_CLICK";

export type TDiscoverQuestAchievement = {
  id: TDiscoverQuest["id"];
  name: TDiscoverQuest["name"];
};

export enum TDiscoverQuestResponse {
  NO_QUESTS_FOUND = "No discover quests found!",
}

export enum TDiscoverQuestAchievementResponse {
  NO_STATE = "Failed to get user quest achievement state!",
  INVALID_ARGUMENT = "Invalid arguments!",
}

export type TDiscoverTaskState = {
  id: TDiscoverTaskData["id"];
  completed: boolean;
};

export type TDiscoverQuestState = {
  id: TDiscoverQuest["id"];
  completed: boolean;
  tasks: Map<TDiscoverTaskData["id"], TDiscoverTaskState>;
};

export type TDiscoverTaskData = {
  id: number;
  description: string;
  event: TDiscoverEvent;
  relation: TMediaAreaData["name"];
};

export type TDiscoverQuest = {
  id: number;

  name: string;

  tasks: Map<TDiscoverTaskData["id"], TDiscoverTaskData>;

  startText?: string | null;
  startImage?: TStrapiImage | null;

  endText?: string | null;
  endImage?: TStrapiImage | null;

  completed: boolean;

  module: TModule;
};
