import {
  Box3,
  Box3Helper,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Vector3,
  Matrix4,
  Color,
  Float32BufferAttribute,
  BufferGeometry,
  Plane,
  MeshLambertMaterial,
  //MeshLambertMaterial,
} from "three";

const DEBUG_AS_WIREFRAME = false;

export class Node {
  debugObject: Object3D = new Object3D();
  boundingBox: Box3 = new Box3();
  childNodes: Array<Node> = [];
  vertices: Array<Vector3> = [];
  planes: Array<Plane> = [];
  debugGeometryCreated: boolean = false;
  constructor(boundingBox: Box3, vertices: Array<Vector3>) {
    this.boundingBox = boundingBox;
    this.vertices = vertices;
    this.debugObject.name = "Node";
  }

  // for one axis find min, center and max -> return in array ds
  getAxisParameters = (axis: string): [number, number, number] => {
    return [
      this.boundingBox.min[`${axis}`],
      this.boundingBox.min[`${axis}`] +
        (this.boundingBox.max[`${axis}`] - this.boundingBox.min[`${axis}`]) / 2,
      this.boundingBox.max[`${axis}`],
    ];
  };

  addDebugGeometry = () => {
    // todo: check if in DEBUG mode

    if (!this.debugGeometryCreated) {
      // add bounding box helper (visualises octree cells)
      const boundingBoxHelper = new Box3Helper(
        this.boundingBox,
        new Color(0x8b0000)
      );
      boundingBoxHelper.visible = false;

      this.debugObject.add(boundingBoxHelper);
      const positionAttribute = new Float32BufferAttribute(
        this.vertices.reduce((acc: Array<number>, v: Vector3) => {
          acc.push(v.x);
          acc.push(v.y);
          acc.push(v.z);
          return acc;
        }, []),
        3
      );
      const geom = new BufferGeometry();
      geom.setAttribute("position", positionAttribute);
      geom.computeVertexNormals();
      const debugMesh = new Mesh(
        geom,
        new MeshLambertMaterial({
          color: 0xff0000,
          wireframe: DEBUG_AS_WIREFRAME,
        })
      );

      this.debugObject.add(debugMesh);
      this.debugGeometryCreated = true;
    }
  };

  // a single iteration clips one face,
  // create new vertex based on input -> that is on the edge of the bounding box
  // calculate outpoint of vector between v0, v1 with plane normal p
  calcIntersectionLineWithPlane = (
    v0: Vector3,
    v1: Vector3,
    p: Vector3,
    s: number
  ) => {
    const d0 = v0.dot(p) - s;
    const d1 = v1.dot(p) - s;
    // length to 1 x y z between 0 and 1?
    const s0 = d0 / (d0 - d1);
    const v = new Vector3(
      v0.x + s0 * (v1.x - v0.x),
      v0.y + s0 * (v1.y - v0.y),
      v0.z + s0 * (v1.z - v0.z)
    );
    return v;
  };

  clipGeometry = (
    vertices: Array<Vector3>,
    bbCenter: Vector3,
    size: Vector3,
    planeNormal: Vector3
  ): Array<Vector3> => {
    // convert bb center to mat4 and calc inverse -> translation mat4 into bb space
    const projectorMatrix = new Matrix4();
    projectorMatrix.setPosition(bbCenter);
    const projectorMatrixInverse = new Matrix4();
    projectorMatrixInverse.copy(projectorMatrix);
    projectorMatrixInverse.invert();

    // convert vertices into coordinate system of bb
    const inVertices = vertices.map((v) => {
      const result = v.clone();
      result.applyMatrix4(projectorMatrixInverse);
      return result;
    });

    // output vertices
    const outVertices = new Array<Vector3>();

    // todo: rename s to something meaningful
    const s = 0.5 * Math.abs(size.dot(planeNormal));

    // loop over triangles of transformed vertices
    for (let i = 0; i < inVertices.length; i += 3) {
      let total = 0;
      // points for clipping
      let nV1, nV2, nV3, nV4;
      // calc if point is in clip plane
      const d1 = inVertices[i + 0].dot(planeNormal) - s;
      const d2 = inVertices[`${i + 1}`].dot(planeNormal) - s;
      const d3 = inVertices[`${i + 2}`].dot(planeNormal) - s;
      // set flags for vertices
      const v1Out = d1 > 0;
      const v2Out = d2 > 0;
      const v3Out = d3 > 0;
      // calculate, how many vertices of the face lie outside of the clipping plane
      total = (v1Out ? 1 : 0) + (v2Out ? 1 : 0) + (v3Out ? 1 : 0);

      switch (total) {
        case 0: {
          // the entire face lies inside of the plane, no clipping needed
          outVertices.push(inVertices[`${i}`]);
          outVertices.push(inVertices[`${i + 1}`]);
          outVertices.push(inVertices[`${i + 2}`]);
          break;
        }
        case 1: {
          // one vertex lies outside of the plane, perform clipping
          if (v1Out) {
            nV1 = inVertices[`${i + 1}`];
            nV2 = inVertices[`${i + 2}`];
            nV3 = this.calcIntersectionLineWithPlane(
              inVertices[`${i}`],
              nV1,
              planeNormal,
              s
            );
            nV4 = this.calcIntersectionLineWithPlane(
              inVertices[`${i}`],
              nV2,
              planeNormal,
              s
            );
          }
          if (v2Out) {
            nV1 = inVertices[`${i}`];
            nV2 = inVertices[`${i + 2}`];
            nV3 = this.calcIntersectionLineWithPlane(
              inVertices[`${i + 1}`],
              nV1,
              planeNormal,
              s
            );
            nV4 = this.calcIntersectionLineWithPlane(
              inVertices[`${i + 1}`],
              nV2,
              planeNormal,
              s
            );
            outVertices.push(nV3);
            outVertices.push(nV2.clone());
            outVertices.push(nV1.clone());
            outVertices.push(nV2.clone());
            outVertices.push(nV3.clone());
            outVertices.push(nV4);
            break;
          }
          if (v3Out) {
            nV1 = inVertices[`${i}`];
            nV2 = inVertices[`${i + 1}`];
            nV3 = this.calcIntersectionLineWithPlane(
              inVertices[`${i + 2}`],
              nV1,
              planeNormal,
              s
            );
            nV4 = this.calcIntersectionLineWithPlane(
              inVertices[`${i + 2}`],
              nV2,
              planeNormal,
              s
            );
          }
          outVertices.push(nV1.clone());
          outVertices.push(nV2.clone());
          outVertices.push(nV3);
          outVertices.push(nV4);
          outVertices.push(nV3.clone());
          outVertices.push(nV2.clone());
          break;
        }
        case 2: {
          // two vertices lies outside of the plane, perform clipping
          if (!v1Out) {
            nV1 = inVertices[`${i}`].clone();
            nV2 = this.calcIntersectionLineWithPlane(
              nV1,
              inVertices[`${i + 1}`],
              planeNormal,
              s
            );
            nV3 = this.calcIntersectionLineWithPlane(
              nV1,
              inVertices[`${i + 2}`],
              planeNormal,
              s
            );
            outVertices.push(nV1);
            outVertices.push(nV2);
            outVertices.push(nV3);
          }
          if (!v2Out) {
            nV1 = inVertices[`${i + 1}`].clone();
            nV2 = this.calcIntersectionLineWithPlane(
              nV1,
              inVertices[`${i + 2}`],
              planeNormal,
              s
            );
            nV3 = this.calcIntersectionLineWithPlane(
              nV1,
              inVertices[`${i}`],
              planeNormal,
              s
            );
            outVertices.push(nV1);
            outVertices.push(nV2);
            outVertices.push(nV3);
          }
          if (!v3Out) {
            nV1 = inVertices[`${i + 2}`].clone();
            nV2 = this.calcIntersectionLineWithPlane(
              nV1,
              inVertices[`${i}`],
              planeNormal,
              s
            );
            nV3 = this.calcIntersectionLineWithPlane(
              nV1,
              inVertices[`${i + 1}`],
              planeNormal,
              s
            );
            outVertices.push(nV1);
            outVertices.push(nV2);
            outVertices.push(nV3);
          }
          break;
        }
        case 3: {
          // the entire face lies outside of the plane, so let's discard the corresponding vertices
          break;
        }
      }
    }

    return outVertices.map((o) => {
      // project verts back to bb center pos world pos
      o.applyMatrix4(projectorMatrix);
      return o;
    });
  };

  split = (depth: number) => {
    const axisParamtersX = this.getAxisParameters("x");
    const axisParamtersY = this.getAxisParameters("y");
    const axisParamtersZ = this.getAxisParameters("z");
    const v0 = new Vector3();
    const v1 = new Vector3();
    const plane = new Vector3();

    for (let x = 0; x < 2; x++) {
      const bbx = new Box3(
        new Vector3(
          axisParamtersX[`${x}`],
          axisParamtersY[0],
          axisParamtersZ[0]
        ),
        new Vector3(
          axisParamtersX[`${x + 1}`],
          axisParamtersY[2],
          axisParamtersZ[2]
        )
      );
      const xVertices = this.clipGeometry(
        this.vertices,
        bbx.getCenter(v0),
        bbx.getSize(v1),
        plane.set(x === 0 ? 1 : -1, 0, 0)
      );
      for (let y = 0; y < 2; y++) {
        const bby = new Box3(
          new Vector3(
            axisParamtersX[`${x}`],
            axisParamtersY[`${y}`],
            axisParamtersZ[0]
          ),
          new Vector3(
            axisParamtersX[`${x + 1}`],
            axisParamtersY[`${y + 1}`],
            axisParamtersZ[2]
          )
        );
        const yVertices = this.clipGeometry(
          xVertices,
          bby.getCenter(v0),
          bby.getSize(v1),
          plane.set(0, y === 0 ? 1 : -1, 0)
        );
        for (let z = 0; z < 2; z++) {
          const bbz = new Box3(
            new Vector3(
              axisParamtersX[`${x}`],
              axisParamtersY[`${y}`],
              axisParamtersZ[`${z}`]
            ),
            new Vector3(
              axisParamtersX[`${x + 1}`],
              axisParamtersY[`${y + 1}`],
              axisParamtersZ[`${z + 1}`]
            )
          );
          const zVertices = this.clipGeometry(
            yVertices,
            bbz.getCenter(v0),
            bbz.getSize(v1),
            plane.set(0, 0, z === 0 ? 1 : -1)
          );
          if (zVertices.length > 0) {
            if (depth > 0) {
              const childNode = new Node(bbz, zVertices);
              this.debugObject.add(childNode.debugObject);
              this.childNodes.push(childNode);
              childNode.split(depth - 1);
            } else {
              this.addDebugGeometry();
            }
          }
        }
      }
    }
  };
}

export class Octree extends Object3D {
  rootNode: Node;

  constructor(mesh: Mesh, splitDepth: number = 2) {
    super();
    // create vector3 array of vertices
    const vertices = this.vertexToVectorArray(mesh);

    // compute bounding box
    mesh.geometry.computeBoundingBox();
    if (!mesh.geometry.boundingBox) {
      throw new Error(
        "Octree::split(): Failed computing bounding box for node: " + this
      );
    }
    const meshBb = mesh.geometry.boundingBox;
    const meshBbCenter = meshBb.getCenter(new Vector3());
    const meshBbSize = meshBb.getSize(new Vector3());
    const meshBbLongestSize = Math.max(
      meshBbSize.x,
      Math.max(meshBbSize.y, meshBbSize.z)
    );
    const boundingBox = new Box3(
      meshBbCenter.clone().subScalar(meshBbLongestSize / 2),
      meshBbCenter.clone().addScalar(meshBbLongestSize / 2)
    );

    this.rootNode = new Node(boundingBox, vertices);
    this.rootNode.split(splitDepth);
    this.add(this.rootNode.debugObject);
    // todo: add precalculate planes function
    this.precalculatePlanes(this.rootNode);
  }

  vertexToVectorArray = (inputMesh: Mesh): Array<Vector3> => {
    // get raw vertex position attribute from geometry
    const meshVertexPos = inputMesh.geometry.getAttribute("position");

    // get vertex index from geometry
    const meshVertexIndex = inputMesh.geometry.getIndex();
    if (!meshVertexIndex) {
      throw new Error(
        "Octree::clipMesh(): Failed to get vertex position index of mesh: " +
          inputMesh
      );
    }

    // final vertex position vector3 array
    const vertexPos: Array<Vector3> = [];

    // convert vertex buffer to vector array
    for (
      let indexVertex = 0;
      indexVertex < meshVertexIndex.count;
      indexVertex++
    ) {
      const curVertex = new Vector3();
      curVertex.fromBufferAttribute(
        meshVertexPos,
        meshVertexIndex.getX(indexVertex)
      );
      vertexPos.push(curVertex);
    }
    return vertexPos;
  };

  processInputMesh = (inputMesh: Mesh): Mesh => {
    const mesh = new Mesh();
    mesh.copy(inputMesh);
    mesh.material = new MeshBasicMaterial({ color: 0x60bf60 });
    return mesh;
  };

  precalculatePlanes = (node: Node) => {
    // planes contain normals
    const planes = Array<Plane>();
    for (let f = 0; f < node.vertices.length; f += 3) {
      const vA = node.vertices[`${f}`];
      const vB = node.vertices[`${f + 1}`];
      const vC = node.vertices[`${f + 2}`];
      const plane = new Plane().setFromCoplanarPoints(vA, vB, vC);
      planes.push(plane);
    }

    node.planes = planes;

    const children = node.childNodes;
    for (let i = 0, l = children.length; i < l; i++) {
      this.precalculatePlanes(children[`${i}`]);
    }
  };

  isSphereCollidingWithNode = (sphere, node) => {
    const { center, radius } = sphere;
    const { boundingBox } = node;

    // Calculate the distance between the sphere center and the bounding box
    const distance = boundingBox.distanceToPoint(center);

    // Check if the distance is less than the sphere radius to determine collision
    return distance < radius;
  };

  isSphereColliding = (sphere) => {
    const stack = [this.rootNode];

    while (stack.length > 0) {
      const node = stack.pop();

      if (this.isSphereCollidingWithNode(sphere, node)) {
        // If the sphere is colliding with the current node,
        // check if it collides with the child nodes
        if (node.childNodes.length > 0) {
          stack.push(...node.childNodes);
        } else {
          // Leaf node, collision detected
          return true;
        }
      }
    }
    return false;
  };
}
