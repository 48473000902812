import { FC } from "react";

import { ArrowWrapper, StyledButtonArrow, StyledText } from "./styles";

import Arrow from "~/public/assets/icons/ArrowRight.svg";

interface IButtonArrow {
  text: string;
  onClick: () => void;
}

const ButtonArrow: FC<IButtonArrow> = ({ text, onClick }) => {
  return (
    <StyledButtonArrow onClick={onClick}>
      <ArrowWrapper>
        <Arrow />
      </ArrowWrapper>
      <StyledText bold as={"p"}>
        {text}
      </StyledText>
    </StyledButtonArrow>
  );
};

export default ButtonArrow;
