import spaceService from ".";

import { TStrapiImage } from "@/types/StrapiImage";

import moduleService from "@/services/ModuleService";
import { TModule } from "@/services/ModuleService/types";
import { TMetaNavContent } from "@/services/NavService";
import { TUser } from "@/services/UserService/types";

export type TGroup = {
  accessModules: Array<TModule["id"]>;
  userIds: Array<TUser["id"]>;
  name: string;
};

export enum TSpaceResponse {
  NO_SPACES_FOUND = "No spaces found!",
}

export enum ESpaceRole {
  VISITOR = "Visitor",
  HOST = "Host",
}

export type TSpaceUser = {
  id: TUser["id"];
  email: TUser["email"];
  role: ESpaceRole;
};

export enum EDCLevel {
  DC0_1 = "DC0_1",
  DC2 = "DC_2",
}

export type TSpace = {
  id: number;
  title: string;
  description: string;
  slug: string;
  previewImage: TStrapiImage;
  mapImage: TStrapiImage;
  defaultModule: TModule["id"];
  modules: Array<TModule["id"]>;
  agenda: TAgenda;
  roomUrl: string;
  users: Array<TSpaceUser>;
  groups: Array<TGroup>;
  published_at: string;
  ValidFromDate: string;
  InvalidFromDate: string;
  DCLevel: EDCLevel;
} & TMetaNavContent;

export type TSpaceMap = Map<number, TSpace>;

export type TAgendaPoint = {
  id: number;
  notify: boolean;
  modules: Array<TModule["id"]>;
  // iso string in utc
  startDate: string;
  endDate: string;
  description: string;
  title: string;
};

// ToDo: Move this agenda stuff into separate file.

export type TAgenda = Array<TAgendaPoint>;

export const isDateActive = (
  startDateIsoString: string,
  endDateIsoString: string
) => {
  const curTimeMs = Date.parse(new Date().toISOString());
  const startTimeMs = Date.parse(startDateIsoString);
  const endTimeMs = Date.parse(endDateIsoString);

  return curTimeMs >= startTimeMs && curTimeMs < endTimeMs;
};

export const isDateInFuture = (dateIsoString: string): boolean => {
  if (!dateIsoString) return false;

  const curTimeMs = Date.parse(new Date().toISOString());
  const timeMs = Date.parse(dateIsoString);

  return curTimeMs < timeMs;
};

export const getAgendaBySpaceId = (spaceId: number) => {
  if (!spaceId) return null;

  const space = spaceService.getState().getSpaceById(spaceId);
  return space?.agenda ? space.agenda : null;
};

export const getActiveAgendaPointByModuleId = (moduleId: number) => {
  if (!moduleId) return null;

  const module = moduleService.getState().getModuleById(moduleId);
  if (!module) return null;

  const agenda = getAgendaBySpaceId(module.space);
  if (!agenda) return null;

  const agendaPoint = agenda.find(
    (agendaPoint) =>
      agendaPoint.modules.includes(module.id) &&
      isDateActive(agendaPoint.startDate, agendaPoint.endDate)
  );

  return agendaPoint ? agendaPoint : null;
};

export const getFutureAgendaPointsByModuleId = (moduleId: number) => {
  if (!moduleId) return [];

  const module = moduleService.getState().getModuleById(moduleId);
  if (!module) return [];

  const agenda = getAgendaBySpaceId(module.space);
  if (!agenda) return [];

  const agendaPoints = agenda.filter(
    (agendaPoint) =>
      agendaPoint.modules.includes(module.id) &&
      isDateInFuture(agendaPoint.endDate)
  );

  return agendaPoints ? agendaPoints : [];
};
