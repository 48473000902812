import { ToggleButton } from "@/components/Dom/VideoChat/Common/styles";

import videoService from "@/services/VideoService";

import { colors } from "@/styles/colors";

import Camera from "~/public/assets/icons/Camera.svg";

const CameraButton = () => {
  const { hasCamera, cameraAvailable } = videoService((state) => ({
    hasCamera: state.localParticipant.hasCamera,
    cameraAvailable: state.cameraAvailable,
  }));

  return (
    <ToggleButton
      icon={<Camera />}
      iconWidth={14}
      iconHeight={7.5}
      iconColor={hasCamera ? colors.grey.normal.hex : colors.white.default}
      backgroundColor={
        hasCamera ? colors.grey.lightest.hex : colors.red.normal.hex
      }
      big
      onClick={videoService.getState().toggleCamera}
      disabled={!cameraAvailable}
      isActive={hasCamera}
    />
  );
};

export default CameraButton;
