import create from "zustand";

import { ESpaceRole } from "../SpaceService/types";

import debugService from "@/services/DebugService";
import spaceService from "@/services/SpaceService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

export enum PERMISSION_ACTION {
  NOTIFICATION_SEND,
  EDIT_SPACES,
  EDIT_CURRENT_SPACE,
}

type TPermissionService = {
  can: (action: PERMISSION_ACTION, user?: TUser | null) => boolean;
};

const permissionService = create<TPermissionService>((set, get) => {
  return {
    can: (action, user = userService.getState().getOwnUser()) => {
      switch (action) {
        case PERMISSION_ACTION.NOTIFICATION_SEND: {
          return (
            spaceService.getState().getRoleFromCurrentSpace() ===
            ESpaceRole.HOST
          );
        }
        case PERMISSION_ACTION.EDIT_SPACES:
          const hostedSpaces = spaceService.getState().getHostedSpaces();
          if (!hostedSpaces || hostedSpaces.size === 0) return false;
          return true;
        case PERMISSION_ACTION.EDIT_CURRENT_SPACE:
          return (
            spaceService.getState().getRoleFromCurrentSpace() ===
            ESpaceRole.HOST
          );
        default: {
          debugService.getState().logInfo("no matching action found");
          return false;
        }
      }
    },
  };
});

export default permissionService;
