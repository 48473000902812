import {
  Camera,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  Vector2,
  Vector3,
} from "three";

import assetService from "@/services/AssetService";
import debugService from "@/services/DebugService";

// Order left to right row by row
const mapAtlasUvAttributes = [
  [0, 1, 0.5, 1, 0, 0.5, 0.5, 0.5],
  [0.5, 1, 1, 1, 0.5, 0.5, 1, 0.5],
  [0, 0.5, 0.5, 0.5, 0, 0, 0.5, 0],
  [0.5, 0.5, 1, 0.5, 0.5, 0, 1, 0],
];

const SIZE: number = 0.25;
const COUNT: number = 5;
const DISTANCE: number = 1.5;
const SPEED: number = 0.65;

const VECTOR_FORWARD: Vector3 = new Vector3(0.0, 0.0, 1.0);
const TWO_PI: number = Math.PI * 2.0;
const DIRECTION: Vector3 = new Vector3(1.0, 1.0, 0.0);

type TDirectionType = "UP" | "RADIAL";

type TParticle = {
  mesh: Mesh;
  random: number;
  startTime: number;
};

const smoothFalloff = (x: number) => {
  return -16 * Math.pow(x - 0.5, 4.0) + 1;
};

export default class ParticleSystem {
  // Instanced rendering could be used here but it is overkill for the amount of icons
  particles: Array<TParticle>;
  pivot: Object3D;
  target: Vector3;

  active: boolean;
  activeTimeoutId: any | null;

  directionType: TDirectionType;
  tmpDirection: Vector3;

  duration: number;
  time: number;

  count: number;
  size: number;
  distance: number;
  speed: number;
  falloff: Function;

  constructor(
    mapName: string,
    directionType: TDirectionType = "UP",
    size: number = SIZE,
    count: number = COUNT,
    speed: number = SPEED,
    distance: number = DISTANCE,
    falloff: Function = smoothFalloff
  ) {
    this.active = false;
    this.activeTimeoutId = false;

    this.target = new Vector3();

    this.tmpDirection = new Vector3(1.0, 1.0, 0.0);
    this.directionType = directionType;

    this.duration = 0.0;
    this.time = 0.0;

    this.size = size;
    this.count = count;
    this.speed = speed;
    this.distance = distance;
    this.falloff = falloff;

    this.particles = new Array<TParticle>();

    const material = new MeshBasicMaterial({
      map: assetService.getState().getAsset(mapName).data,
      depthTest: false,
      transparent: true,
    });

    for (let particleIndex = 0; particleIndex < this.count; particleIndex++) {
      const geometry = new PlaneGeometry(this.size, this.size);
      const mesh = new Mesh(geometry, material);
      mesh.scale.set(0.0, 0.0, 0.0);

      this.particles.push({
        mesh: mesh,
        random: 0.35,
        startTime: Math.random() * 0.5,
      });
    }

    this.pivot = new Object3D();
    this.pivot.name = "Icons";
    this.pivot.position.set(0.0, 1.5, -0.2);
    this.pivot.rotateY(Math.PI);

    this.particles.forEach(({ mesh }) => this.pivot.add(mesh));
  }

  setMap(particleIndex: number, mapIndex: number) {
    const uvAttribute = this.particles[
      particleIndex
    ].mesh.geometry.getAttribute("uv");

    for (let uvIndex = 0; uvIndex < 8; uvIndex += 2) {
      const u = mapAtlasUvAttributes[mapIndex][uvIndex];
      const v = mapAtlasUvAttributes[mapIndex][uvIndex + 1];

      uvAttribute.setXY(Math.ceil(uvIndex * 0.5), u, v);
    }
    uvAttribute.needsUpdate = true;
  }

  resetParticle(particleIndex: number) {
    this.particles[particleIndex].random = Math.min(
      0.75,
      Math.max(Math.random(), 0.25)
    );

    const mesh = this.particles[particleIndex].mesh;

    mesh.scale.set(0.0, 0.0, 0.0);
    mesh.position.set(0.0, 0.05, 0.0);
    mesh.visible = true;
  }

  start(duration: number = Infinity, mapIndex: number = -1) {
    this.stop();

    const randomMap: boolean = mapIndex < 0;

    for (
      let particleIndex = 0;
      particleIndex < this.particles.length;
      particleIndex++
    ) {
      this.setMap(
        particleIndex,
        randomMap ? Math.floor(Math.random() * 4.0) : mapIndex
      );
      this.resetParticle(particleIndex);
    }

    this.duration = duration;
    this.time = 0.0;

    this.active = true;
    this.activeTimeoutId = setTimeout(() => {
      this.stop();
    }, this.duration);
  }

  stop() {
    this.active = false;
    if (this.activeTimeoutId) {
      clearTimeout(this.activeTimeoutId);
      this.activeTimeoutId = null;
    }
  }

  update(deltaTime: number, camera: Camera) {
    if (this.active) {
      this.time += Math.round(deltaTime * 1000);
      this.pivot.lookAt(camera.getWorldPosition(this.target));
    }

    // ToDo: Update to work with infinite duration.
    const timeProgress: number = this.time / this.duration;

    for (let iconIndex = 0; iconIndex < this.particles.length; iconIndex++) {
      const { mesh, random, startTime } = this.particles[iconIndex];

      switch (this.directionType) {
        case "UP": {
          const heightProgress: number = mesh.position.y / this.distance;
          const smoothedProgress: number = this.falloff(heightProgress);

          if (heightProgress <= 1.0 && timeProgress > startTime) {
            mesh.scale.set(
              smoothedProgress,
              smoothedProgress,
              smoothedProgress
            );

            mesh.translateY(this.speed * deltaTime);
            mesh.position.setX(
              Math.cos(random * (1 - heightProgress) * this.speed) *
                heightProgress *
                random
            );

            if (heightProgress >= 1.0) mesh.visible = false;
          } else if (deltaTime > 0.1) mesh.visible = false;
          else if (this.active) this.resetParticle(iconIndex);
          break;
        }
        case "RADIAL": {
          this.tmpDirection
            .copy(DIRECTION)
            .applyAxisAngle(VECTOR_FORWARD, TWO_PI * random);
          const directionProgress: number =
            new Vector2(mesh.position.x, mesh.position.y).length() /
            this.distance;
          const smoothedProgress: number = this.falloff(directionProgress);

          if (directionProgress <= 0.95 && timeProgress > startTime) {
            mesh.scale.set(
              smoothedProgress,
              smoothedProgress,
              smoothedProgress
            );

            mesh.translateOnAxis(
              this.tmpDirection,
              this.speed * deltaTime * smoothedProgress
            );

            if (directionProgress > 0.85) mesh.visible = false;
          } else if (deltaTime > 0.1) mesh.visible = false;
          else if (this.active) this.resetParticle(iconIndex);
          break;
        }
        default: {
          debugService
            .getState()
            .logError("reactionController::update(): Invalid icon type.");
          break;
        }
      }
    }
  }
}
