import React, { FC } from "react";

import UserBadge from "@/components/Dom/Common/UserBadge";

import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

import { StyledUserName } from "./styles";

interface IUserName {
  users: TUser[];
  applyFontStyle?: boolean;
  bold?: boolean;
}

const UserName: FC<IUserName> = ({
  users,
  applyFontStyle = true,
  bold = false,
  ...others
}) => {
  if (!users?.length) return null;

  return (
    <StyledUserName {...others} applyFontStyle={applyFontStyle} bold={bold}>
      {users.map((user, i) => (
        <React.Fragment key={user.id}>
          <UserBadge user={user} />
          {userService.getState().buildUserName(user)}
          {i !== users.length - 1 && ", "}
        </React.Fragment>
      ))}
    </StyledUserName>
  );
};

export default UserName;
