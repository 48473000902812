import raf from "raf";
import { Vector3, Clock, Euler } from "three";
import create from "zustand";

import ReactionController from "@/components/Scene/Avatar/ReactionController";

import cmsService from "@/services/CmsService";
import debugService from "@/services/DebugService";
import {
  TDiscoverQuestState,
  TDiscoverTaskData,
  TDiscoverTaskState,
} from "@/services/DiscoverQuestService/types";
import moduleService from "@/services/ModuleService";
import rehService from "@/services/RehService";
import { TTriggerAreaData } from "@/services/SceneService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import vrService from "@/services/VrService";
import localStorage from "@/utils/LocalStorage";
import { clamp } from "@/utils/Math";

export type TPlayerData = {
  currentModuleId: string;
  currentEventId: string;
  avatarConfig: TAvatarConfig;
  questState: Map<TDiscoverQuestState["id"], TDiscoverQuestState>;
  userId: number;
};
// ToDo: Type options for strings
export type TAvatarConfig = {
  outfit: string;
  outfitStyle: string;
  hairCut: string;
  hairColor: string;
  skinColor: string;
  extras: Array<string>;
  skin: string;
};

// PLAYER MOVEMENT CONFIGS

export const playerMovementConfig = {
  FORCE_TRANSLATE_FORWARD: 1.2,
  MAX_TRANSLATE_VELOCITY_FORWARD: 6.6,

  FORCE_TRANSLATE_BACKWARDS: -0.3,
  MAX_TRANSLATE_VELOCITY_BACKWARDS: 1.2,

  TRANSLATE_DAMPENING: 0.7,

  FORCE_ROTATION: 4.8,
  MAX_ROTATION_VELOCITY: 2.4,

  ROTATION_DAMPENING: 0.85,

  FORCE_FLY: 12,
};

const NETWORKED_PLAYER_LAG_INSTANT_UPDATE_THRESHOLD = 0.5;
export const networkedPlayerMovementConfig = {
  FORCE_TRANSLATE: 4.2,

  MAX_TRANSLATE_VELOCITY_FORWARD: 6.6,
  MAX_TRANSLATE_VELOCITY_BACKWARDS: 1.0,

  TRANSLATE_DAMPENING: 0.3,

  FORCE_ROTATION: 2.4,
  MAX_ROTATION_VELOCITY: 4.0,

  ROTATION_DAMPING: 0.6,
};

export class PlayerTransform {
  currentPosition: Vector3;
  newPosition: Vector3;
  currentRotation: number; // rotation around y axis
  newRotation: number; // rotation around y axis
  velocity: Vector3;
  rotationVelocity: number;
  translateDirection: number;

  constructor(
    position: Vector3 = new Vector3(),
    rotation: number = 0.0,
    velocity: Vector3 = new Vector3(),
    rotationVelocity: number = 0.0,
    translateDirection: number = 0.0
  ) {
    this.currentPosition = position.clone();
    this.newPosition = position.clone();
    this.currentRotation = rotation;
    this.newRotation = rotation;
    this.velocity = velocity.clone();
    this.rotationVelocity = rotationVelocity;
    this.translateDirection = translateDirection;
  }
}

// NETWORK PACKAGE TYPES

type TPlayerTransformPackage = {
  userId?: number;
  rotation?: number;
  position?: {
    x: number;
    y: number;
    z: number;
  };
};
type TPlayerTransformPackages = {
  [userId: number]: TPlayerTransformPackage;
};
type TPlayerLeftPackage = {
  userId: number;
};

type TPlayerService = {
  start: () => void;
  switchToVrTick: () => void;
  stop: () => void;

  init: () => void;

  allPlayerIds: Array<number>;
  allPlayers: Map<number, PlayerTransform>;

  maxVisiblePlayers: number;
  allVisiblePlayerIds: Set<number>;

  ownPlayerData: TPlayerData | null;

  reactionController: ReactionController | null;

  setCurrentModule: (moduleId: number | null) => void;
  setAvatarConfig: (avatarConfig: TAvatarConfig) => Promise<boolean>;
  updateQuestState: (state: TDiscoverQuestState) => Promise<boolean>;

  getPlayerData: (userId: number) => Promise<TPlayerData>;

  lastSentPosition: Vector3 | null;
  lastSentRotation: number | null;

  curTriggerArea: TTriggerAreaData | null;

  clock: Clock | null;

  onOwnPlayerUpdate: () => {} | null;
  setOwnTransform: (transform: PlayerTransform) => void;

  onUpdate: (playerTransformPackages: TPlayerTransformPackages) => void;

  onJoin: (playerTransformPackages: TPlayerTransformPackages) => void;
  onPlayerSpawned: (playerTransformPackage: TPlayerTransformPackage) => void;
  onPlayerLeft: (playerLeftPackage: TPlayerLeftPackage) => void;

  interpolatePlayerTransform: () => any;

  loopCurrentPlayerIndex: (currentIndex: number, arrayLength: number) => number;
  addVisiblePlayers: (
    currentPlayers: Array<number>,
    currentVisiblePlayers: Set<number>,
    numOfNewPlayers: number
  ) => void;
};

export const updateTransform = (
  transform: PlayerTransform,
  deltaTime: number
) => {
  const positionDiff = new Vector3();
  const newVelocity = new Vector3();
  const forward = new Vector3();

  // TRANSLATION
  positionDiff.copy(
    transform.newPosition.clone().sub(transform.currentPosition)
  );

  newVelocity.copy(positionDiff);
  newVelocity.multiplyScalar(networkedPlayerMovementConfig.FORCE_TRANSLATE);

  // add difference of positions multiplied with force to velocity
  //player.velocity.add(positionDiff.clone().multiplyScalar(FORCE));

  // calculate magnitude of velocity
  const totalVelocity = newVelocity.length();
  const maxVelocity =
    transform.translateDirection > 0.0
      ? networkedPlayerMovementConfig.MAX_TRANSLATE_VELOCITY_FORWARD
      : networkedPlayerMovementConfig.MAX_TRANSLATE_VELOCITY_BACKWARDS;
  // reduce to max velocity
  if (totalVelocity > maxVelocity) {
    newVelocity.multiplyScalar(maxVelocity / totalVelocity);
  }

  // use delta time and velocity to update position
  transform.currentPosition.add(newVelocity.clone().multiplyScalar(deltaTime));

  // multiply velocity with DAMPING
  newVelocity.multiplyScalar(networkedPlayerMovementConfig.TRANSLATE_DAMPENING);

  const newVelocityLength = newVelocity.length();
  if (newVelocityLength < 0.001) newVelocity.set(0.0, 0.0, 0.0);

  transform.velocity.copy(newVelocity);

  // ROTATION

  const rotationDiff = simplifyRotationAngle(
    transform.newRotation - transform.currentRotation
  );

  let newRotationVelocity =
    transform.rotationVelocity +
    rotationDiff * networkedPlayerMovementConfig.FORCE_ROTATION;

  newRotationVelocity = clamp(
    newRotationVelocity,
    -networkedPlayerMovementConfig.MAX_ROTATION_VELOCITY,
    networkedPlayerMovementConfig.MAX_ROTATION_VELOCITY
  );

  const newRotation =
    transform.currentRotation + newRotationVelocity * deltaTime;

  transform.currentRotation = Math.atan2(
    Math.sin(newRotation),
    Math.cos(newRotation)
  );

  // rotation velocity damping and clamping
  newRotationVelocity =
    newRotationVelocity * networkedPlayerMovementConfig.ROTATION_DAMPING;
  if (Math.abs(newRotationVelocity) < 0.001) newRotationVelocity = 0.0;
  transform.rotationVelocity = newRotationVelocity;

  // TRANSLATE DIRECTION

  forward.set(0.0, 0.0, -1.0);
  forward.applyEuler(new Euler(0.0, transform.currentRotation, 0.0));
  forward.normalize();

  transform.translateDirection =
    forward.dot(transform.velocity.clone().normalize()) > 0.0 ? 1.0 : -1.0;
};

/**
 * reduce input angle to range of -Pi to +Pi and return simplified value
 */
const simplifyRotationAngle = (angle) => {
  let simplifiedAngle = angle;
  while (simplifiedAngle < -Math.PI) {
    simplifiedAngle += Math.PI * 2;
  }
  while (simplifiedAngle > Math.PI) {
    simplifiedAngle -= Math.PI * 2;
  }

  return simplifiedAngle;
};

const serializeQuestState = (
  questState: Map<TDiscoverQuestState["id"], TDiscoverQuestState>
) => {
  // Do deep copy to parse maps to arrays
  return Array.from(questState.values()).map((quest: TDiscoverQuestState) => ({
    id: quest.id,
    completed: quest.completed,
    tasks: Array.from(quest.tasks.values()),
  }));
};

const parseQuestState = (
  serializedQuestState: any
): Map<TDiscoverQuestState["id"], TDiscoverQuestState> => {
  const questState = new Map<TDiscoverQuestState["id"], TDiscoverQuestState>(
    serializedQuestState.map((entry) => [entry.id, entry])
  );
  questState.forEach((entry) => {
    entry.tasks = new Map<TDiscoverTaskData["id"], TDiscoverTaskState>(
      (entry.tasks as any).map((taskState) => [taskState.id, taskState])
    );
  });
  return questState;
};

export const playerService = create<TPlayerService>((set, get) => {
  const isPlayerTransformPackageValid = (
    playerTransformPackage: TPlayerTransformPackage
  ): boolean => {
    if (!playerTransformPackage) return false;

    if (
      playerTransformPackage.position &&
      ((playerTransformPackage.position.x &&
        typeof playerTransformPackage.position.x !== "number") ||
        (playerTransformPackage.position.y &&
          typeof playerTransformPackage.position.y !== "number") ||
        (playerTransformPackage.position.z &&
          typeof playerTransformPackage.position.z !== "number"))
    )
      return false;

    if (
      playerTransformPackage.rotation &&
      typeof playerTransformPackage.rotation !== "number"
    )
      return false;

    return true;
  };

  // Send position and rotation updates to the server in a given Interval
  let updateClientInterval;
  const startUpdateClientInterval = (tickInterval) => {
    updateClientInterval = setInterval(() => {
      const playerUpdates = get().onOwnPlayerUpdate();

      if (playerUpdates) {
        rehService
          .getState()
          .sendMessage("module", "CLIENT_UPDATE", playerUpdates);
      }
    }, tickInterval);
  };
  const stopUpdateClientInterval = () => {
    clearInterval(updateClientInterval);
  };

  let rafHandle;
  const tick = () => {
    rafHandle = raf(tick);
    get().interpolatePlayerTransform();
  };
  const vrTick = () => {
    vrService.getState().xrSession?.requestAnimationFrame(vrTick);
    get().interpolatePlayerTransform();
  };

  return {
    clock: null,

    ownPlayerData: null,

    reactionController: null,

    curTriggerArea: null,

    maxVisiblePlayers: localStorage.get("maxVisiblePlayers") || 50,
    allVisiblePlayerIds: new Set<number>(),

    allPlayerIds: new Array<number>(),
    allPlayers: new Map<number, PlayerTransform>(),

    lastSentPosition: null,
    lastSentRotation: null,

    init: () => {
      const { subscribe } = rehService.getState();

      subscribe("module", "SERVER_CLIENT_SPAWNED", get().onPlayerSpawned);
      subscribe("module", "SERVER_CLIENT_LEFT", get().onPlayerLeft);
      subscribe("module", "SERVER_ALL_CLIENTS", get().onJoin);
      subscribe("module", "SERVER_UPDATE", get().onUpdate);
      subscribe("module", "SERVER_TICK_INTERVAL", startUpdateClientInterval);
      // subscribe("module", "CLIENT_UPDATE", get().onOwnPlayerUpdate);
    },

    start: async () => {
      set({ clock: new Clock(true) });

      raf(tick);

      get().setCurrentModule(moduleService.getState().currentModuleId);
    },

    switchToVrTick: async () => {
      if (rafHandle) {
        raf.cancel(rafHandle);
      }
      vrService.getState().xrSession?.requestAnimationFrame(vrTick);
    },

    stop: () => {
      if (rafHandle) {
        raf.cancel(rafHandle);
      }
      stopUpdateClientInterval();

      set({
        allPlayers: new Map<number, PlayerTransform>(),
        allPlayerIds: new Array<number>(),
        allVisiblePlayerIds: new Set<number>(),
      });

      get().setCurrentModule(null);
    },

    onOwnPlayerUpdate: () => {
      const ownUser: TUser | null = userService.getState().getOwnUser();
      if (!ownUser) return null;

      const playerTransform: PlayerTransform | undefined = get().allPlayers.get(
        ownUser.id
      );
      if (!playerTransform) return null;

      const { lastSentPosition, lastSentRotation } = get();
      const playerTransformPackage: TPlayerTransformPackage = {};

      if (
        playerTransform.currentPosition &&
        (!lastSentPosition ||
          lastSentPosition.distanceToSquared(playerTransform.currentPosition) >
            0.1)
      ) {
        playerTransformPackage.position = playerTransform.currentPosition;
        get().lastSentPosition?.copy(playerTransform.currentPosition);
      }
      if (
        playerTransform.currentRotation &&
        (!lastSentRotation ||
          Math.abs(lastSentRotation - playerTransform.currentRotation) > 0.01)
      ) {
        playerTransformPackage.rotation = playerTransform.currentRotation;
        set({
          lastSentRotation: playerTransform.currentRotation,
        });
      }

      return Object.keys(playerTransformPackage).length > 0
        ? playerTransformPackage
        : null;
    },
    onPlayerSpawned: async (playerTransformPackage = {}) => {
      const { userId } = playerTransformPackage;

      if (!userId) {
        debugService
          .getState()
          .logError(
            "playerService::onPlayerJoin(): Invalid player transform package - userId is missing!"
          );
        return;
      }
      // ToDo: Investigate why this is happening => user is reconnected?
      // check if player is existing
      if (get().allPlayerIds.indexOf(userId) >= 0) {
        // eslint-disable-next-line no-console
        console.error(
          `playerService::onPlayerJoin(): Player with id = ${userId} already exists!`
        );
        return;
      }

      // ToDo(Eric) Validate that the properties exist on the position object
      // Can ts help here? => write helper routine
      if (!isPlayerTransformPackageValid(playerTransformPackage)) {
        debugService
          .getState()
          .logError(
            "playerService::onPlayerJoin(): Invalid new player transform values!"
          );
        return;
      }

      // create new player transform and add it into map
      const newPlayer: PlayerTransform = new PlayerTransform(
        new Vector3(
          // @ts-expect-error
          playerTransformPackage.position.x,
          // @ts-expect-error
          playerTransformPackage.position.y,
          // @ts-expect-error
          playerTransformPackage.position.z
        ),
        playerTransformPackage.rotation
      );
      get().allPlayers.set(userId, newPlayer);

      // insert new clients alphabetically correct
      let playerAdded = false;
      for (let index = 0; index < get().allPlayerIds.length; index++) {
        if (get().allPlayerIds[`${index}`] > userId) {
          get().allPlayerIds.splice(index, 0, userId);
          playerAdded = true;
          break;
        }
      }
      if (!playerAdded) {
        get().allPlayerIds.push(userId);
      }

      // display player if max visible players is not reached yet
      if (
        !userService.getState().isSelf(userId) &&
        get().allVisiblePlayerIds.size < get().maxVisiblePlayers
      ) {
        get().allVisiblePlayerIds.add(userId);
      }

      // force rerender of scene avatars by overwriting allVisiblePlayerIds
      set({ allVisiblePlayerIds: new Set(get().allVisiblePlayerIds) });
    },
    onPlayerLeft: ({ userId }) => {
      if (!userId) {
        debugService
          .getState()
          .logError(
            "playerService::onPlayerLeft(): Invalid player leave package - userId is missing!"
          );
        return;
      }
      // check if player is existing
      if (get().allPlayerIds.indexOf(userId) < 0) {
        // eslint-disable-next-line no-console
        console.warn(
          `playerService::onPlayerLeft(): Player with id = ${userId} does not exists!`
        );
        return;
      }

      get().allPlayers.delete(userId);
      set({ allPlayerIds: get().allPlayerIds.filter((id) => id !== userId) });

      // check if leaving user was visible
      if (get().allVisiblePlayerIds.has(userId)) {
        get().allVisiblePlayerIds.delete(userId);

        get().addVisiblePlayers(
          get().allPlayerIds,
          get().allVisiblePlayerIds,
          Math.max(get().maxVisiblePlayers - get().allVisiblePlayerIds.size, 0)
        );

        set({
          allVisiblePlayerIds: new Set<number>(get().allVisiblePlayerIds),
        });
      }
    },
    onJoin: (playerTransformPackages) => {
      if (!playerTransformPackages) return;

      Object.entries(playerTransformPackages).forEach(
        ([userIdAsString, playerTransformPackage]: [
          string,
          TPlayerTransformPackage
        ]) => {
          const userId: number = parseInt(userIdAsString, 10);

          if (!isPlayerTransformPackageValid(playerTransformPackage)) {
            debugService
              .getState()
              .logError(
                "playerService::onJoin(): Invalid new player transform values!"
              );
            return;
          }

          const newPlayer: PlayerTransform = new PlayerTransform(
            new Vector3(
              // @ts-expect-error
              playerTransformPackage.position.x,
              // @ts-expect-error
              playerTransformPackage.position.y,
              // @ts-expect-error
              playerTransformPackage.position.z
            ),
            playerTransformPackage.rotation
          );

          get().allPlayers.set(userId, newPlayer);

          // TODO: show all PlayerIds in debug
          get().allPlayerIds.push(userId);
        }
      );

      get().allVisiblePlayerIds.clear();
      // sort newAllPlayerIds alphabetically
      get().allPlayerIds.sort();

      // only keep players in visible array that are still present
      get().allVisiblePlayerIds.forEach((userId) => {
        if (
          get().allPlayerIds.includes(userId) &&
          !userService.getState().isSelf(userId)
        )
          get().allVisiblePlayerIds.add(userId);
      });

      // select visible players to array
      get().addVisiblePlayers(
        get().allPlayerIds,
        get().allVisiblePlayerIds,
        Math.max(get().maxVisiblePlayers - get().allVisiblePlayerIds.size, 0)
      );

      set({
        allVisiblePlayerIds: new Set<number>(get().allVisiblePlayerIds),
      });
    },
    loopCurrentPlayerIndex(currentIndex, arrayLength) {
      if (currentIndex < 0) {
        return arrayLength + currentIndex;
      } else if (currentIndex >= arrayLength) {
        return currentIndex - arrayLength;
      }
      return currentIndex;
    },
    addVisiblePlayers(
      currentPlayers,
      currentVisiblePlayers,
      numOfNewPlayers = 1
    ) {
      if (currentPlayers.length <= get().maxVisiblePlayers) {
        currentPlayers.forEach((currentPlayer) => {
          if (
            !currentVisiblePlayers.has(currentPlayer) &&
            !userService.getState().isSelf(currentPlayer)
          )
            currentVisiblePlayers.add(currentPlayer);
        });
        return;
      }

      // get index of own player in current players array
      // goal is to display people around your user index

      const ownUser: TUser | null = userService.getState().getOwnUser();

      if (!ownUser) return;

      const ownPlayerIndex = currentPlayers.indexOf(ownUser.id);

      const visiblePlayersStartSize = currentVisiblePlayers.size;

      if (ownPlayerIndex >= 0) {
        let leftIndex = get().loopCurrentPlayerIndex(
          ownPlayerIndex - 1,
          currentPlayers.length
        );
        let rightIndex = get().loopCurrentPlayerIndex(
          ownPlayerIndex + 1,
          currentPlayers.length
        );

        while (
          currentVisiblePlayers.size - visiblePlayersStartSize <
            numOfNewPlayers &&
          currentVisiblePlayers.size < get().maxVisiblePlayers &&
          rightIndex !== leftIndex
        ) {
          // check right index first if player can be added (prefers newer players)
          if (
            !currentVisiblePlayers.has(currentPlayers[`${rightIndex}`]) &&
            !userService
              .getState()
              .isSelf(Number(currentPlayers[`${rightIndex}`]))
          ) {
            currentVisiblePlayers.add(currentPlayers[`${rightIndex}`]);

            // check if we now have enough players or if we need to continue with left index
            if (
              currentVisiblePlayers.size - visiblePlayersStartSize >=
                numOfNewPlayers ||
              currentVisiblePlayers.size >= get().maxVisiblePlayers
            ) {
              break;
            }
          }

          // check left player if it can be added as well
          if (
            !currentVisiblePlayers.has(currentPlayers[`${leftIndex}`]) &&
            !userService
              .getState()
              .isSelf(Number(currentPlayers[`${rightIndex}`]))
          ) {
            currentVisiblePlayers.add(currentPlayers[`${leftIndex}`]);
          }

          // de/in-crement indices and check for looping criteria
          leftIndex = get().loopCurrentPlayerIndex(
            leftIndex - 1,
            currentPlayers.length
          );

          // additional break statement - check after changing one index if they are now the same
          if (leftIndex === rightIndex) break;

          rightIndex = get().loopCurrentPlayerIndex(
            rightIndex + 1,
            currentPlayers.length
          );
        }
      }
    },
    onUpdate: (playerTransformPackages) => {
      // check for each player transform package that user exist

      Object.entries(playerTransformPackages).forEach(
        ([userIdAsString, playerTransformPackage]: [
          string,
          TPlayerTransformPackage
        ]) => {
          const userId: number = parseInt(userIdAsString, 10);
          const user = get().allPlayers.get(userId);

          // only update current player transform if player is not self and exists
          if (!user || userService.getState().isSelf(userId)) return;

          // parse and insert partial transform data
          Object.entries(playerTransformPackage).forEach(
            ([property, value]: [string, any]) => {
              switch (property) {
                case "position": {
                  // @ts-expect-error
                  get()
                    .allPlayers.get(userId)
                    .newPosition.set(value.x, value.y, value.z);
                  break;
                }
                case "rotation": {
                  // @ts-expect-error
                  get().allPlayers.get(userId).newRotation = value;
                  break;
                }
                default:
                  break;
              }
            }
          );
        }
      );
    },
    /**
     * interpolate all other player transforms
     */
    interpolatePlayerTransform: () => {
      if (!get().clock) return;

      //@ts-expect-error
      const delta = get().clock.getDelta();
      get().allPlayers.forEach((player: PlayerTransform, userId: number) => {
        if (!userService.getState().isSelf(userId)) {
          if (
            !get().allVisiblePlayerIds.has(userId) ||
            delta > NETWORKED_PLAYER_LAG_INSTANT_UPDATE_THRESHOLD
          ) {
            // instant update
            player.currentPosition = player.newPosition.clone();
            player.currentRotation = player.newRotation;
            player.velocity.set(0, 0, 0);
            player.rotationVelocity = 0;
          } else {
            updateTransform(player, delta);
          }
        }
      });
    },
    /**
     * apply own player transform with userId -> called by PlayerThirdPersonController
     * @param PlayerTransform
     */
    setOwnTransform: (transform: PlayerTransform) => {
      const ownUser: TUser | null = userService.getState().getOwnUser();
      if (!ownUser?.id) return;

      set((state) => ({
        allPlayers: new Map(state.allPlayers.set(ownUser.id, transform)),
      }));
    },

    getPlayerData: async (userId) => {
      return new Promise(async (resolve, reject) => {
        try {
          const playerData: TPlayerData = await cmsService
            .getState()
            .requestData(`getPlayerData/${userId}`, false);

          if (playerData.questState)
            playerData.questState = parseQuestState(playerData.questState);

          resolve(playerData);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },

    setCurrentModule: async (moduleId) => {
      try {
        if (!moduleId) moduleId = 0;

        const data = await cmsService
          .getState()
          .sendData(`setModule`, { currentModuleId: moduleId }, "PUT");

        if (!data || !data.playerData) return false;

        if (data.playerData.questState)
          data.playerData.questState = parseQuestState(
            data.playerData.questState
          );

        set({ ownPlayerData: data.playerData });
        return true;
      } catch (error) {
        debugService
          .getState()
          .logError(`playerService::setCurrentModule: ${error}`);

        return false;
      }
    },

    setAvatarConfig: async (avatarConfig) => {
      if (!avatarConfig) return false;

      try {
        const data = await cmsService
          .getState()
          .sendData(`setAvatarConfig`, { avatarConfig: avatarConfig }, "PUT");

        if (!data || !data.playerData) return false;

        if (data.playerData.questState)
          data.playerData.questState = parseQuestState(
            data.playerData.questState
          );

        set({ ownPlayerData: data.playerData });
        return true;
      } catch (error) {
        debugService
          .getState()
          .logError(
            `playerService::setAvatarConfig(): Failed to set avatar config = ${error}`
          );
        return false;
      }
    },
    updateQuestState: async (state) => {
      const ownPlayerData = get().ownPlayerData;
      if (!state || !ownPlayerData) return false;

      try {
        if (!ownPlayerData.questState)
          ownPlayerData.questState = new Map<
            TDiscoverQuestState["id"],
            TDiscoverQuestState
          >();

        // init quest state if it does not exist
        if (!ownPlayerData.questState.has(state.id))
          ownPlayerData.questState.set(state.id, state);

        await cmsService
          .getState()
          .sendData(
            `setQuestState`,
            { questState: serializeQuestState(ownPlayerData.questState) },
            "PUT"
          );

        // force rerender
        set({ ownPlayerData });
        return true;
      } catch (error) {
        debugService
          .getState()
          .logError(
            `playerService::updateQuestState(): Failed to update quest state = ${error}`
          );
        return false;
      }
    },
  };
});
