import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { Text } from "@/styles/typography";

export const StyledAvatar = styled.div`
  align-items: center;
  border-radius: 100%;
  display: grid;
  grid-template: 1fr / 1fr;
  height: 100%;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  ${(props) =>
    props.theme &&
    css`
      background: ${props.theme.background};
      color: ${props.theme.text};
    `}
  
  img {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  
  span {
    font-family: var(--font-sans-serif);
    font-size: 1.2rem;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    font-weight: 700;
    line-height: 1em;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
  }
`;

export const StyledAvatarImage = styled.div`
  position: relative;
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: 100%;
  overflow: hidden;
  margin: auto;

  ${({ isGroup }) =>
    isGroup &&
    css`
      overflow: initial;

      ${StyledAvatar} {
        position: absolute;
        width: 55%;
        height: 55%;
        border-radius: 50%;

        &:first-child {
          top: 0;
          left: 0;
          z-index: 1;
        }

        &:nth-child(2) {
          top: 0;
          right: 0;
        }

        span {
          font-size: 0.8rem;
        }
      }
    `}

  span {
    user-select: none;
  }
`;

export const StyledGroupCount = styled(Text)`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 55%;
  height: 55%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.grey.lightest.hex};
  border-radius: 50%;
  color: ${colors.grey.dark.hex};
  font-weight: 700;
  font-size: 0.8rem;

  transform: translateX(-50%);
  z-index: 2;
`;
