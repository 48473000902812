/**
 * Interval that will wait for async function to finish before looping
 *
 * @param fn
 * @param intervalMs
 */
import debugService from "@/services/DebugService";

const asyncInterval = (fn: () => void, intervalMs: number): (() => void) => {
  let isActive = true;

  const update = async () => {
    if (!isActive) return;

    try {
      await fn();
    } catch (error) {
      debugService.getState().logError(error);
    }

    setTimeout(update, intervalMs);
  };

  update();

  // Stop update loop
  return () => {
    isActive = false;
  };
};

export default asyncInterval;
