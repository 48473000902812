import soundService from "@/services/AudioService";

import { StyledStatusNotification, IconWrapper, Headline } from "./styles";

type TSmallNotification = {
  text: string | React.ReactNode;
  type?: "default" | "danger";
  icon?: {
    element: any;
    width: number;
    height: number;
  };
  playSound?: boolean;
};

const StatusNotification = ({
  text,
  type = "default",
  icon = { element: undefined, width: 10, height: 10 },
  playSound = true,
}: TSmallNotification) => {
  if (playSound) {
    setTimeout(() => {
      soundService.getState().play("/assets/audio/notification.mp3");
    }, 200);
  }

  return (
    <StyledStatusNotification type={type}>
      {icon.element && (
        <IconWrapper iconWidth={icon.width} iconHeight={icon.height}>
          {icon.element}
        </IconWrapper>
      )}

      <Headline bold={true}>{text}</Headline>
    </StyledStatusNotification>
  );
};

export default StatusNotification;
