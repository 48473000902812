import { FC } from "react";

import ButtonArrow from "@/components/Dom/Common/ButtonArrow";

import notificationService from "@/services/NotificationService";

import { BigMidHeadline } from "@/styles/typography";

import {
  StyledInfoNotification,
  TextWrapper,
  ImageWrapper,
  StyledText,
  StyledSubText,
} from "./styles";

export type TInfoNotification = {
  headline: string;
  text: string;
  subText?: string;
  buttonText: string;
  onButtonClick?: () => void;
  imageUrl: string;
  id?: string;
};

const InfoNotification: FC<TInfoNotification> = ({
  headline,
  text,
  subText,
  buttonText,
  onButtonClick = () => {},
  imageUrl,
  id,
}) => {
  const onClick = () => {
    if(id) notificationService.getState().removeNotification(id);
    onButtonClick();
  };
  return (
    <StyledInfoNotification>
      <TextWrapper>
        {headline && <BigMidHeadline serif>{headline}</BigMidHeadline>}
        {text && <StyledText>{text}</StyledText>}
        {subText ? (
          <StyledSubText>{subText}</StyledSubText>
        ) : (
          <ButtonArrow text={buttonText} onClick={onClick} />
        )}
      </TextWrapper>

      {imageUrl && (
        <ImageWrapper imageUrl={imageUrl}>
          {subText && <ButtonArrow text={buttonText} onClick={onClick} />}
        </ImageWrapper>
      )}
    </StyledInfoNotification>
  );
};

export default InfoNotification;
