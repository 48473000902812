import { TStrapiDocument } from "@/types/StrapiDocument";
import { TStrapiImage } from "@/types/StrapiImage";

import { TChat } from "@/services/ChatService";
import { TMediaAreaData, TTriggerAreaData } from "@/services/SceneService";
import { TSpace } from "@/services/SpaceService/types";

export enum JoinRoomError {
  INVALID_MODULE = "Invalid Module!",
}

export type TJoinOptions = {
  slugInfo?: {
    moduleSlug: TModule["slug"];
    spaceSlug: TSpace["slug"];
  } | null;
  moduleId?: TModule["id"] | null;
};

export enum TModulesResponse {
  NO_MODULES_FOUND = "No modules found!",
}

export type TModuleBlueprint = {
  id: number;
  title: string;
  description: string;
  audioSources: any[];
  assetMetadata: {
    mediaAreas: {
      name: string;
    }[];
    startSpawn: {
      offset: {
        x: number;
        y: number;
      };
      position: {
        x: number;
        y: number;
        z: number;
      };
      rotation: number;
    };
    // ToDo Type audioSources
    audioSources: any[];
    triggerAreas: {
      name: string;
    }[];
  };
  asset: TStrapiDocument;
  useAsTemplate: boolean;
  overviewImage: TStrapiImage;
  overviewImageCallzones: TStrapiImage;
};

export type TModule = {
  id: number;
  space: TSpace["id"];

  title: string;
  description: string;

  previewImage: TStrapiImage;
  blueprint: TModuleBlueprint;

  canAccess: boolean;
  local: boolean;
  supportsVr: boolean;

  // optional data based on canJoin
  slug?: string;
  roomUrls?: Array<{
    id: number;
    roomUrl: string;
    __component: "room-url-entry.room-url";
  }>;
  mediaAreas?: Array<TMediaAreaData>;
  triggerAreas?: Array<TTriggerAreaData>;
  chat?: TChat["id"];
};
