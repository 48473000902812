import styled from "styled-components";

import ButtonRound from "@/components/Dom/Common/ButtonRound";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";

export const ButtonRoundVideo = styled(ButtonRound)`
  display: grid;
  place-items: center;
  background-color: ${(props) => props.backgroundColor};
  transition: background-color 0.25s linear;

  svg {
    width: ${(props) => `${props.iconWidth / 10}rem`};
    height: ${(props) => `${props.iconHeight / 10}rem`};
    fill: ${(props) => props.iconColor || colors.white.default};
  }

  &:not(:disabled) {
    ${Hover(`
      background-color: ${colors.tangerine.normal.hex};
      border-color: ${colors.tangerine.normal.hex};
      
      svg {
        fill: ${colors.white.default}
      }
    `)}
  }
`;

export const ToggleButton = styled(ButtonRoundVideo)`
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2rem;
    height: 0.1rem;
    background-color: ${colors.white.default};
    transform: translate(-50%, -50%) rotate(-45deg)
      scale(${(props) => (props.isActive ? 0 : 1)});
    transition: transform 0.4s ${ease.inOutCubic};
  }

  &:not(:disabled) {
    ${Hover(`
        background-color: ${colors.tangerine.normal.hex};
        border-color: ${colors.tangerine.normal.hex};
        color: ${colors.white.default};
        fill: ${colors.white.default};
      `)}
  }
`;
