import { signOut } from "next-auth/client";
import create from "zustand";

import cmsService from "@/services/CmsService";
import debugService from "@/services/DebugService";

type TAuthService = {
  init: (token: string | null) => boolean;
  token: string | null;
  signOut: (redirectUrl?: string) => void;
};

const authService = create<TAuthService>((set) => {
  return {
    token: null,
    init: (token) => {
      if (!token) return false;

      set({ token });
      return true;
    },
    signOut: async (redirectUrl) => {
      try {
        await cmsService.getState().requestData("/blacklistToken");
        // eslint-disable-next-line no-console
        console.warn("blacklisted token");
      } catch (error) {
        debugService
          .getState()
          .logError(`authService::signOut(): ${error}`, { errorCode: 606 });
      }

      await signOut({ callbackUrl: redirectUrl ? redirectUrl : "" });
    },
  };
});

export default authService;
