import { v4 as uuidv4 } from "uuid";
import create from "zustand";

import notificationService from "../NotificationService";
import { TUser } from "../UserService/types";

import StatusNotification from "@/components/Dom/Notifications/StatusNotification";

import authService from "@/services/AuthService";
import avatarConfigService from "@/services/AvatarConfigService";
import badgeService from "@/services/BadgeService";
import chatService from "@/services/ChatService";
import debugService from "@/services/DebugService";
import discoverQuestService from "@/services/DiscoverQuestService";
import moduleService from "@/services/ModuleService";
import navService from "@/services/NavService";
import { playerService } from "@/services/PlayerService";
import spaceService from "@/services/SpaceService";
import userService from "@/services/UserService";
import videoService from "@/services/VideoService";

async function persistSessionId() {
  const sessionId = await uuidv4();
  userService
    .getState()
    .setOwnUser({ sessionId: sessionId }, { sendData: true });
  localStorage.setItem("sessionId", sessionId);
}

/**
 * Compare sessionId persisted in the user object of strapi with sessionId in LocalStorage
 * to find out if a session was taken over from a different machine or browser.
 * Display Message if session was taken over.
 * @param user
 */
async function validateUserSession(user: TUser | null) {
  if (user) {
    const userSessionId = user?.sessionId;
    const localSessionId = localStorage.getItem("sessionId");

    if (!userSessionId) {
      await persistSessionId();
      return;
    }
    const doesSessionMatch = localSessionId == userSessionId;

    if (!doesSessionMatch) {
      await persistSessionId();
      notificationService
        .getState()
        .addNotification(
          <StatusNotification
            text={
              "The link you have used to login is already in use. The other user was logged out automatically."
            }
            type={"danger"}
          />,
          {
            position: "top",
            autoRemove: true,
            autoRemoveTimeout: 8000,
          }
        );
    }
  }
}

type TInitService = {
  isInitialized: boolean;
  init: (session: any, isOnHostPage?: boolean) => Promise<boolean>;
};

const initService = create<TInitService>((set) => {
  return {
    isInitialized: false,

    init: (session, isOnHostPage = false) => {
      return new Promise<boolean>(async (resolve, reject) => {
        if (!session) {
          reject("initService::init(): Invalid session!");
          return;
        }

        const startTime = Date.now();

        if (!debugService.getState().init()) {
          reject("debugService::init(): Failed to initialized debug service!");
          return;
        }

        const { token } = session.user;
        if (!authService.getState().init(token)) {
          reject("authService::init(): Invalid token!");
          return;
        }

        try {
          await userService.getState().init();

          const user = await userService.getState().ownUser;

          await validateUserSession(user);

          const spaces = await spaceService.getState().init(isOnHostPage);

          avatarConfigService.getState().init();
          playerService.getState().init();

          await Promise.all([
            moduleService.getState().init(),
            badgeService.getState().init(),
            navService.getState().init(),
          ]);

          if (spaces?.size > 0) {
            await Promise.all([
              chatService.getState().init(),
              videoService.getState().init(),
              discoverQuestService.getState().init(),
            ]);
          }
        } catch (error) {
          reject(error);
          return;
        }

        set({ isInitialized: true });
        resolve(true);
        // eslint-disable-next-line no-console
        console.log(`init app in ${(Date.now() - startTime) * 0.001} seconds`);
        return;
      });
    },
  };
});

export default initService;
