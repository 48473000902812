import { LogoWrapper } from "./styles";

import LogoSvg from "~/public/assets/icons/PwC.svg";

const Logo = () => {
  return (
    <LogoWrapper>
      <LogoSvg />
    </LogoWrapper>
  );
};

export default Logo;
