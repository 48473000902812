import CallAcceptButton from "@/components/Dom/VideoChat/Common/CallAcceptButton";
import CallEndButton from "@/components/Dom/VideoChat/Common/CallEndButton";
import CameraButton from "@/components/Dom/VideoChat/Common/CameraButton";
import MicrophoneButton from "@/components/Dom/VideoChat/Common/MicrophoneButton";

import videoService from "@/services/VideoService";

import { StyledVideoNotificationControls } from "./styles";

const VideoNotificationControls = () => {
  const callState = videoService((state) => state.callState);

  const hideOnError = () => {
    if (callState === "error") return false;
    if (callState === "callDeclined") return false;
    return true;
  };

  const hideOnGettingCall = () => {
    if (callState === "gettingCall") return true;
    if (callState === "gettingGroupCall") return true;
    return false;
  };

  return (
    <StyledVideoNotificationControls>
      {hideOnError() && <CameraButton />}

      {hideOnError() && <MicrophoneButton />}

      {hideOnGettingCall() && <CallAcceptButton />}

      {hideOnError() && <CallEndButton />}
    </StyledVideoNotificationControls>
  );
};

export default VideoNotificationControls;
