import { FC } from "react";

import Button from "@/components/Dom/Common/Button";

import popupOverlayService from "@/services/PopupOverlayService";

import { BigMidHeadline } from "@/styles/typography";

import {
  ButtonWrapper,
  DeclineButtonStyles,
  StyledConfirmDialog,
  StyledText,
} from "./styles";

type TConfirmDialog = {
  headline: string;
  subHeadline?: string;
  acceptButtonText?: string;
  declineButtonText?: string | null;
  showDeclineButton?: boolean;
  onAccept?: () => void;
  onDecline?: () => void;
};

const ConfirmDialog: FC<TConfirmDialog> = ({
  headline,
  subHeadline,
  acceptButtonText = "Ok",
  declineButtonText = "No",
  showDeclineButton = true,
  onAccept = () => {},
  onDecline = () => {},
}) => {
  const { close } = popupOverlayService.getState();

  if (!headline || !onAccept || !onDecline) return null;

  return (
    <StyledConfirmDialog>
      {subHeadline && <StyledText>{subHeadline}</StyledText>}
      <BigMidHeadline serif>{headline}</BigMidHeadline>

      <ButtonWrapper>
        <Button
          text={acceptButtonText}
          onClick={() => {
            onAccept?.();
            close();
          }}
        />
        {declineButtonText && showDeclineButton && (
          <Button
            text={declineButtonText}
            onClick={() => {
              onDecline?.();
              close();
            }}
            extraStyle={DeclineButtonStyles}
          />
        )}
      </ButtonWrapper>
    </StyledConfirmDialog>
  );
};

export default ConfirmDialog;
