import styled, { css } from "styled-components";

const centeredStyle = css`
  left: 50%;
  transform: translateX(-50%);
  place-items: center;
`;

const rightAlignedStyle = css`
  top: 3rem;
  right: 3rem;
  place-items: flex-end;
`;

export const StyledNotificationCenter = styled.aside`
  position: fixed;
  z-index: 14;
  display: grid;
  row-gap: 1.5rem;

  ${(props) => props.position == "right" && rightAlignedStyle}
  ${(props) =>
    props.position == "top" &&
    css`
      top: 3rem;
      ${centeredStyle};
    `}
  ${(props) =>
    props.position == "bottom" &&
    css`
      bottom: 3rem;
      ${centeredStyle};
    `}
`;
