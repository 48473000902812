import moduleService from "@/services/ModuleService";
import spaceService from "@/services/SpaceService";
import { TSpace } from "@/services/SpaceService/types";
import userService from "@/services/UserService";

export const buildSpaceSlug = (spaceId: TSpace["id"]) => {
  const space = spaceService.getState().getSpaceById(spaceId);
  if (!space) return "/";

  return `/${space.slug}`;
};

export const useUsersInSpace = (spaceId: TSpace["id"]) => {
  const users = userService((state) =>
    [...state.cachedUsers.values()].filter(
      (user) => user.currentSpaceId === spaceId
    )
  );

  return users;
};

export const buildDefaultSpaceSlug = () => {
  const spaces = spaceService.getState().spaces;
  const space = spaces?.values().next().value;

  if (spaces && spaces.size == 1 && space) {
    const defaultModule = moduleService
      .getState()
      .getModuleById(space.defaultModule);

    return `/${space?.slug}/${defaultModule?.slug}`;
  }
  return "/";
};

/**
 * If the user is only part of one space, return the default module of that space.
 * This is used for the quick access feature.
 */
export const getDefaultModuleId = () : number|undefined => {
  const spaces = spaceService.getState().spaces;
  const space = spaces?.values().next().value;

  if (spaces && spaces.size == 1 && space) {
    return space.defaultModule;
  }
  return;
};