import { ReactElement } from "react";
import create from "zustand";

import inputService from "@/services/InputService";

type TPopupOverlayService = {
  dialogRef: HTMLDialogElement | null;
  element: ReactElement | null;

  open: (element: ReactElement) => void;
  close: () => void;
};

const popupOverlayService = create<TPopupOverlayService>((set, get) => {
  return {
    dialogRef: null,
    element: null,

    open: (element) => {
      const { dialogRef } = get();
      if (!element || !dialogRef) return;

      inputService.getState().lockInput();

      set({ element });

      //@ts-expect-error typescript does not know the dialog element
      dialogRef.showModal();
    },
    close: () => {
      if (!get().dialogRef) return;

      inputService.getState().enableInput();

      //@ts-expect-error typescript does not know the dialog element
      get().dialogRef.close();
      set({ element: null });
    },
  };
});

export default popupOverlayService;
