import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { center, Hover } from "@/styles/mixins";

export const ButtonRoundStyles = css`
  --button-size: ${(props) =>
    props.large ? 5 : props.big ? 4 : props.mid ? 3 : 2}rem;
  --svg-size: ${(props) =>
    props.large ? 1.6 : props.big ? 1.5 : props.mid ? 1.2 : 0.8}rem;

  background-color: ${colors.orange.hex};
  border-radius: 100%;
  color: ${colors.white.default};
  display: grid;
  flex-shrink: 0;
  height: var(--button-size);
  place-items: center;
  position: relative;
  transition: all 0.3s ${ease.inOutCubic}, background-color 0.2s linear,
    border-color 0.2s linear;
  width: var(--button-size);

  &:not(:disabled) {
    ${Hover(`
      background-color: ${colors.tangerine.normal.hex};
      border-color: ${colors.tangerine.normal.hex};
      color: ${colors.white.default}
    `)}
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.grey.normal.hex};
  }

  svg {
    ${center};

    height: var(--svg-size);
    position: absolute;
    width: var(--svg-size);
  }
`;

export const StyledButtonRound = styled.button`
  ${ButtonRoundStyles}
`;
