import create from "zustand";

import { TUser, TUserData, TUserRequestInfo } from "./types";

import StatusNotification from "@/components/Dom/Notifications/StatusNotification";

import authService from "@/services/AuthService";
import cmsService from "@/services/CmsService";
import debugService from "@/services/DebugService";
import notificationService from "@/services/NotificationService";
import spaceService from "@/services/SpaceService";

export type TUserService = {
  cachedUsers: Map<number, TUser>;

  ownUser: TUser | null;
  ownUserData: TUserData | null;

  init: () => Promise<boolean>;

  getOwnUser: () => TUser | null;

  isSelf: (userId: TUser["id"]) => boolean;

  setOwnUser: (
    partialUser: Partial<TUser>,
    options?: { sendData?: boolean } // Whether to update the database or just update locally
  ) => Promise<Partial<TUser> | null>;
  setOwnUserData: (
    partialUserData: Partial<TUserData>
  ) => Promise<Partial<TUserData> | null>;
  setOwnProfileImage: (profileImage: File) => Promise<any | null>;

  getUserById: (userId: TUser["id"]) => Promise<TUser>;

  getUsers: (minCount?: number) => Promise<Array<TUser>>;
  getUsersByIds: (userIds: Array<TUser["id"]>) => Promise<Array<TUser>>;
  getUsersByName: (
    firstname: TUser["firstname"],
    lastname: TUser["lastname"]
  ) => Promise<Array<TUser>>;

  updateUser: (userId: TUser["id"], parameter: string, newValue: any) => void;
  updateUsers: (
    userIds: TUser["id"][],
    parameter: string,
    newValue: any
  ) => void;

  requestUsersByCount: (count: number) => Promise<Array<TUser>>;
  isCacheInDbParity: () => Promise<boolean>;

  getUserName: (userId: number) => Promise<string> | string;
  buildUserName: (user: TUser) => string;

  getUserData: (userId: TUser["id"]) => Promise<boolean>;
  setUserData: (
    userId: TUser["id"],
    userData: Partial<
      Pick<TUser, "firstname" | "lastname" | "validDate" | "invalidDate">
    >
  ) => Promise<TUser>;

  isOwnUserValid: () => boolean;
};

export const userCanBeRendered = (user: TUser): boolean => {
  if (!user.firstname && !user.lastname) return false;
  return true;
};

export const isUserValid = (user: TUser): boolean => {
  if (!user) return false;
  if (!user.validDate && !user.invalidDate) return true;
  if (!user.validDate || !user.invalidDate) return false;

  // all utc times
  const currentTimeMs = Date.parse(new Date().toISOString());
  const accountValidTimeMs = Date.parse(user.validDate);
  const accountInvalidTimeMs = Date.parse(user.invalidDate);

  if (
    currentTimeMs < accountValidTimeMs ||
    currentTimeMs > accountInvalidTimeMs
  )
    return false;

  return true;
};

const userService = create<TUserService>((set, get) => {
  const setUserAutoSignOut = (user: TUser): boolean => {
    if (!user) return false;
    if (!user.invalidDate) return true;

    // all utc times
    const currentTimeMs = Date.parse(new Date().toISOString());
    const accountInvalidTimeMs = Date.parse(user.invalidDate);

    const signOutTimeMs = accountInvalidTimeMs - currentTimeMs;

    setTimeout(async () => {
      // ToDo: Find solution to persists this during sign out -> change z index and redirect
      notificationService
        .getState()
        .addNotification(
          <StatusNotification
            text={"Your session has expired."}
            type={"danger"}
          />,
          { position: "top", autoRemove: true, autoRemoveTimeout: 8000 }
        );
      authService.getState().signOut("/setup/signin");
    }, signOutTimeMs);

    return true;
  };

  const requestUserById = async (userId: number) => {
    return new Promise<TUser>(async (resolve, reject) => {
      try {
        const user: TUser | undefined = await cmsService
          .getState()
          .requestData(`getUser/${userId}`);

        if (!user) {
          reject(TUserRequestInfo.NO_USER_FOUND);
          return;
        }

        //@ts-expect-error set userData as empty until it is loaded with  getUserData
        resolve({ ...user, userData: {}, currentModuleId: 0 });
        return;
      } catch (error) {
        reject(error);
        return;
      }
    });
  };

  const requestUsersByIds = async (userIds: Array<number>) => {
    return new Promise<Array<TUser>>(async (resolve, reject) => {
      if (!userIds[0]) {
        reject(TUserRequestInfo.NO_USER_FOUND);
        return;
      }

      const filteredUserIds = userIds.filter(
        (userId) => userId !== get().ownUser?.id
      );

      if (!filteredUserIds.length) {
        reject(TUserRequestInfo.NO_USER_FOUND);
        return;
      }

      const body = {
        userIds: filteredUserIds,
      };

      try {
        const users:
          | Array<TUser>
          | undefined = await cmsService
          .getState()
          .sendData(`getUsersById`, body);

        if (!users || users.length === 0) {
          reject(TUserRequestInfo.NO_USER_FOUND);
          return;
        }

        resolve(
          //@ts-expect-error set userData as empty until it is loaded with  getUserData
          users.map((user) => ({ ...user, userData: {}, currentModuleId: 0 }))
        );
        return;
      } catch (error) {
        reject(error);
        return;
      }
    });
  };

  return {
    cachedUsers: new Map(),
    ownUser: null,
    ownUserData: null,
    ownUserRef: null,

    isSelf: (userId) => get().getOwnUser()?.id == userId,

    init: () => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const ownUser = await cmsService.getState().requestData("getMe");

          if (!isUserValid(ownUser)) {
            // ToDo: Find solution to persists this during sign out -> change z index and redirect
            notificationService
              .getState()
              .addNotification(
                <StatusNotification
                  text={
                    "Your account is currently deactivated. Please check for new credentials."
                  }
                  type={"danger"}
                />,
                {
                  position: "top",
                  autoRemove: true,
                  autoRemoveTimeout: 8000,
                }
              );
            authService.getState().signOut("/setup/signin");
          } else setUserAutoSignOut(ownUser);

          // ToDo: Network error handling.
          const ownUserData = await cmsService
            .getState()
            .requestData(`getUserData/${ownUser.id}`);

          set({ ownUser, ownUserData });

          resolve(true);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },

    getOwnUser: () => {
      return get().ownUser;
    },

    getUserById: async (userId) => {
      return new Promise<TUser>(async (resolve, reject) => {
        if (get().isSelf(userId)) {
          const { ownUser } = get();
          if (ownUser) {
            resolve(ownUser);
            return;
          }
        }

        // try to get user from local cache
        const cachedUser: TUser | undefined = get().cachedUsers.get(userId);

        if (cachedUser) {
          resolve(cachedUser);
          return;
        }

        // request user from cms if not found
        try {
          const newUser: TUser = await requestUserById(userId);

          get().cachedUsers.set(newUser.id, newUser);

          resolve(newUser);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },

    // minCount = 0 returns all users in cache
    getUsers: (minCount = 0, getUserData = false) => {
      return new Promise<Array<TUser>>(async (resolve) => {
        const cachedUserCount = get().cachedUsers.size;

        // return all users if minCount is 0 and enough entries are in map
        if (!minCount || cachedUserCount >= minCount) {
          resolve(Array.from<TUser>(get().cachedUsers.values()));
          return;
        }

        const requestUserCount = minCount - cachedUserCount;

        try {
          await get().requestUsersByCount(requestUserCount);
        } catch (error) {
          if (error !== TUserRequestInfo.DATABASE_PARITY) {
            debugService
              .getState()
              .logError(`UserSearchPanel::onScrollBottom(): ${error}`);
          }
        }

        resolve(Array.from<TUser>(get().cachedUsers.values()));
        return;
      });
    },

    updateUser: async (userId, parameter, newValue) => {
      if (!userId || !parameter) return;
      const { cachedUsers } = get();

      try {
        const user = await get().getUserById(userId);

        if (user) {
          user[parameter] = newValue;
          cachedUsers.set(userId, user);
          set({ cachedUsers });
        }
      } catch (e) {
        debugService.getState().logError(`UserService::updateUser(): ${e}`);
      }
    },

    updateUsers: async (userIds, parameter, newValue) => {
      if (!userIds?.length || !parameter) return;
      const { cachedUsers } = get();

      try {
        const users = await get().getUsersByIds(userIds);

        if (users.length) {
          users.forEach((user) => {
            if (user) {
              user[parameter] = newValue;
              cachedUsers.set(user.id, user);
            }
          });

          set({ cachedUsers });
        }
      } catch (e) {
        debugService.getState().logError(`UserService::updateUsers(): ${e}`);
      }
    },

    getUsersByName: (firstname, lastname) => {
      return new Promise<Array<TUser>>(async (resolve, reject) => {
        if (!firstname && !lastname) {
          reject(TUserRequestInfo.NO_USER_FOUND);
          return;
        }

        if (!(await get().isCacheInDbParity())) {
          // ToDo: Type body for specific cms routes in frontend?
          const body = {
            firstname: firstname.toLowerCase(),
            lastname: lastname.toLowerCase(),
            exclude: Array.from(get().cachedUsers.keys()),
          };

          try {
            const users:
              | Array<TUser>
              | undefined = await cmsService
              .getState()
              .sendData(`getUsersByName`, body);

            // ToDo: Solve this with a set and forced rerender in user list.

            if (users?.length)
              users.forEach((user) => get().cachedUsers.set(user.id, user));
          } catch (error) {
            reject(error);
            return;
          }
        }

        let users: Array<TUser> = Array.from(get().cachedUsers.values());

        users = users.filter((user) => {
          if (!user.firstname || !user.lastname) return false;

          const userFirstname: string = user.firstname.toLowerCase();
          const userLastname: string = user.lastname.toLowerCase();

          if (
            !userFirstname.includes(firstname.toLowerCase()) ||
            !userLastname.includes(lastname.toLowerCase()) ||
            get().isSelf(user.id)
          )
            return false;

          return true;
        });

        resolve(users);
        return;
      });
    },

    isCacheInDbParity: () => {
      return new Promise<boolean>(async (resolve, reject) => {
        try {
          const userCount: number = await cmsService
            .getState()
            .requestData(`getUserCount`);

          resolve(userCount === get().cachedUsers.size);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },

    requestUsersByCount: (count) => {
      return new Promise<Array<TUser>>(async (resolve, reject) => {
        if (count < 1) {
          resolve([]);
          return;
        }

        // check if we already have all user in cache
        try {
          if (await get().isCacheInDbParity())
            reject(TUserRequestInfo.DATABASE_PARITY);
        } catch (error) {
          reject(error);
          return;
        }

        // get user amount by count that are not already in cache and exclude service role users
        const body = {
          count,
          exclude: Array.from<number>(get().cachedUsers.keys()),
        };

        try {
          const requestedUsers:
            | Array<TUser>
            | undefined = await cmsService
            .getState()
            .sendData("getUsersByCount", body);

          if (!requestedUsers?.length) {
            reject(TUserRequestInfo.NO_USER_FOUND);
            return;
          }

          const users = new Map(requestedUsers.map((i) => [i.id, i]));

          set({
            cachedUsers: new Map([...get().cachedUsers, ...users]),
          });

          resolve(requestedUsers);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },

    getUsersByIds: async (userIds) => {
      return new Promise<Array<TUser>>(async (resolve, reject) => {
        if (!userIds.length) {
          resolve([]);
          return;
        }

        let users: Array<TUser> = new Array<TUser>();
        const requestUserIds: Array<number> = new Array<number>();

        // get all users that are in map
        userIds.forEach((userId) => {
          if (get().isSelf(userId)) {
            const { ownUser } = get();
            if (ownUser) users.push(ownUser);
          }

          const user: TUser | undefined = get().cachedUsers.get(userId);
          if (!user) requestUserIds.push(userId);
          else users.push(user);
        });

        if (requestUserIds.length > 0) {
          try {
            // request users which are missing in on single call
            const requestedUsers: Array<TUser> = await requestUsersByIds(
              requestUserIds
            );

            users = users.concat(requestedUsers);
          } catch (error) {
            // eslint-disable-next-line no-console
            // console.error(`userService::getUsersByIds(): ${error}`);
          }
        }

        users.forEach((user) => get().cachedUsers.set(user.id, user));

        if (!users[0]) {
          reject(TUserRequestInfo.NO_USER_FOUND);
          return;
        }

        resolve(users);
        return;
      });
    },

    buildUserName: (user) => {
      if (!user) return "Anonymous Participant";
      if (!user.firstname) return user.lastname;
      if (!user.lastname) return user.firstname;
      return `${user.firstname} ${user.lastname}`;
    },

    getUserName: async (userId) => {
      try {
        const user: TUser = await get().getUserById(userId);
        return get().buildUserName(user);
      } catch (error) {
        // eslint-disable-next-line no-console
        debugService.getState().logWarn(`userService::getUserName(): ${error}`);
        return "";
      }
    },

    setOwnUserData: async (partialUserData) => {
      return new Promise(async (resolve, reject) => {
        const userData = { ...get().ownUserData, ...partialUserData };

        try {
          const data = await cmsService
            .getState()
            .sendData(`/setOwnUserData`, { userData }, "POST");

          set({ ownUserData: data.userData });
          resolve(data.userData);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },

    setOwnUser: async (partialUser, options = {}) => {
      const { sendData = true } = options;

      return new Promise(async (resolve, reject) => {
        const user: Partial<TUser> = { ...get().ownUser, ...partialUser };

        if (sendData) {
          try {
            const data = await cmsService
              .getState()
              .sendData(`/setOwnUser`, { user }, "POST");

            set({ ownUser: data.user });
            resolve(data);
            return;
          } catch (error) {
            reject(error);
            return;
          }
        } else {
          //@ts-expect-error Idk why this is wrong
          set({ ownUser: user });
          resolve(user);
        }
      });
    },

    setOwnProfileImage: async (profileImage) => {
      return new Promise(async (resolve, reject) => {
        const formData = new FormData();
        formData.append("profileImage", profileImage);

        try {
          const data = await cmsService
            .getState()
            .sendData(`/setOwnProfileImage`, formData, "POST");
          set({
            ownUser: {
              ...data.user,
              profileImage: data.user.profileImage,
            },
          });

          resolve(data.user.profileImage);
        } catch (error) {
          reject(error);
        }
      });
    },

    getUserData: (userId) => {
      return new Promise<boolean>(async (resolve, reject) => {
        try {
          const user = get().cachedUsers.get(userId);

          if (!user) {
            reject(TUserRequestInfo.NO_USER_FOUND);
            return;
          }

          // ToDo: Use type guard / schema check instead of Object keys?
          if (user.userData && Object.keys(user.userData).length > 0) {
            resolve(true);
            return;
          }

          const userData = await cmsService
            .getState()
            .requestData(`getUserData/${userId}`);

          if (userData && user.userData !== userData) {
            set((state) => {
              const cachedUsers = new Map(state.cachedUsers);
              cachedUsers.set(userId, { ...user, userData });
              return { cachedUsers };
            });
            resolve(true);
            return;
          }

          reject(TUserRequestInfo.NO_USER_FOUND);
          return;
        } catch (error) {
          reject(error);
        }
      });
    },

    setUserData: (userId, userData) => {
      const spaceId = spaceService.getState().currentSpaceId;

      return new Promise<TUser>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`UserService::setUserData: no space was set`);
          reject();
          return;
        }

        try {
          let user;

          if (get().isSelf(userId)) user = get().ownUser;
          else user = get().cachedUsers.get(userId);

          if (!user) {
            reject(TUserRequestInfo.NO_USER_FOUND);
            return;
          }

          const updatedUser = await cmsService.getState().sendData(
            `updateUser`,
            {
              spaceId,
              userId,
              userData,
            },
            "PUT"
          );

          if (updatedUser) {
            set((state) => {
              const cachedUsers = new Map(state.cachedUsers);
              cachedUsers.set(userId, { ...updatedUser, userData });
              return { cachedUsers };
            });
            resolve(updatedUser);
            return;
          }

          reject(TUserRequestInfo.NO_USER_FOUND);
          return;
        } catch (error) {
          reject(error);
        }
      });
    },

    isOwnUserValid: () => {
      const user = get().getOwnUser();

      if (!user || !user.firstname || !user.lastname) return false;

      const userData = get().ownUserData;

      if (!userData) return false;

      return true;
    },
  };
});

export default userService;
