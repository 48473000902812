import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { Text } from "@/styles/typography";

export const StyledConfirmDialog = styled.div`
  text-align: center;
  padding: 4.5rem 4rem 5rem;
  border-radius: 1rem;
  background-color: ${colors.white.default};
  overflow: visible;
  max-width: 66rem;
  min-width: 40rem;

  ${Text} {
    margin-bottom: 2rem;
    color: ${colors.grey.light.hex};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

export const StyledText = styled(Text)`
  white-space: pre-wrap;
`;
export const DeclineButtonStyles = css`
  background-color: ${colors.grey.normal.hex};
`;
