import React, { ReactElement } from "react";
import create from "zustand";

import NewChatMessageNotification from "@/components/Dom/Chat/NewMessageNotification";
import UserName from "@/components/Dom/Common/UserName";
import StatusNotification from "@/components/Dom/Notifications/StatusNotification";

import cmsService from "@/services/CmsService";
import debugService from "@/services/DebugService";
import moduleService from "@/services/ModuleService";
import { TModule } from "@/services/ModuleService/types";
import notificationService from "@/services/NotificationService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import asyncInterval from "@/utils/AsyncInterval";
import { formatTime } from "@/utils/Date";

type TMessageNotificationData = {
  headline: string | ReactElement;
  text: string;
  time: string;
  chatId: number;
};

type TMessageResponse = {
  chatId: TChat["id"];
  messages: Array<TMessage>;
  lastMessageTimestamp: TChat["lastMessageTimestamp"];
};

export enum EChatType {
  PUBLIC = "public",
  PRIVATE = "private",
}
export type TChat = {
  id: number;
  type: EChatType;
  users: Array<TUser["id"]>;
  lastMessageTimestamp: string;
  messageCount: number;
  newMessageCount: number;
  lastActivity: number;
};

export type TMessage = {
  id: number;
  content: string;
  userId: number;
  created_at: string;
};

const BASE_UPDATE_MS = 8000;

const CONFIG = {
  ACTIVE_CHAT_MESSAGE_POLLING_MS: BASE_UPDATE_MS,
  MESSAGE_POLLING_MS: BASE_UPDATE_MS * 2.0,
  CHAT_POLLING_MS: BASE_UPDATE_MS * 4.0,
};

type TChatService = {
  chats: Array<TChat>;
  activeChat: TChat | null;

  messages: Array<TMessage>;

  init: () => Promise<boolean>;

  connectChat: (activeChat: TChat, allMessages?: boolean) => void;
  disconnectChat: () => void;

  sendMessage: (content: string) => Promise<boolean>;

  createChat: (userIds: Array<number>) => Promise<TChat>;

  resetNewMessageCount: (chatId: number) => void;

  getChatByModule: (module: TModule) => TChat | null;
  getChatsByType: (type: EChatType) => Array<TChat>;
  getChatByUserIds: (userIds: Array<TUser["id"]>) => TChat | null;
};

export const MAX_CONTENT_LENGTH: number = 255;

const chatService = create<TChatService>((set, get) => {
  const createMessageNotificationData = async (
    chatId: TChat["id"],
    message: TMessage
  ): Promise<TMessageNotificationData> => {
    const user: TUser = await userService
      .getState()
      .getUserById(message.userId);

    const headline = <UserName users={[user]} bold applyFontStyle={false} />;

    return {
      headline,
      text: message.content,
      time: formatTime(message["created_at"]),
      chatId: chatId,
    } as TMessageNotificationData;
  };
  const sendMessageNotifications = (notificationData) => {
    notificationData.forEach((data) => {
      notificationService
        .getState()
        .addNotification(
          <NewChatMessageNotification
            headline={data.headline}
            text={data.text}
            time={data.time}
            chatId={data.chatId}
          />,
          { position: "right", autoRemoveTimeout: 6000 }
        );
    });
  };
  const sendChatNotification = async (chat: TChat) => {
    const users = await cacheChatUsers([chat]);

    notificationService
      .getState()
      .addNotification(
        <NewChatMessageNotification
          headline={
            users.length
              ? "Users are ready to chat with you:"
              : "User is ready to chat with you:"
          }
          text={<UserName users={users} bold applyFontStyle={false} />}
          chatId={chat.id}
        />,
        { position: "right", autoRemoveTimeout: 6000 }
      );
  };

  const cacheChatUsers = async (chats: TChatService["chats"]) => {
    const userIds = Array.from(new Set(chats.flatMap((chat) => chat.users)));

    return await userService.getState().getUsersByIds(userIds);
  };

  // initialize local chat state for use with chat bubble system and ordering
  const initChatState = (chats: TChatService["chats"]) => {
    const now = Date.now();

    // @ts-ignore
    const ownUserId = userService.getState().ownUser.id;

    for (const chat of chats) {
      chat.newMessageCount = 0;
      chat.users = chat.users.filter((userId) => userId !== ownUserId);

      if (chat.type === EChatType.PUBLIC) continue;

      chat.lastActivity = now;
    }
  };

  const getNewMessages = async (
    timestamp?: string | null
  ): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      const { activeChat } = get();

      if (!activeChat) {
        const errorMessage = `chatService::updateMessages(): No active chat found!`;
        debugService.getState().logError(errorMessage);

        reject(errorMessage);
        return;
      }

      try {
        // parse request body data
        // we ask for new messages in the chats with id and greater than timestamp
        const chats = [
          {
            id: activeChat.id,
            timestamp: timestamp || activeChat.lastMessageTimestamp,
          },
        ];

        // request new chat messages
        const chatsWithNewMessages: Array<TMessageResponse> = await cmsService
          .getState()
          .sendData(`/chats/getMessages`, { chats });

        if (!chatsWithNewMessages?.length) {
          resolve(false);
          return;
        }

        const { messages } = chatsWithNewMessages[0];

        // return if no chat messages are new
        if (!messages.length) {
          resolve(false);
          return;
        }

        // update chat cache
        // @ts-ignore
        get().activeChat.lastMessageTimestamp =
          messages[messages.length - 1].created_at;

        // update messages and force rerender
        set((state) => ({
          messages: state.messages.concat(messages),
        }));

        resolve(true);
        return;
      } catch (error) {
        const errorMessage = `chatService::updateMessages(): ${error}`;
        debugService.getState().logError(errorMessage);

        reject(errorMessage);
        return;
      }
    });
  };

  const getChatWithUsers = (userIds: Array<TUser["id"]>): TChat | null => {
    // @ts-ignore
    const ownUserId = userService.getState().ownUser.id;

    const chat = get().chats.find((chat: TChat) => {
      // filter out own user
      const chatUserIds = chat.users.filter((userId) => userId !== ownUserId);

      // return early if more or less users are in chat which is checked
      if (chatUserIds.length !== userIds.length) return false;

      // console.log(`chatService::getChatWithUsers(): User ids =`, chat.users);

      // check if the chat has the same users
      return chatUserIds.every((userId) => userIds.indexOf(userId) > -1);
    });

    // console.log(`chatService::getChatWithUsers(): Chat =`, chat);

    return chat || null;
  };

  // @ts-ignore
  let stopActiveChatMessagePolling: null | Function = null;
  const startActiveChatMessagePolling = () => {
    stopActiveChatMessagePolling = asyncInterval(
      getNewMessages,
      CONFIG.ACTIVE_CHAT_MESSAGE_POLLING_MS
    );
  };

  // @ts-ignore
  let stopChatPolling: null | Function = null;
  const getChats = (notify = false) => {
    return new Promise<Array<TChat>>(async (resolve, reject) => {
      try {
        const data = await cmsService
          .getState()
          .requestData(`/chats/getChats`, false);

        // return current chats if no new ones were found
        if (!data?.chats?.length) {
          resolve(get().chats);
          return;
        }

        const chats = data.chats as Array<TChat>;
        initChatState(chats);

        // return new chats if no current chats are present
        // this will happen on chat service init
        if (!notify || !get().chats.length) {
          set({ chats });

          resolve(get().chats);
          return;
        }

        for (const chat of chats) {
          if (get().chats.some((entry) => entry.id === chat.id)) continue;

          try {
            await sendChatNotification(chat);
          } catch (error) {
            const errorMessage = `chatService::updateChats(): ${error}`;
            debugService.getState().logError(errorMessage);

            reject(errorMessage);
            return;
          }
        }

        // apply cached last activity timestamp to chat
        // only new chats will have current time as last activity
        // this is for keeping the order in main nav after a new chat has been added
        chats.forEach((chat) => {
          const cachedChat = get().chats.find(
            (cachedChat) => cachedChat.id === chat.id
          );
          chat.lastActivity = cachedChat
            ? cachedChat.lastActivity
            : chat.lastActivity;
          chat.newMessageCount = cachedChat
            ? cachedChat.newMessageCount
            : chat.newMessageCount;
        });

        set({ chats });

        // console.log(`chatService::getChats(): Got new chats =`, chats);

        resolve(get().chats);
        return;
      } catch (error) {
        const errorMessage = `chatService::updateChats(): ${error}`;
        debugService.getState().logError(errorMessage);

        reject(errorMessage);
        return;
      }
    });
  };

  // check for new messages in all direct chats
  const startChatPolling = () => {
    const pollNewChats = () => {
      return new Promise<boolean>(async (resolve, reject) => {
        try {
          const privateChatCount = await cmsService
            .getState()
            .requestData(`/chats/getChatCount`);

          const publicChats = get().getChatsByType(EChatType.PUBLIC);

          // check only if there are new private chats by subtracting the public space chat count from the total chats count
          if (privateChatCount > get().chats.length - publicChats.length)
            await getChats(true);

          resolve(true);
          return;
        } catch (error) {
          reject(
            `chatService::pollNewChats(): Failed to poll new chats = ${error}`
          );
          return;
        }
      });
    };

    stopChatPolling = asyncInterval(pollNewChats, CONFIG.CHAT_POLLING_MS);
  };

  // @ts-ignore
  let stopMessagePolling: null | Function = null;
  const startMessagePolling = () => {
    const pollNewMessages = () => {
      return new Promise<boolean>(async (resolve) => {
        try {
          const { chats } = get();

          // parse request body data
          // we ask for new messages in the chats with id and greater than timestamp
          const data = chats
            .filter(({ id }) => id !== get().activeChat?.id)
            .map((chat) => ({
              id: chat.id,
              timestamp: chat.lastMessageTimestamp,
            }));

          // request new chat messages
          const chatsWithNewMessages: Array<TMessageResponse> = await cmsService
            .getState()
            .sendData(`/chats/getMessages`, { chats: data });

          if (!chatsWithNewMessages?.length) {
            resolve(true);
            return;
          }

          // setup notification data cache to render after update completed
          const notificationDataCache: Array<TMessageNotificationData> = [];

          const now = new Date().getTime();

          for (const chatWithNewMessages of chatsWithNewMessages) {
            const chat = chats.find(
              (chat) => chat.id === chatWithNewMessages.chatId
            );

            if (!chat) continue;

            // update cache for sorting chats in ui by last activity
            chat.lastActivity = now;
            chat.lastMessageTimestamp =
              chatWithNewMessages.messages[
                chatWithNewMessages.messages.length - 1
              ]["created_at"];

            for (const message of chatWithNewMessages.messages) {
              const { userId } = message;

              // do not send message or display message bubble if user is not in the current module or if it is his own message received
              if (
                userService.getState().isSelf(userId) ||
                (chat.type === EChatType.PUBLIC &&
                  moduleService.getState().getCurrentModule()?.chat !== chat.id)
              )
                continue;

              // used for message bubbles in main nav ui
              chat.newMessageCount++;

              const notificationData = await createMessageNotificationData(
                chatWithNewMessages.chatId,
                message
              );
              notificationDataCache.push(notificationData);
            }
          }

          // force rerender to display new message bubbles in main nav ui
          set({ chats: Array.from(chats) });

          sendMessageNotifications(notificationDataCache);

          resolve(true);
          return;
        } catch (error) {
          debugService
            .getState()
            .logError(
              `chatService::checkNewChatMessages(): Failed to get new chat messages = ${error}`
            );

          resolve(false);
          return;
        }
      });
    };

    stopMessagePolling = asyncInterval(
      pollNewMessages,
      CONFIG.MESSAGE_POLLING_MS
    );
  };

  return {
    chats: [],

    activeChat: null,
    messages: [],

    init: () => {
      return new Promise<boolean>(async (resolve) => {
        // get all (module and group) chats for user and cache participants if allowed
        try {
          // this is allowed to fail if we receive old group chats with users
          // that we are no longer allowed to see because space permissions changed
          await cacheChatUsers(await getChats());
        } catch (error) {
          debugService
            .getState()
            .logError(`chatService::init(): Failed initializing = ${error}`);
        }

        // start polling for new chats and messages in existing ones

        // there exists two messages polling:
        // one low frequent one for all group chats
        // and one high frequent one for the currently active open chat started and stopped on demand

        startChatPolling();
        startMessagePolling();

        resolve(true);
        return;
      });
    },

    sendMessage: (content: string) => {
      return new Promise<boolean>(async (resolve, reject) => {
        try {
          const { activeChat, chats } = get();

          if (content.length > MAX_CONTENT_LENGTH) {
            reject("chatService::sendMessage(): Message is too long!");
            return;
          }

          if (!activeChat) {
            reject(
              "chatService::sendMessage(): User is not connected to a chat"
            );
            return;
          }

          // stop message polling while sending new message
          if (stopActiveChatMessagePolling) {
            stopActiveChatMessagePolling();
            stopActiveChatMessagePolling = null;
          }

          await cmsService
            .getState()
            .sendData(
              `/chats/${activeChat.id}/addMessage`,
              { content },
              "POST"
            );

          // sort chat to top
          const updatedChats = chats.map((chat) => {
            if (chat.id !== activeChat.id) return chat;

            chat.lastActivity = Date.now();
            return chat;
          });
          set({ chats: updatedChats });

          get().connectChat(activeChat, false);

          resolve(true);
          return;
        } catch (error) {
          const errorMessage = `chatService::sendMessage(): Failed to send message = ${error}`;
          debugService.getState().logError(errorMessage);

          reject(error);
          return;
        }
      });
    },

    connectChat: (activeChat, allMessages = true) => {
      // update active chat
      set({ activeChat });

      if (allMessages)
        getNewMessages(new Date(0).toISOString()).then(() =>
          startActiveChatMessagePolling()
        );
      else startActiveChatMessagePolling();
    },

    disconnectChat: () => {
      // stop check for new messages to avoid notification when you close a chat directly after receiving one
      if (stopActiveChatMessagePolling) {
        stopActiveChatMessagePolling();
        stopActiveChatMessagePolling = null;
      }

      set({
        activeChat: null,
        messages: [],
      });
    },

    createChat: (userIds) => {
      return new Promise<TChat>(async (resolve, reject) => {
        let chat = getChatWithUsers(userIds);

        if (chat) {
          // console.log(`chatService::createChat(): Chat already exists!`);
          resolve(chat);
          return;
        }

        // console.log(`chatService::createChat(): Creating new chat.`);

        try {
          chat = await cmsService
            .getState()
            .sendData("/chats/createChat", { userIds }, "POST");

          if (stopChatPolling) stopChatPolling();

          // update chats
          await getChats();

          startChatPolling();

          resolve(chat as TChat);
          return;
        } catch (error) {
          notificationService
            .getState()
            .addNotification(
              <StatusNotification
                text={"Sorry, we were not able to create this chat"}
              />
            );

          const errorMessage = `chatService::createChat(): Failed to create chat = ${error}`;
          debugService.getState().logError(errorMessage);

          reject(errorMessage);
          return;
        }
      });
    },

    resetNewMessageCount: (chatId) => {
      const chats: Array<TChat> = get().chats.map((chat) => {
        if (chat.id === chatId) chat.newMessageCount = 0;
        return chat;
      });

      // force rerender
      set({ chats });
    },

    getChatByModule: (module: TModule) => {
      if (!module) return null;

      const chat = get().chats.find((chat) => chat.id === module.chat);

      if (!chat) return null;

      return chat;
    },
    getChatsByType: (type: EChatType) => {
      if (!type) return [];

      return get().chats.filter((chat) => chat.type === type);
    },
    getChatByUserIds: (userIds) => {
      if (!userIds.length) return null;

      return getChatWithUsers(userIds);
    },
  };
});

export default chatService;
