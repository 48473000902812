import { useEffect } from "react";
import shallow from "zustand/shallow";

import cmsService from "@/services/CmsService";
import debugService from "@/services/DebugService";
import moduleService from "@/services/ModuleService";
import { TModule, TJoinOptions } from "@/services/ModuleService/types";
import spaceService from "@/services/SpaceService";
import userService from "@/services/UserService";
import asyncInterval from "@/utils/AsyncInterval";

export const buildModuleSlug = (moduleId: TModule["id"]) => {
  if (!moduleId) return "/";

  const module = moduleService.getState().getModuleById(moduleId);
  if (!module) return "/";

  const space = spaceService.getState().getSpaceById(module.space);
  if (!space) return "/";

  return `/${space.slug}/${module.slug}`;
};

export const isModuleValid = (moduleId: TModule["id"]): boolean => {
  if (!moduleId) return false;

  const module = moduleService.getState().getModuleById(moduleId);
  if (!module || !module.blueprint || !module.blueprint.assetMetadata)
    return false;

  return true;
};

export const isModulePathValid = (
  slugInfo: TJoinOptions["slugInfo"]
): TModule["id"] | null => {
  const { logError } = debugService.getState();

  if (!slugInfo) {
    logError("moduleService::isValidModulePath(): Invalid input parameter!");
    return null;
  }

  const { moduleSlug, spaceSlug } = slugInfo;

  if (!moduleSlug || !spaceSlug) {
    logError("moduleService::isValidModulePath(): Invalid input parameter!");
    return null;
  }

  const space = spaceService.getState().getSpaceBySlug(spaceSlug);
  if (!space) {
    logError("moduleService::isValidModulePath(): No space found!");
    return null;
  }

  const module = moduleService.getState().getModuleBySlug(moduleSlug);
  if (!module) {
    logError("moduleService::isValidModulePath(): No module found!");
    return null;
  }

  if (!space.modules.includes(module.id)) {
    logError(
      "moduleService::isValidModulePath(): Module is not part of space!"
    );
    return null;
  }

  return module.id;
};

export const useUsersInModule = (moduleId: TModule["id"]) => {
  const users = userService(
    (state) =>
      [...state.cachedUsers.values()].filter(
        (user) =>
          user.currentModuleId === moduleId &&
          !userService.getState().isSelf(user.id)
      ),
    shallow
  );

  useEffect(() => {
    if (!moduleService.getState().canAccessModule(moduleId)) return;

    const updateUsers = async () => {
      try {
        const userIds = await cmsService
          .getState()
          .requestData(`getUsersInModule/${moduleId}`);

        const { cachedUsers } = userService.getState();

        await userService.getState().getUsersByIds(userIds);

        userIds.forEach((userId) => {
          const user = cachedUsers.get(userId);

          if (user) {
            user.currentModuleId = moduleId;
            cachedUsers.set(userId, user);
          }
        });

        cachedUsers.forEach((user) => {
          if (!userIds.includes(user.id) && user.currentModuleId === moduleId) {
            const _user = cachedUsers.get(user.id);

            if (_user) {
              _user.currentModuleId = 0;
              cachedUsers.set(user.id, _user);
            }
          }
        });

        userService.setState({ cachedUsers });
      } catch (error) {
        debugService
          .getState()
          .logError(
            `useUsersInModule(): Failed to get users in module = ${error}`
          );
      }
    };

    const killInterval = asyncInterval(updateUsers, 5000);
    return () => killInterval();
  }, [moduleId]);

  return users;
};
