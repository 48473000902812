import { Howler, HowlOptions, Howl } from "howler";
import { v4 as uuidv4 } from "uuid";
import create from "zustand";

import localStorage from "@/utils/LocalStorage";

type TSoundSettings = {
  muted: boolean;
  volume: number;
};

type TSoundService = {
  settings: TSoundSettings;
  sounds: Map<string, Howl>;

  update: (
    listenerPosition: Array<number>,
    listenerRotation: Array<number>
  ) => void;

  toggleMuted: () => void;

  play: (
    src: string,
    options?: HowlOptions & {
      delay: number | null;
      position: Array<number> | null;
    }
  ) => string;
  stop: (id: string) => void;
};

const soundService = create<TSoundService>((set, get) => {
  const settings = localStorage.get("audioSettings") || {
    muted: false,
    volume: 0.5,
  };
  Howler.volume(settings.volume);
  Howler.pos([0.0, 0.0, 0.0]);

  const add = (id: string, sound: Howl) => {
    set((state) => {
      const sounds = new Map(state.sounds);
      sounds.set(id, sound);
      return { sounds };
    });
  };

  const remove = (id: string) => {
    set((state) => {
      const sounds = new Map(state.sounds);
      sounds.delete(id);
      return { sounds };
    });
  };

  return {
    settings,
    sounds: new Map<string, Howl>(),

    update: (listenerPosition, listenerDirection) => {
      Howler.pos(listenerPosition[0], listenerPosition[1], listenerPosition[2]);
      Howler.orientation(
        listenerDirection[0],
        listenerDirection[1],
        listenerDirection[2],
        0,
        1,
        0
      );
    },

    toggleMuted: () => {
      set((state) => {
        const { settings } = state;

        settings.muted = !settings.muted;
        localStorage.set("audioSettings", settings);

        return { settings };
      });
    },

    play: (src, options = {}) => {
      //TODO: prevent sounds from double playing
      if (get().settings.muted || !src) return;

      const soundId = uuidv4();

      const sound = new Howl({
        src: [src],
        ...options,
        onplay: (id) => {
          // onplay is every loop called so we need this gate
          if (get().sounds.has(soundId)) return;

          // This is a workaround because the options for pannerAttr seems not to be applied
          if (options.pannerAttr) {
            sound.pannerAttr(options.pannerAttr, id);
          }
          sound.radius = options.pannerAttr?.rolloffFactor || 1.0;

          add(soundId, sound);
        },
        onstop: () => {
          remove(soundId);
        },
      });

      if (options.delay) {
        setTimeout(() => {
          sound.play();
        }, options.delay);
      } else if (!options.autoplay) {
        sound.play();
      }

      return soundId;
    },
    stop: (id) => {
      const sound = get().sounds.get(id);

      if (!sound) return;

      sound.stop();
    },
  };
});

export default soundService;
