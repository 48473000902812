import { useState, useEffect } from "react";

import cmsService from "@/services/CmsService";
import debugService from "@/services/DebugService";
import { TMediaAreaData } from "@/services/SceneService";
// NOTE: A very similar version of this file exits in Strapi!
// extensions/upload/admin/src/utils/azure-sas.js

interface SasData {
  maxAgeMs: number;
  parameters: string;
  createdAt: number;
  accountName: string;
  containerName: string;
}

type SasUrl = string | null | undefined;

// Global state, all component instances should share the same SAS token
let activeSas: SasData | null = null;
let newSasPromise: Promise<SasData> | null = null;

/**
 * Function that can be used to inject a URL with SAS parameters
 * for private azure storage account access.
 *
 * Usage:
 *
 * const urlWithParams: string = useSasParams(url);
 *
 */
export const appendSasParams = async (url: SasUrl) => {
  if (!url) return "";

  const needSas: boolean = url.includes("blob.core.windows.net");

  if (!needSas) return url;

  // Are we already fetching a new SAS?
  if (!sasIsValid(activeSas) && newSasPromise) {
    activeSas = await newSasPromise;
  }

  if (!sasIsValid(activeSas)) {
    newSasPromise = fetchSas();
    activeSas = await newSasPromise;
    newSasPromise = null;
  }

  if (!sasIsValid(activeSas)) return "";

  const params: string = activeSas.parameters;
  const accountName: string = activeSas.accountName;

  let urlWithParams: string = url;

  const isCorrectAccount = url.includes(accountName);

  if (!isCorrectAccount) return url;

  if (!params || !accountName) return "";

  urlWithParams += urlWithParams.includes("?") ? "&" : "?";
  urlWithParams += params;

  return urlWithParams;
};

/**
 * Fetches an Azure Storage Account Shared Access Signature (SAS)
 * The SAS can be used to make requests to the azure storage account containing the user-uploaded assets.
 * To get a SAS our custom Strapi SAS API is used.
 */
export async function fetchSas(): Promise<SasData> {
  try {
    const response = await cmsService
      .getState()
      .requestData("/azure/storage/sas");

    return {
      // Substract one minute from the maxAgeMs because this request also took some time...
      maxAgeMs: response.sas.maxAgeMs - 1000 * 60,
      parameters: response.sas.parameters,
      createdAt: Date.now(),
      accountName: response.sas.accountName,
      containerName: response.sas.containerName,
    };
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Checks whether a sas object is valid.
 */
export function sasIsValid(sas: SasData | null): sas is SasData {
  if (!sas) {
    return false;
  }

  const age = Date.now() - sas.createdAt;

  if (age > sas.maxAgeMs) {
    return false;
  }

  return true;
}

// Data URL of a 1px GIF
export const PLACEHOLDER_URL =
  "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";

/**
 * React hook that can be used to inject a URL with SAS parameters
 * for private azure storage account access.
 *
 * Usage:
 *
 * const { urlWithParams } = useSasParams(props.src);
 *
 */
export function useSasParams(url: SasUrl) {
  if (!url) {
    url = "";
  }

  const [sasParams, setSasParams] = useState<string | null>(null);
  const [sasAccountName, setSasAccountName] = useState<string | null>(null);

  // Not all Azure storage blobs need a SAS. E.g. the blobs from the assets
  // storage account are already public. However, if the URL is an Azure
  // storage URL, we still fetch the SAS and then check if the SAS is valid
  // for this account. The reason for this approach is that it simplifies
  // the configuration.
  const mayNeedSas = url.includes("blob.core.windows.net");

  useEffect(() => {
    async function init() {
      let sas = activeSas;

      // Are we already fetching a new SAS?
      if (!sasIsValid(sas) && newSasPromise) {
        sas = await newSasPromise;
      }

      if (!sasIsValid(sas)) {
        newSasPromise = fetchSas();
        activeSas = await newSasPromise;
        newSasPromise = null;

        sas = activeSas;
      }

      setSasParams(sas.parameters);
      setSasAccountName(sas.accountName);
    }

    if (mayNeedSas) {
      init().catch((error) => {
        debugService
          .getState()
          .logError(
            `Can not display: Azure blob ${url}, SAS could not be fetched ${error}`
          );

        // Set params so that urlWithParams is set to the right URL
        setSasParams("sas=error");
      });
    }
  }, [url]);

  let urlWithParams = url;
  if (mayNeedSas) {
    let params = sasParams;
    let accountName = sasAccountName;

    if (!params && sasIsValid(activeSas)) {
      // useEffect wasn't called yet, but we can already use the cached sas
      params = activeSas.parameters;
      accountName = activeSas.accountName;
    }

    if (params && accountName) {
      const isCorrectAccount = url.includes(accountName);

      if (isCorrectAccount) {
        urlWithParams += urlWithParams.includes("?") ? "&" : "?";
        urlWithParams += params;
      }
    } else {
      // Do not load image until we have the right URL
      urlWithParams = PLACEHOLDER_URL;
    }
  }

  return {
    urlWithParams,
  };
}

/**
 * Function to download assets from azure.
 * The function will trigger an automatic download.
 *
 * Usage:
 *
 * onClick={() => downloadMediaAsset(item: TMediaAreaData)}
 *
 */
export function downloadMediaAsset(item: TMediaAreaData) {
  appendSasParams(item.media.url).then((url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = item.media.name;
        a.click();
      });
  });
}
