import React from "react";
import { io, Socket } from "socket.io-client";
import create from "zustand";

import StatusNotification from "@/components/Dom/Notifications/StatusNotification";

import authService from "@/services/AuthService";
import debugService from "@/services/DebugService";
import moduleService from "@/services/ModuleService";
import notificationService from "@/services/NotificationService";
import permissionService, {
  PERMISSION_ACTION,
} from "@/services/PermissionService";
import { playerService } from "@/services/PlayerService";
import spaceService from "@/services/SpaceService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import videoService from "@/services/VideoService";
import httpClient from "@/utils/HttpClient";

type TMessageType =
  | "CLIENT_UPDATE"
  | "CLIENT_OUTFIT_CHANGED"
  | "CLIENT_NOTIFICATION"
  | "CLIENT_REACTION"
  | "SERVER_UPDATE"
  | "SERVER_CLIENT_CONNECTED"
  | "SERVER_CLIENT_LEFT"
  | "SERVER_ALL_CLIENTS"
  | "SERVER_CLIENT_OUTFIT_CHANGED"
  | "SERVER_CLIENT_NOTIFICATION"
  | "SERVER_CLIENT_REACTION"
  | "SERVER_CLIENT_SPAWNED"
  | "SERVER_TICK_INTERVAL"
  | "SERVER_VIDEO_CALL_STATE"
  | "VIDEO_ROOM_CANCELED"
  | "VIDEO_ROOM_CREATED"
  | "VIDEO_ROOM_INVITED"
  | "VIDEO_ROOM_ACCEPTED"
  | "VIDEO_ROOM_DECLINED"
  | "VIDEO_ROOM_JOINED"
  | "VIDEO_ROOM_LEFT"
  | "VIDEO_CREATE"
  | "VIDEO_RECONNECT"
  | "VIDEO_JOIN"
  | "VIDEO_JOIN_STATIC_ROOM"
  | "VIDEO_LEAVE"
  | "VIDEO_INVITE"
  | "VIDEO_ACCEPT"
  | "VIDEO_DECLINE"
  | "AUTH_FAILED"
  | "VIDEO_CANCEL"
  | "VIDEO_USER_STATE_CHANGED";

type TRehRoom = {
  socket: Socket | null;
  listeners: Map<TMessageType, Function[]>;
};

type TRehRooms = {
  [key in TRoomType]: TRehRoom;
};

type TRoomType = "communication" | "module";

type TRehService = {
  isConnected: (type?: TRoomType) => boolean;

  subscribe: (
    roomType: TRoomType,
    messageType: TMessageType,
    callback: Function
  ) => void;
  unsubscribe: (
    roomType: TRoomType,
    messageType: TMessageType,
    callback: Function
  ) => void;

  connect: (roomType: TRoomType) => Promise<boolean>;
  disconnect: (roomType: TRoomType) => void;

  sendMessage: (
    roomType: TRoomType,
    messageType: TMessageType,
    data: any
  ) => void;

  sendNotification: (
    message: string,
    userIds: Array<TUser["id"]>,
    payload?: {}
  ) => void;

  requestRoomOnDemand: (data) => Promise<string>;
};

const rehService = create<TRehService>((set, get) => {
  const rooms: TRehRooms = {
    communication: {
      socket: null,
      listeners: new Map(),
    },
    module: {
      socket: null,
      listeners: new Map(),
    },
  };

  const getBaseUrlAndPathFromUrl = (url) => {
    const parsedUrl = new URL(url);
    return {
      baseUrl: parsedUrl.origin,
      path: parsedUrl.pathname,
    };
  };

  return {
    subscribe: (roomType, messageType, callback) => {
      if (!rooms[`${roomType}`].listeners.has(messageType))
        rooms[`${roomType}`].listeners.set(messageType, []);

      rooms[`${roomType}`].listeners.get(messageType)!.push(callback);
    },

    unsubscribe: (roomType, messageType, callback) => {
      const listeners = rooms[`${roomType}`].listeners.get(messageType);
      if (!listeners) return;

      rooms[`${roomType}`].listeners.set(
        messageType,
        listeners.filter((listener) => listener !== callback)
      );
    },

    connect: (roomType) => {
      return new Promise(async (resolve, reject) => {
        if (!roomType) {
          reject("No type set");
          return;
        }

        let roomUrlPromise: Promise<string | null>;

        switch (roomType) {
          // ToDo: (@Max / @Eric) This caused an issue with joining a module because the local module cache was invalid and not refreshed
          // On the first look this is the same logic as before but I (Eric) do not understand why this differs
          /*
          else if (spaceService.getState().getRoomUrlFromCurrentSpace()) {
            roomUrlPromise = Promise.resolve(
                spaceService.getState().getRoomUrlFromCurrentSpace()
            );
          }
           */

          case "communication":
            // set NEXT_PUBLIC_REH_LOCAL_URL in your .env for local testing with running reh service on localhost:5000
            if (process.env.NEXT_PUBLIC_REH_COMMUNICATION_PATH) {
              roomUrlPromise = Promise.resolve(
                process.env.NEXT_PUBLIC_REH_COMMUNICATION_PATH
              );
            } else {
              const space = spaceService.getState().getCurrentSpace();
              if (!space) break;

              // no room url is set. Request room at rehshed api
              roomUrlPromise = get().requestRoomOnDemand({
                instanceId: space.id,
                roomType: "communication",
              });
            }
            break;

          case "module":
            // ToDo: (@Max / @Eric) This caused an issue with joining a module because the local module cache was invalid and not refreshed
            // On the first look this is the same logic as before but I (Eric) do not understand why this differs
            /*
            else if (moduleService.getState().getRoomUrlFromCurrentModule()) {
              roomUrlPromise = Promise.resolve(
                moduleService.getState().getRoomUrlFromCurrentModule()
              );
            } */

            if (process.env.NEXT_PUBLIC_REH_MODULE_PATH) {
              // set NEXT_PUBLIC_REH_LOCAL_URL in your .env for local testing when running reh service on localhost:5000
              roomUrlPromise = Promise.resolve(
                process.env.NEXT_PUBLIC_REH_MODULE_PATH
              );
            } else {
              const module = moduleService.getState().getCurrentModule();
              if (!module) break;

              // no room url is set. Request room at rehshed api
              roomUrlPromise = get().requestRoomOnDemand({
                instanceId: module.id,
                roomType: "module",
              });
            }
            break;
        }

        // @ts-expect-error
        if (!roomUrlPromise) {
          reject("No room url found set");
          return;
        }

        const url = await roomUrlPromise;

        const { baseUrl, path } = getBaseUrlAndPathFromUrl(url);

        if (get().isConnected(roomType)) {
          rooms[`${roomType}`].socket?.disconnect();
        }

        rooms[`${roomType}`].socket = io(baseUrl, {
          path,
          auth: {
            userId: userService.getState().ownUser?.id,
            jwt: authService.getState().token,
          },
        });

        const { socket, listeners } = rooms[`${roomType}`];

        //When an event comes in, get listener and execute all callbacks
        socket!.onAny((event, data) => {
          const callbacks = listeners.get(event);
          if (callbacks) callbacks.forEach((callback) => callback(data));
        });

        //Register default listeners
        socket!.on("connect", () => {
          resolve(true);
        });

        // @ts-ignore
        socket!.io.on("reconnect", () => {
          if (roomType === "communication") {
            videoService.getState().reconnect();
          } else {
            const ownUser = userService.getState().ownUser;
            if (!ownUser) {
              // eslint-disable-next-line no-console
              console.error(
                "RehService::reconnect(): Failed reconnection to module reh room - no own user found in user service!"
              );
              return;
            }

            const playerTransform = playerService
              .getState()
              .allPlayers.get(ownUser.id);
            if (!playerTransform) {
              // eslint-disable-next-line no-console
              console.error(
                "RehService::reconnect(): Failed reconnection to module reh room - no player transform found in player service!"
              );
              return;
            }

            const transform = {
              position: playerTransform.currentPosition,
              rotation: playerTransform.currentRotation,
            };

            setTimeout(
              () =>
                get().sendMessage("module", "SERVER_CLIENT_SPAWNED", transform),
              2000
            );
          }
        });

        socket!.on("connect_error", (error) => {
          debugService
            .getState()
            .logError(
              `rehService::connect(): Connect error on room type ${roomType} = ${error}`,
              { errorCode: 604 }
            );

          reject(
            new Error(`Authentication on socket failed with error: ${error}`)
          );
        });

        socket!.on("connect_timeout", (error) => {
          debugService
            .getState()
            .logError(
              `socket::callback(): Connect timeout on room type ${roomType} = ${error}`,
              { errorCode: 682 }
            );
        });
      });
    },

    disconnect: (roomType) => {
      if (!roomType || !rooms[`${roomType}`]?.socket) return;

      rooms[`${roomType}`].socket!.disconnect();
      rooms[`${roomType}`].socket = null;
    },

    sendMessage: (roomType, messageType, data) => {
      rooms[`${roomType}`].socket?.emit(messageType, data);
    },

    sendNotification: (message, userIds = [], payload) => {
      if (
        !permissionService
          .getState()
          .can(PERMISSION_ACTION.NOTIFICATION_SEND) ||
        !message
      )
        return;

      get().sendMessage("communication", "CLIENT_NOTIFICATION", {
        message,
        userIds,
        payload,
      });

      //TODO: add icon
      notificationService
        .getState()
        .addNotification(
          <StatusNotification text={"Notification published"} />
        );
    },

    //TODO: make it possible to subscribe to this
    isConnected: (roomType) => {
      if (roomType) return Boolean(rooms[`${roomType}`].socket);
      return Boolean(rooms.communication.socket && rooms.module.socket);
    },

    requestRoomOnDemand: (data: Object) => {
      return new Promise<string>(async (resolve, reject) => {
        const response = await httpClient.sendData(
          `${process.env.NEXT_PUBLIC_REHSHED_URL}/open-room-on-demand`,
          data,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authService.getState().token}`,
          },
          "POST"
        );

        if (!response || !response.data?.roomUrl) {
          reject(
            `rehService::requestRoomOnDemand(): Error requesting room on demand - invalid rehshed response = ${response}!`
          );
          return;
        }

        resolve(response.data.roomUrl);
        return;
      });
    },
  };
});

export default rehService;
