import create from "zustand";

type TLoadingScreenService = {
  viewState: "fadeIn" | "fadeOut" | "fadeOutComplete";
  loadingProgress: number;
  isPageReady: boolean;
  lastUrl: string;
  pageReady: () => void;
  fadeIn: () => Promise<any> | void;
  fadeOut: () => void;
};

const loadingScreenService = create<TLoadingScreenService>((set, get) => ({
  viewState: "fadeIn",
  loadingProgress: 0,
  isPageReady: false,
  lastUrl: "",

  pageReady: () => {
    set({ isPageReady: true });
  },

  fadeIn: () => {
    if (get().viewState === "fadeIn") return;
    set({ viewState: "fadeIn" });

    return new Promise((resolve) => {
      setTimeout(() => {
        // Wait until the animation has played
        resolve(true);
      }, 751);
    });
  },

  fadeOut: () => {
    if (get().viewState === "fadeOut") return;
    set({ viewState: "fadeOut" });

    setTimeout(() => {
      if (get().viewState !== "fadeOut") return;

      set({ viewState: "fadeOutComplete" });
    }, 751);
  },
}));

export default loadingScreenService;
