import styled from "styled-components";

import { colors } from "@/styles/colors";
import { Text } from "@/styles/typography";

export const StyledUserBadge = styled(Text)`
  position: relative;
  display: inline-block;
  color: ${colors.white.default};
  line-height: 1;
  text-transform: uppercase;
  background: ${(props) => props.color};
  border-radius: 1rem;
  padding: 0.3rem 0.4rem 0.3rem;
  transform: translateY(-0.25em);
`;
