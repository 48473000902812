import create from "zustand";

import {
  customizationScheme,
  getOutfitStyles,
  getRandomAvatarConfig,
  getSkin,
  rand,
} from "@/services/AvatarConfigService/customizationScheme";
import debugService from "@/services/DebugService";
import { playerService, TAvatarConfig } from "@/services/PlayerService";
import rehService from "@/services/RehService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

type TAvatarConfigService = {
  configs: Map<TUser["id"], TAvatarConfig>;
  init: () => void;
  setConfig: (config: TAvatarConfig) => Promise<TAvatarConfig>;
  loadConfig: (userId: TUser["id"]) => Promise<TAvatarConfig>;
  onConfigChanged: (userId) => void;
  randomizeConfig: () => void;
};

export const validateAndFixConfig = (config: TAvatarConfig): TAvatarConfig => {
  const randomConfig = getRandomAvatarConfig();
  if (!config) return JSON.parse(JSON.stringify(randomConfig));

  // fix single elements if missing
  if (!config.outfit || !customizationScheme.outfit.includes(config.outfit)) {
    config.outfit = randomConfig.outfit;
  }

  const outfitParts: Array<string> = config.outfit.split("_");
  const outfitName: string = outfitParts[2];
  const outfitSid: string = outfitParts[1];

  if (
    !config.outfitStyle ||
    !getOutfitStyles(outfitName, outfitSid).find(
      (style) => style === config.outfitStyle
    )
  )
    config.outfitStyle = rand(getOutfitStyles(outfitName, outfitSid));

  let validExtras: boolean = true;

  config.extras.forEach((extra) => {
    if (
      !customizationScheme.extras.includes(extra) &&
      (!extra.includes("0") || !extra.includes(outfitSid))
    )
      validExtras = false;
  });

  if (!config.extras || !validExtras) config.extras = randomConfig.extras;

  if (!config.hairCut || !customizationScheme.hairCut.includes(config.hairCut))
    config.hairCut = randomConfig.hairCut;

  if (
    !config.hairColor ||
    !customizationScheme.hairColor.includes(config.hairColor)
  )
    config.hairColor = randomConfig.hairColor;

  if (
    !config.skinColor ||
    !customizationScheme.skinColor.includes(config.skinColor)
  )
    config.skinColor = randomConfig.skinColor;

  if (!config.skin || config.skin !== getSkin(outfitName, outfitSid))
    config.skin = randomConfig.skin;

  return config;
};

const avatarConfigService = create<TAvatarConfigService>((set, get) => {
  return {
    configs: new Map(),

    init: () => {
      rehService
        .getState()
        .subscribe(
          "module",
          "SERVER_CLIENT_OUTFIT_CHANGED",
          get().onConfigChanged
        );
    },

    setConfig: (config) => {
      return new Promise<TAvatarConfig>(async (resolve, reject) => {
        const ownUser = userService.getState().ownUser;
        if (!ownUser) {
          reject(`avatarConfigService::setConfig(): Failed to get own user!`);
          return;
        }

        const validConfig = validateAndFixConfig(config);

        set((state) => {
          state.configs.set(ownUser.id, validConfig);
        });

        try {
          await playerService.getState().setAvatarConfig(validConfig);

          resolve(validConfig);

          if (rehService.getState().isConnected("module")) {
            rehService
              .getState()
              .sendMessage("module", "CLIENT_OUTFIT_CHANGED", null);
          }

          return;
        } catch (error) {
          const message = `avatarConfigService::setConfig(): Failed to persist config = ${error}`;

          debugService.getState().logError(message);
          reject(message);
          return;
        }
      });
    },

    onConfigChanged: ({ userId }) => {
      if (!userService.getState().isSelf(userId)) get().loadConfig(userId);
    },

    loadConfig: async (userId) => {
      return new Promise<TAvatarConfig>(async (resolve) => {
        try {
          const playerData = await playerService
            .getState()
            .getPlayerData(userId);

          const config = validateAndFixConfig(playerData.avatarConfig);

          set(({ configs }) => {
            configs.set(userId, config);
          });

          resolve(config);
          return;
        } catch (error) {
          debugService
            .getState()
            .logError(
              `avatarConfigService::loadConfig(): Failed to load config = ${error}`
            );

          const randomConfig = getRandomAvatarConfig();

          set(({ configs }) => {
            const config = configs.has(userId)
              ? ({ ...configs.get(userId) } as TAvatarConfig)
              : randomConfig;

            configs.set(userId, config);
          });
          resolve(randomConfig);
          return;
        }
      });
    },

    randomizeConfig: () => {
      get().setConfig(getRandomAvatarConfig());
    },
  };
});

export default avatarConfigService;
