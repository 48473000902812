import { ReactElement, useEffect } from "react";

import soundService from "@/services/AudioService";
import chatService, { EChatType, TChat } from "@/services/ChatService";
import moduleService from "@/services/ModuleService";
import { TModule } from "@/services/ModuleService/types";
import navService, { EOverviewPanel } from "@/services/NavService";
import notificationService from "@/services/NotificationService";

import { Text } from "@/styles/typography";

import {
  StyledNewChatMessageNotification,
  Headline,
  TextWrapper,
  Time,
} from "./styles";

type TNewChatMessageNotification = {
  headline: string | ReactElement;
  text: string | ReactElement;
  time?: string;
  chatId: number;
  playSound?: boolean;
  id?: string;
};

const NewChatMessageNotification = ({
  headline,
  text,
  time,
  chatId,
  playSound = true,
  id,
}: TNewChatMessageNotification) => {
  if (playSound) {
    useEffect(() => {
      soundService
        .getState()
        .play("/assets/audio/notification-chat.mp3", { delay: 100 });
    }, []);
  }

  const onClick = () => {
    if (id) notificationService.getState().removeNotification(id);

    //TODO: better way to find correct Chat
    const chat: TChat | undefined = chatService
      .getState()
      .chats.find((chat) => chat.id === chatId);
    if (!chat) return;

    const { type } = chat;

    if (type === EChatType.PUBLIC) {
      //TODO: better way to find correct Module Blueprint
      const module: TModule | undefined = moduleService
        .getState()
        .modules.find((module) => module.chat === chatId);

      // ToDo: Change section type "Space" to "Module"?
      module &&
        navService.getState().setPanel(EOverviewPanel.SPACES, module.id, 1);
    } else {
      navService.getState().setPanel(EOverviewPanel.CHATS, chatId, 1);
    }
  };

  return (
    <StyledNewChatMessageNotification onClick={onClick}>
      <Headline bold={true}>{headline}</Headline>

      {time && <Time light={true}>{time}</Time>}

      <TextWrapper>
        <Text>{text}</Text>
      </TextWrapper>
    </StyledNewChatMessageNotification>
  );
};

export default NewChatMessageNotification;
