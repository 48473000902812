import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";
import { BigHeadline } from "@/styles/typography";

const defaultStyle = css`
  background-color: rgba(${colors.white.rgba}, 0.6);
  color: ${colors.grey.text.hex};
  transition: background-color 0.2s linear;

  ${Hover(`background-color: rgba(${colors.white.rgba}, 1);`)}
`;

const dangerStyle = css`
  background-color: ${colors.red.normal.hex};
  color: ${colors.white.default};
`;

export const StyledStatusNotification = styled.div`
  position: relative;
  padding: 2rem;
  display: flex;
  align-items: center;
  border-radius: var(--notification-radius);
  width: fit-content;

  ${(props) => (props.type === "danger" ? dangerStyle : defaultStyle)};
`;

export const IconWrapper = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  width: ${(props) => `${props.iconWidth}px`};
  height: ${(props) => `${props.iconHeight}px`};
  margin-right: ${(props) => `${(30 - props.iconWidth) * 0.5 + 9}px`};
  margin-left: ${(props) => `${(30 - props.iconWidth) * 0.5}px`};

  &:before {
    content: "";
    position: absolute;
    top: ${(props) => `${-(30 - props.iconHeight) * 0.5}px`};
    left: ${(props) => `${-(30 - props.iconWidth) * 0.5}px`};
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background-color: ${colors.grey.text.hex};
  }

  svg {
    object-fit: contain;
    width: 100%;
    height: 100%;
    z-index: 2;
    fill: ${colors.white.default};
  }
`;

export const Headline = styled(BigHeadline)`
  max-width: 35rem;
  text-align: center;
`;
