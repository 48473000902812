import { useEffect, useState } from "react";

import UserName from "../../Common/UserName";

import AvatarImage from "@/components/Dom/Common/AvatarImage";
import VideoNotificationControls from "@/components/Dom/VideoChat/VideoNotificationControls";

import debugService from "@/services/DebugService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import videoService, { TVideoService } from "@/services/VideoService";

import {
  StyledVideoNotification,
  TextWrapper,
  ImageWrapper,
  ParticipantsNumber,
  NumberText,
  Headline,
  SubHeadline,
} from "./styles";

export type TVideoNotification = {
  userIds: TUser["id"][];
};

const getSubheading = (callState: TVideoService["callState"]) => {
  if (callState === "calling") return "You are calling";
  if (callState === "gettingCall") return "Is calling...";
  if (callState === "callingGroup") return "You are starting a";
  if (callState === "gettingGroupCall") return "Invite to join";
  if (callState === "callDeclined") return "Call declined";
  return "Call cancelled";
};

const getHeadline = (callState: TVideoService["callState"], users) => {
  if (callState === "callingGroup") return "Group Call";

  const userName = <UserName users={users} bold applyFontStyle={false} />;

  if (callState === "calling") return userName;
  if (callState === "gettingCall") return userName;

  return <span>{userName} is not available</span>;
};

const VideoNotification = ({ userIds }: TVideoNotification) => {
  const ownUser = userService((state) => state.ownUser);
  const callState = videoService((state) => state.callState);
  const [users, setUsers] = useState<TUser[]>([]);
  const numberOfParticipants = userIds.length;

  useEffect(() => {

    const init = async () => {
      try {
        const users = await userService.getState().getUsersByIds(userIds);
        setUsers(users);
      } catch (error) {
        debugService
          .getState()
          .logError(`VideoNotification::useEffect[]: ${error}`);
      }
    };

    init();
  }, []);

  if (!users?.length || !ownUser) return null;

  return (
    <StyledVideoNotification>
      <TextWrapper callState={callState}>
        <SubHeadline>{getSubheading(callState)}</SubHeadline>
        <Headline>{getHeadline(callState, users)}</Headline>
      </TextWrapper>

      {callState !== "error" && <VideoNotificationControls />}

      <ImageWrapper>
        <AvatarImage users={ownUser} />

        {numberOfParticipants > 2 && (
          <ParticipantsNumber>
            <NumberText bold={true}>{numberOfParticipants - 2}</NumberText>
          </ParticipantsNumber>
        )}

        <AvatarImage users={users[0]} />
      </ImageWrapper>
    </StyledVideoNotification>
  );
};

export default VideoNotification;
