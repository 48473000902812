import {
  AudioLoader,
  CubeTextureLoader,
  Mesh,
  MeshStandardMaterial,
  sRGBEncoding,
  TextureLoader,
} from "three";
import { GLTFLoader, DRACOLoader } from "three-stdlib";
import create from "zustand";

import cmsService from "@/services/CmsService";

type TAssetData = {
  name: string | number;
  type: string;
  data: any; //TODO Type
};

type TAssetService = {
  isInitialized: boolean;
  loadAsset: (
    name: TAssetData["name"],
    type: "geometry" | "texture" | "map" | "cubemap" | "audio",
    urls: Array<string>
  ) => Promise<any>;
  getAsset: (name: TAssetData["name"]) => any;
  assets: Array<TAssetData>;
  init: () => Promise<any>;
};

const assetService = create<TAssetService>((set, get) => {
  const textureLoader = new TextureLoader();
  const cubeTextureLoader = new CubeTextureLoader();
  const gltfLoader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  const audioLoader = new AudioLoader();

  dracoLoader.setDecoderPath(
    "https://www.gstatic.com/draco/versioned/decoders/1.4.3/"
  );
  gltfLoader.setDRACOLoader(dracoLoader);

  return {
    isInitialized: false,
    assets: new Array<TAssetData>(),

    init: async () => {
      return new Promise<boolean>(async (resolve, reject) => {
        if (get().isInitialized) {
          resolve(true);
          return;
        }
        const startTime = Date.now();

        // PRELOAD SKYBOX AND AVATAR METADATA FROM STRAPI

        const preloadPromises = Array<Promise<any>>();

        preloadPromises.push(
          cmsService.getState().requestData("skybox", false, true)
        );
        preloadPromises.push(
          cmsService.getState().requestData("avatar", false, true)
        );

        const values = await Promise.all(preloadPromises).catch((error) => {
          reject(error);
          return;
        });

        const skybox = values[0];

        if (!skybox.right?.url)
          skybox.right = { url: "/assets/fallback-skybox/Right.png" };
        if (!skybox.left?.url)
          skybox.left = { url: "/assets/fallback-skybox/Left.png" };
        if (!skybox.top?.url)
          skybox.top = { url: "/assets/fallback-skybox/Top.png" };
        if (!skybox.bottom?.url)
          skybox.bottom = { url: "/assets/fallback-skybox/Bottom.png" };
        if (!skybox.back?.url)
          skybox.back = { url: "/assets/fallback-skybox/Back.png" };
        if (!skybox.front?.url)
          skybox.front = { url: "/assets/fallback-skybox/Front.png" };

        //const avatar = values[1];

        // tmp load avatar from next server
        const avatar = { asset: { url: "/assets/meshes/PWC_Avatar_v22.glb" } };

        if (!avatar || !avatar.asset?.url) {
          reject(
            `assetService::init(): Error getting avatar metadata from cms!`
          );
          return;
        }

        // LOAD AVATAR, SKYBOX AND TEXTURES

        const loadPromises = Array<Promise<any>>();

        loadPromises.push(
          get().loadAsset("skybox", "cubemap", [
            skybox.right.url,
            skybox.left.url,
            skybox.top.url,
            skybox.bottom.url,
            skybox.back.url,
            skybox.front.url,
          ])
        );

        loadPromises.push(
          get().loadAsset("avatar", "geometry", [avatar.asset.url])
        );

        const textures: { name: string; url: string }[] = [
          { name: "iconPdf", url: "pdf.png" },
          { name: "iconStream", url: "stream.png" },
          { name: "iconScreenshare", url: "stream.png" },
          { name: "iconStream", url: "share.png" },
          { name: "iconImage", url: "image.png" },
          { name: "iconEnlarge", url: "enlarge.png" },
          { name: "iconVideo", url: "video.png" },
          { name: "iconCamera", url: "camera.png" },
          { name: "iconCameraSimple", url: "camera_simple.png" },
          { name: "arrowMask", url: "arrowMask.png" },
          { name: "callIconMask", url: "callIconMask.png" },
          { name: "iconsAtlas", url: "iconsAtlas.png" },
          { name: "puffAtlas", url: "puffAtlas.png" },
          {
            name: "maPlaceholderFallback",
            url: "MediaAreaFallbackPlaceholder.png",
          },
          { name: "iconLink", url: "link.png" },
          { name: "iconIframe", url: "link.png" },
          { name: "iconQuest", url: "quest.png" },
          { name: "iconMicrophone", url: "microphone.png" },
          { name: "iconMicrophoneMuted", url: "microphone_muted.png" },
          { name: "iconMicrophoneOn", url: "microphone_on.png" },
          { name: "iconMicrophoneOff", url: "microphone_off.png" },
          { name: "iconHand", url: "hand.png" },
          { name: "iconHandActive", url: "hand_active.png" },
          { name: "iconCallEnd", url: "call_end.png" },
          { name: "iconMinify", url: "minify.png" },
          { name: "iconArrow", url: "arrow.png" },
          { name: "iconLoading", url: "loading.png" },
          { name: "videoMinimize", url: "Video_CloseButton.png" },
          { name: "videoMinimizeHover", url: "Video_CloseButtonHover.png" },

          { name: "videoPlay", url: "Video_PlayButton.png" },
          { name: "videoPlayHover", url: "Video_PlayButtonHover.png" },

          { name: "videoPause", url: "Video_PauseButton.png" },
          { name: "videoPauseHover", url: "Video_PauseButtonHover.png" },

          { name: "videoForward", url: "Video_ForwardButton.png" },
          { name: "videoForwardHover", url: "Video_ForwardButtonHover.png" },

          { name: "videoBackward", url: "Video_BackwardButton.png" },
          { name: "videoBackwardHover", url: "Video_BackwardButtonHover.png" },
          { name: "videoBackground", url: "ButtonBackground.png" },

          { name: "muteButton", url: "ButtonMute.png" },
          { name: "unmuteButton", url: "ButtonUnmute.png" },


          { name: "vrReticleDefault", url: "VRDefault.png" },
          { name: "vrReticleHover", url: "VRHover.png" },
          { name: "vrReticleSelected", url: "VRSelected.png" },
          { name: "vrReticleTeleport", url: "VRTeleport.png" },
        ];

        textures.forEach(({ name, url }) => {
          loadPromises.push(
            get().loadAsset(name, "texture", [`/assets/images/${url}`])
          );
        });

        try {
          await Promise.all(loadPromises);

          // load and cache avatar outfit textures
          const avatarAsset = assetService.getState().getAsset("avatar")
            .data.scene;

          avatarAsset.traverse((object) => {
            if (object.name === "Materials") {
              object.children.forEach((mesh: Mesh) => {
                get().assets.push({
                  name: mesh.name,
                  type: "map",
                  data: (mesh.material as MeshStandardMaterial).map,
                });
              });
            }
          });

          set({ isInitialized: true });
          resolve(true);
          // eslint-disable-next-line no-console
          console.log(
            `loaded assets in ${(Date.now() - startTime) * 0.001} seconds`
          );
          return;
        } catch (error) {
          reject(`assetService::init(): Failed loading assets = ${error}`);
          return;
        }
      });
    },
    loadAsset: async (name, type, urls) => {
      return new Promise<boolean>((resolve, reject) => {
        const asset = get().getAsset(name);

        if (asset) {
          resolve(true);
          return;
        } else {
          switch (type) {
            case "geometry": {
              gltfLoader.load(
                urls[0],
                (gltf) => {
                  get().assets.push({
                    name: name,
                    type: "geometry",
                    data: gltf,
                  });
                  resolve(true);
                  return;
                },
                () => {},
                (e) => {
                  reject(
                    `assetService::loadAsset(): Failed to load geometry from = ${urls[0]} with error = ${e}!`
                  );
                  return;
                }
              );
              break;
            }
            case "texture": {
              textureLoader.load(
                urls[0],
                (texture) => {
                  texture.encoding = sRGBEncoding;
                  get().assets.push({
                    name: name,
                    type: "texture",
                    data: texture,
                  });
                  resolve(true);
                  return;
                },
                () => {},
                (e) => {
                  reject(
                    `assetService::loadAsset(): Failed to load texture from = ${urls[0]} with error = ${e}!`
                  );
                  return;
                }
              );
              break;
            }
            case "cubemap": {
              const cubemap = cubeTextureLoader.load(urls);
              if (cubemap) {
                get().assets.push({
                  name: name,
                  type: "cubemap",
                  data: cubemap,
                });
                resolve(true);
                return;
              } else {
                reject(
                  `assetService::loadAsset(): Failed to load cubemap from = ${urls}!`
                );
                return;
              }
            }
            case "map": {
              const map = textureLoader.load(urls[0]);
              if (map) {
                get().assets.push({
                  name: name,
                  type: "map",
                  data: map,
                });
                resolve(true);
                return;
              } else {
                reject(
                  `assetService::loadAsset(): Failed to load map from = ${urls}!`
                );
                return;
              }
            }
            case "audio": {
              audioLoader.load(
                urls[0],
                (audioBuffer) => {
                  get().assets.push({
                    name: name,
                    type: "audio",
                    data: audioBuffer,
                  });
                  resolve(true);
                  return;
                },
                () => {},
                (e) => {
                  reject(
                    `assetService::loadAsset(): Failed to load audio from url = ${urls[0]} with error = ${e}!`
                  );
                  return;
                }
              );
              break;
            }
            default:
              reject(
                `assetService::loadAsset(): Unknown asset type = ${type}!`
              );
              break;
          }
        }
      });
    },
    getAsset: (name) => {
      return get().assets.find((asset) => asset.name === name);
    },
  };
});

export default assetService;
