import { FC } from "react";

import badgeService from "@/services/BadgeService";
import { TUser } from "@/services/UserService/types";

import { StyledUserBadge } from "./styles";

type TUserBadge = React.HTMLAttributes<HTMLSpanElement>;

export interface IProps {
  user: TUser;
}

const UserBadge: FC<IProps & TUserBadge> = ({ user, ...others }) => {
  const badge = badgeService.getState().getBadge(user.badge);

  if (!badge) return null;

  return (
    <StyledUserBadge color={badge.color} bold as="span" {...others}>
      {badge.title}
    </StyledUserBadge>
  );
};

export default UserBadge;
