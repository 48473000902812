import videoService from "@/services/VideoService";

import { colors } from "@/styles/colors";

import { StyledCallAcceptButton } from "./styles";

import Phone from "~/public/assets/icons/PhoneRotated.svg";

const CallAcceptButton = () => {
  const currentRoomId = videoService((state) => state.currentRoomId);

  const acceptCall = async () => {
    if (currentRoomId) await videoService.getState().accept(currentRoomId);
  };

  return (
    <StyledCallAcceptButton
      icon={<Phone />}
      backgroundColor={colors.green.normal.hex}
      onClick={acceptCall}
      large
    />
  );
};

export default CallAcceptButton;
