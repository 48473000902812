import create from "zustand";

import ConfirmDialog from "@/components/Dom/Common/ConfirmDialog";

import popupOverlayService from "@/services/PopupOverlayService";
import localStorage from "@/utils/LocalStorage";

type TLogEntry = {
  type: "INFO" | "WARNING" | "ERROR";
  timestamp: string;
  message: string;
};

// Starts at 600 for custom application error codes
// Following 599 of the http status code
export type TErrorCode =
  | 600 // General application error
  | 604 // General socket connection error
  | 606 // Blacklisting token failed
  | 681 // Scene component loading timeout
  | 682 // Module component loading timeout
  | 682; // Socket connect timeout

type TDebugService = {
  init: () => boolean;

  isEnabled: boolean;
  isPerfEnabled: boolean;

  setDebugEnabled: (checked: boolean) => void;
  setPerfEnabled: (checked: boolean) => void;

  log: Array<TLogEntry>;

  addLog: (type: TLogEntry["type"], message: TLogEntry["message"]) => void;

  logInfo: (message: string) => void;
  logWarn: (message: string) => void;
  logError: (
    message: string,
    options?: {
      errorCode?: TErrorCode;
      displayError?: boolean;
    }
  ) => void;

  getLogAsJson: () => any;
  dumpLogToConsole: () => void;
};

const debugService = create<TDebugService>((set, get) => {
  return {
    isEnabled: false,
    isPerfEnabled: false,

    log: [],

    init: () => {
      set({ isEnabled: localStorage.get("isDebugEnabled") });
      set({ isPerfEnabled: localStorage.get("isDebugPerfEnabled") });

      window.addEventListener(
        "keyup",
        (e) => {
          if (e.key == "F9") {
            get().setDebugEnabled(!get().isEnabled);
          }
        },
        false
      );

      return true;
    },

    setDebugEnabled: (isEnabled) => {
      localStorage.set("isDebugEnabled", isEnabled);
      set({ isEnabled });
    },

    setPerfEnabled: (isPerfEnabled) => {
      localStorage.set("isDebugPerfEnabled", isPerfEnabled);
      set({ isPerfEnabled });
    },

    addLog: (type, message) => {
      const timestamp = new Date().toISOString();

      set((state) => {
        const log = [...state.log, { type, message, timestamp }];

        return { log };
      });
    },

    logInfo: (message) => {
      get().addLog("INFO", message);
    },
    logError: (message, { errorCode = 600, displayError = false } = {}) => {
      if (displayError) {
        popupOverlayService
          .getState()
          .open(
            <ConfirmDialog
              headline={`${errorCode}. That's an error.`}
              subHeadline={message}
              acceptButtonText={"Close"}
              declineButtonText={null}
              onAccept={() => {}}
            />
          );
      }

      get().addLog("ERROR", message);
    },

    logWarn: (message) => {
      get().addLog("WARNING", message);
    },
    getLogAsJson: () => {
      return JSON.stringify(get().log);
    },
    dumpLogToConsole: () => {
      // eslint-disable-next-line no-console
      console.log(get().getLogAsJson());
    },
  };
});

export default debugService;
