import { Matrix4, Object3D, Ray, Vector3 } from "three";

import { Node, Octree } from "./Octree";

export class OctreeRaycaster {
  ray: Ray = new Ray();
  matrixWorld: Matrix4 = new Matrix4();
  inverseMatrix: Matrix4 = new Matrix4();
  rayOctreeCoords: Ray = new Ray();
  intersectionPoint: Vector3 = new Vector3();
  intersectionPointWorld: Vector3 = new Vector3();

  // any is object with { distance, point, face, faceIndex, object }
  ascSort = (a: any, b: any): number => {
    return a.distance - b.distance;
  };

  set = (origin: Vector3, direction: Vector3) => {
    this.ray.set(origin, direction);
  };

  findIntersections = (
    object: Node,
    intersects: Array<any>, // object with { distance, point, face, faceIndex, object }
    recursive: boolean,
    backFaceCulling: boolean
  ) => {
    const bbAccepted = this.raycast(object, intersects, backFaceCulling);

    if (recursive === true && bbAccepted) {
      const children = object.childNodes;

      for (let i = 0, l = children.length; i < l; i++) {
        this.findIntersections(
          children[`${i}`],
          intersects,
          true,
          backFaceCulling
        );
      }
    }
  };

  intersectOctree = (
    octree: Octree,
    recursive: boolean,
    optionalTarget: Array<any> = new Array<any>(),
    backFaceCulling: boolean = true
  ): Array<any> => {
    const intersects = optionalTarget || [];
    this.matrixWorld.copy(octree.matrixWorld);
    this.inverseMatrix = octree.matrixWorld;
    this.inverseMatrix.invert();
    this.rayOctreeCoords.copy(this.ray).applyMatrix4(this.inverseMatrix);
    this.findIntersections(
      octree.rootNode,
      intersects,
      recursive,
      backFaceCulling
    );
    intersects.sort(this.ascSort);
    return intersects;
  };

  intersectOctrees = (
    octrees: Array<Octree>,
    recursive: boolean,
    optionalTarget: Array<Object3D>,
    backFaceCulling: boolean = true
  ): Array<any> => {
    const intersects = optionalTarget || [];
    for (let i = 0, l = octrees.length; i < l; i++) {
      this.matrixWorld.copy(octrees[`${i}`].matrixWorld);
      this.inverseMatrix = octrees[`${i}`].matrixWorld;
      this.inverseMatrix.invert();
      this.rayOctreeCoords.copy(this.ray).applyMatrix4(this.inverseMatrix);
      this.findIntersections(
        octrees[`${i}`].rootNode,
        intersects,
        recursive,
        backFaceCulling
      );
    }
    intersects.sort(this.ascSort);
    return intersects;
  };

  checkIntersection = (
    pA: Vector3,
    pB: Vector3,
    pC: Vector3,
    normal: Vector3,
    point: Vector3,
    backFaceCulling: boolean
  ): Object | null => {
    var intersect;

    intersect = this.ray.intersectTriangle(pA, pB, pC, backFaceCulling, point);

    if (intersect === null) {
      return null;
    }

    this.intersectionPointWorld.copy(point);
    this.intersectionPointWorld.applyMatrix4(this.matrixWorld);

    var distance = this.ray.origin.distanceTo(this.intersectionPointWorld);

    return {
      face: {
        normal: normal,
      },
      distance: distance,
      point: this.intersectionPointWorld.clone(),
    };
  };

  raycast = (
    object: Node,
    intersects: Array<Object>,
    backFaceCulling: boolean
  ): boolean => {
    if (this.rayOctreeCoords.intersectsBox(object.boundingBox) === false) {
      return false;
    }

    for (var f = 0; f < object.vertices.length; f += 3) {
      const va = object.vertices[`${f}`];
      const vb = object.vertices[`${f + 1}`];
      const vc = object.vertices[`${f + 2}`];
      const plane = object.planes[f / 3];
      const intersection = this.checkIntersection(
        va,
        vb,
        vc,
        plane.normal,
        this.intersectionPoint,
        backFaceCulling
      );
      if (intersection) {
        intersects.push(intersection);
      }
    }
    return true;
  };
}
