import { MutableRefObject } from "react";
import { Vector3 } from "three";

import Animator from "@/components/Scene/Avatar/Animator";
import { Configurator } from "@/components/Scene/Avatar/Configurator";
import ReactionController, {
  TReaction,
} from "@/components/Scene/Avatar/ReactionController";

import { getRandomAvatarConfig } from "@/services/AvatarConfigService/customizationScheme";
import {
  networkedPlayerMovementConfig,
  PlayerTransform,
  TAvatarConfig,
  updateTransform,
} from "@/services/PlayerService";
import { Octree } from "@/utils/Collision/Octree";
import { OctreeRaycaster } from "@/utils/Collision/OctreeRaycaster";

export type customBehaviourFunction = (controller: NpcController) => void;

export default class NpcController {
  initialized: boolean;

  transformCache: PlayerTransform;
  transformRef: MutableRefObject<PlayerTransform>;
  isMoving: boolean;

  avatarConfig: TAvatarConfig;
  animator: Animator;
  configurator: Configurator;
  reaction: ReactionController;
  onClick: () => void | null;

  playerTransform: PlayerTransform;
  customBehaviour: customBehaviourFunction | null;

  groundCollisionOctree: Octree;
  groundRaycaster: OctreeRaycaster;

  // TODO: move params into object, set this can be initialized more easily
  constructor(
    groundCollisionOctree: Octree,
    playerTransform: PlayerTransform,
    customBehaviour: customBehaviourFunction | null = null,
    avatarConfig = getRandomAvatarConfig(),
    onClick
  ) {
    this.transformCache = new PlayerTransform();

    this.avatarConfig = avatarConfig;
    this.animator = new Animator();
    this.configurator = new Configurator();
    this.reaction = new ReactionController();
    this.onClick = onClick;

    this.playerTransform = playerTransform;
    this.customBehaviour = customBehaviour;

    this.groundCollisionOctree = groundCollisionOctree;
    this.groundRaycaster = new OctreeRaycaster();

    this.initialized = false;
  }

  init = (transformRef: MutableRefObject<PlayerTransform>) => {
    this.transformRef = transformRef;
    this.transformRef.current = this.transformCache;

    this.initialized = true;
  };

  update = (deltaTime: number): void => {
    if (this.customBehaviour) this.customBehaviour(this);

    this.isMoving = this.transformCache.velocity.lengthSq() > 0.01;
    if (this.isMoving) {
      const forward = this.transformCache.newPosition
        .clone()
        .sub(this.transformCache.currentPosition)
        .setY(0.0)
        .normalize();

      // rotate npc in movement rotation
      this.rotateToDirection(forward);
    }

    // update transform with new position, rotation and velocities
    // this will be consumed by the avatar animation logic later
    // shared logic with networked player
    updateTransform(this.transformCache, deltaTime);

    // place npc on ground
    this.transformCache.currentPosition = this.groundPosition(
      this.transformCache.currentPosition
    );

    // update transform ref
    if (this.initialized) this.transformRef.current = this.transformCache;
  };

  // instant update
  setPosition = (position: Vector3) => {
    position = this.groundPosition(position);

    this.transformCache.newPosition.copy(position);
    this.transformCache.currentPosition.copy(position);

    if (this.initialized) this.transformRef.current = this.transformCache;
  };

  // instant update
  setRotation = (rotation: number) => {
    this.rotateTo(rotation);
    this.transformCache.currentRotation = rotation;

    if (this.initialized) this.transformRef.current = this.transformCache;
  };

  startReaction = (reaction: TReaction): Promise<NpcController> => {
    return new Promise<NpcController>((resolve) => {
      const duration = this.reaction.start(reaction);

      setTimeout(() => {
        resolve(this);
      }, duration);
    });
  };

  // automatic interpolation
  moveTo = (position: Vector3): Promise<NpcController> => {
    return new Promise<NpcController>((resolve) => {
      this.transformCache.newPosition.copy(this.groundPosition(position));

      const distance = this.transformCache.newPosition.distanceTo(
        this.transformCache.currentPosition
      );

      // TMP: This should not be dependent on fixed framerate!
      const approximatedDuration = Math.ceil(
        (distance /
          (60 * networkedPlayerMovementConfig.MAX_TRANSLATE_VELOCITY_FORWARD)) *
          100000
      );

      setTimeout(() => {
        resolve(this);
      }, approximatedDuration);
    });
  };

  // automatic interpolation
  rotateTo = (rotation: number): Promise<NpcController> => {
    return new Promise<NpcController>((resolve) => {
      this.transformCache.newRotation = rotation;

      const difference = Math.abs(
        this.transformCache.currentRotation - this.transformCache.newRotation
      );

      // TMP: This should not be dependent on fixed framerate!
      const approximatedDuration = Math.ceil(
        (difference /
          (60 * networkedPlayerMovementConfig.MAX_ROTATION_VELOCITY)) *
          100000
      );

      setTimeout(() => {
        resolve(this);
      }, approximatedDuration);
    });
  };

  // automatic interpolation
  rotateToDirection = (direction: Vector3): Promise<NpcController> => {
    const rotation = Math.atan2(direction.x, direction.z) + Math.PI;
    return this.rotateTo(rotation);
  };

  rotateToPlayer = (): Promise<NpcController> => {
    const directionToPlayer = this.playerTransform.currentPosition
      .clone()
      .sub(this.transformCache.currentPosition)
      .setY(0.0)
      .normalize();

    return this.rotateToDirection(directionToPlayer);
  };

  // util
  groundPosition = (position: Vector3): Vector3 => {
    this.groundRaycaster.set(
      position.clone().add(new Vector3(0.0, 100.0, 0.0)),
      new Vector3(0, -1, 0)
    );
    const gravityIntersections = this.groundRaycaster.intersectOctree(
      this.groundCollisionOctree,
      true
    );

    if (gravityIntersections.length > 0) {
      position.setY(gravityIntersections[0].point.y + 1.0);
    }

    return position;
  };

  updateAvatarConfig = (avatarConfig) => {
    this.avatarConfig = { ...this.avatarConfig, ...avatarConfig };
    if (this.initialized) this.configurator.update(this.avatarConfig);
  };
}
