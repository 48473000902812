import styled, { css } from "styled-components";

export const StyledFlexAndGrow = styled.div`
  border-radius: 0rem 0rem var(--mainNav-overview-panel-border-radius)
    var(--mainNav-overview-panel-border-radius);
  display: flex;
  height: calc(100vh - calc(var(--mainNav-margin) * 2) - 7rem);
  flex-direction: column;
  overflow: hidden;
`;

export const FlexAndGrowHeader = styled.div``;

export const FlexAndGrowBody = styled.div`
  flex-grow: 1;
  max-height: 100%;
  overflow: hidden;
  position: relative;
`;

const linearGradientBackground = (angle) => css`
  background: linear-gradient(
    ${angle}deg,
    rgba(255, 255, 255, 0) 0%,
    white 100%
  );
`;

export const Gradient = styled.span`
  pointer-events: none;
  display: inline-block;
  width: 100%;
  position: absolute;
  transition: opacity 0.2s linear;
  opacity: 0;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
    `};

  // Top gradient
  &:first-child {
    height: var(--gradient-top-height);
    top: 0;
    transform-origin: center top;
    ${linearGradientBackground(0)}
  }

  // Bottom gradient
  &:last-child {
    height: var(--gradient-bottom-height);
    bottom: 0;
    transform-origin: center bottom;
    ${linearGradientBackground(180)}
  }
`;

export const BodyContent = styled.div`
  max-height: 100%;
  padding: var(--flex-grow-body-padding);
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const FlexAndGrowFooter = styled.div`
  position: relative;
`;
