import { Euler, Matrix4, Quaternion, Vector3 } from "three";
import { DEG2RAD } from "three/src/math/MathUtils";

export const clamp = (value: number, lowerBound: number, upperBound: number) =>
  Math.min(Math.max(value, lowerBound), upperBound);

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// PX to REM converter
export const pxToRem = (px: number, base = 10): string => {
  return (1 / base) * px + "rem";
};

// REM to PX converter
export const remToPx = (rem: number, base = 10): number => {
  return rem * base;
};

// Seed generating function from string
// From https://github.com/bryc/code/blob/master/jshash/PRNGs.md#addendum-a-seed-generating-functions
export const xmur3 = (str) => {
  for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
    h = (h << 13) | (h >>> 19);
  }
  return function () {
    h = Math.imul(h ^ (h >>> 16), 2246822507);
    h = Math.imul(h ^ (h >>> 13), 3266489909);
    return (h ^= h >>> 16) >>> 0;
  };
};

export const getEulerXRotation = (quaternion) => {
  const rotationMatrix = new Matrix4();
  rotationMatrix.makeRotationFromQuaternion(quaternion);

  const xAxis = new Vector3();
  const yAxis = new Vector3();
  const zAxis = new Vector3();
  rotationMatrix.extractBasis(xAxis, yAxis, zAxis);

  const angle = Math.atan2(zAxis.y, zAxis.z);
  return Math.PI - angle;
};

export const getEulerYRotation = (quaternion) => {
  const rotationMatrix = new Matrix4();
  rotationMatrix.makeRotationFromQuaternion(quaternion);

  const xAxis = new Vector3();
  const yAxis = new Vector3();
  const zAxis = new Vector3();
  rotationMatrix.extractBasis(xAxis, yAxis, zAxis);

  const angle = Math.atan2(xAxis.z, xAxis.x);
  return Math.PI - angle;
};

export const getEulerZRotation = (quaternion) => {
  const rotationMatrix = new Matrix4();
  rotationMatrix.makeRotationFromQuaternion(quaternion);

  const xAxis = new Vector3();
  const yAxis = new Vector3();
  const zAxis = new Vector3();
  rotationMatrix.extractBasis(xAxis, yAxis, zAxis);

  const angle = Math.atan2(yAxis.x, yAxis.y);
  return Math.PI - angle;
};

export const ExtractAxisQuaternion = (
  originalRotation: Quaternion,
  xAxis: boolean,
  yAxis: boolean,
  zAxis: boolean
) => {
  return new Quaternion().setFromEuler(
    new Euler(
      xAxis ? getEulerXRotation(originalRotation) : 0,
      yAxis ? getEulerYRotation(originalRotation) : 0,
      zAxis ? getEulerZRotation(originalRotation) : 0
    )
  );
};

export const GetTiltQuaternion = (xTilt, yTilt, zTilt) => {
  return new Quaternion().setFromEuler(
    new Euler(DEG2RAD * xTilt, DEG2RAD * yTilt, DEG2RAD * zTilt)
  );
};

// Generate random number based on seed
// From https://stackoverflow.com/a/47593316
export const mulberry32 = (a) => {
  let t = (a += 0x6d2b79f5);
  t = Math.imul(t ^ (t >>> 15), t | 1);
  t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
  return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
};

/**
 * Duplicates array items
 */
export const duplicateArrayItems = (arr: any, numberOfRepetitions: number) =>
  arr.flatMap((i) => Array.from({ length: numberOfRepetitions }).fill(i));
