import { FC, HTMLAttributes } from "react";
import { css, CSSObject, CSSProp } from "styled-components";

import { StyledButton, StyledButtonIcon } from "./styles";

type TButton = HTMLAttributes<HTMLButtonElement>;
export interface IProps {
  big?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  extraStyle?: CSSProp | CSSObject;
  fullWidth?: boolean;
  outline?: boolean;
  text: string | React.ReactNode;
  type?: string;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button: FC<IProps & TButton> = ({
  big = false,
  children,
  disabled = false,
  fullWidth = false,
  extraStyle = css``,
  outline = false,
  icon,
  text,
  type = "submit",
  handleClick,
  ...rest
}) => {
  return (
    <StyledButton
      big={big}
      disabled={disabled}
      extraStyle={extraStyle}
      fullWidth={fullWidth}
      outline={outline}
      type={type}
      onClick={handleClick}
      {...rest}
    >
      {icon && <StyledButtonIcon> {icon}</StyledButtonIcon>} {children || text}
    </StyledButton>
  );
};

export default Button;
