import { TAvatarConfig } from "@/services/PlayerService";

export const customizationScheme = {
  outfit: [
    "Outfit_1_Casual",
    "Outfit_1_Formal",
    "Outfit_2_Casual",
    "Outfit_2_Formal",
  ],
  outfitStyle: [
    "Outfit_1_CasualGreenStripes",
    "Outfit_1_CasualOrange",
    "Outfit_1_CasualPink",
    "Outfit_1_CasualViolette",
    "Outfit_1_CasualWhite",
    "Outfit_1_FormalBeige",
    "Outfit_1_FormalBlue",
    "Outfit_1_FormalDarkGrey",
    "Outfit_1_FormalViolette",
    "Outfit_2_CasualBrown",
    "Outfit_2_CasualDots",
    "Outfit_2_CasualFlower",
    "Outfit_2_CasualStripes",
    "Outfit_2_FormalDarkBlue",
    "Outfit_2_FormalLightBlue",
    "Outfit_2_FormalRed",
    "Outfit_2_FormalWhite",
  ],
  extras: [
    "Extra_0_Earring",
    "Extra_0_Glasses_Meme",
    "Extra_0_Glasses_Round",
    "Extra_0_Glasses_Pointy",
    "Extra_0_Watch",
    "Extra_1_Pocket",
    "Extra_1_Scarf",
    "Extra_1_Tie",
    "Extra_2_Necklace_Thick",
    "Extra_2_Necklace_Thin",
  ],
  hairCut: [
    "Hair_0_Afro",
    "Hair_0_Beard",
    "Hair_0_Curly",
    "Hair_0_Dutt",
    "Hair_0_Hat_Fabric",
    "Hair_0_Hat_Fabric_Hair",
    "Hair_0_Long",
    "Hair_0_LongWavy",
    "Hair_0_ManBun",
    "Hair_0_PonyTail",
    "Hair_0_ShortMale",
    "Hair_0_Side",
    "Hair_0_Smalltail",
    "Hair_0_Spiky",
  ],
  hairColor: [
    "Black",
    "White",
    "BrownDark",
    "BrownRed",
    "Brown",
    "RoseLight",
    "Orange",
    "Tangerine",
  ],
  skinColor: ["Dark", "Brown", "Tan", "RedLight", "BrownLight", "YellowLight"],
  skin: ["Skin_1_Formal", "Skin_1_Casual", "Skin_2_Formal", "Skin_2_Casual"],
};

export const skinColors = {
  Dark: "#49361A",
  Brown: "#A18067",
  Tan: "#CA947B",
  RedLight: "#F7C8C4",
  BrownLight: "#E5CBBF",
  YellowLight: "#FBE8CC",
};

export const hairColors = {
  Black: "#000000",
  White: "#FFFFFF",
  BrownDark: "#472B24",
  BrownRed: "#773829",
  Brown: "#A18067",
  RoseLight: "#E27588",
  Orange: "#D04A02",
  Tangerine: "#EB8C00",
};

export const getRandomAvatarConfig = () => {
  const outfit = rand(customizationScheme.outfit);

  const outfitParts: Array<string> = outfit.split("_");
  const outfitSid: string = outfitParts[1];
  const outfitName: string = outfitParts[2];

  const outfitStyle = rand(getOutfitStyles(outfitName, outfitSid));
  const skin = getSkin(outfitName, outfitSid);

  let hasGlasses: boolean = false;
  let hasNecklace: boolean = false;

  const extras = getAccessories(outfitSid).filter((extra) => {
    if (Math.random() > 0.5) return false;

    const extraName: string = extra.split("_")[2];

    if (extraName === "Necklace" && hasNecklace) return false;
    else hasNecklace = true;

    if (extraName === "Glasses" && hasGlasses) return false;
    else hasGlasses = true;

    return true;
  });

  const randomConfig: TAvatarConfig = {
    extras,
    hairColor: rand(customizationScheme.hairColor),
    hairCut: rand(customizationScheme.hairCut),
    outfit,
    outfitStyle,
    skinColor: rand(customizationScheme.skinColor),
    skin,
  };
  return randomConfig;
};

export const rand = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

// returns desired outfit styles based on style and personality
export const getOutfitStyles = (outfitName: string, outfitSid: string) => {
  const outfitType: string = `${outfitSid}_${outfitName}`;
  return customizationScheme.outfitStyle.filter((style) =>
    style.includes(outfitType)
  );
};

// returns desired extras/accessories based on personality
export const getAccessories = (outfitSid: string) => {
  return customizationScheme.extras.filter(
    (extra) => extra.includes("0") || extra.includes(outfitSid)
  );
};

export const getSkin = (outfitName: string, outfitSid: string) => {
  const skinName = `Skin_${outfitSid}_${outfitName}`;
  return customizationScheme.skin.find((skin) => skin === skinName) as string;
};
