const validateDate = (date) => (date instanceof Date ? date : new Date(date));

export const formatTime = (date: Date | string): string => {
  if (!date) return "";

  const _date = validateDate(date);
  if (!_date) return "";

  return `${_date.getHours()}:${_date
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
};

export const formatDate = (date: Date, separator = "."): string | null => {
  if (!date) return null;

  const _date = validateDate(date);
  if (!_date) return null;

  return `${_date.getDate().toString().padStart(2, "0")}${separator}${(_date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}${separator}${_date.getFullYear()}`;
};

export function formatChatBubbleTimestamp(timestamp) {
  const prependZero = (time) => (time < 10 ? `0${time}` : time);

  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${prependZero(hours)}:${prependZero(minutes)}`;
}

export function formatGroupedMessageDate(date) {
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const yesterday = new Date(
    new Date().setHours(0, 0, 0, 0).valueOf() - 1000 * 60 * 60 * 24
  );
  const groupDate = new Date(new Date(date).setHours(0, 0, 0, 0));

  if (groupDate.getTime() === today.getTime()) return "Today";
  if (groupDate.getTime() === yesterday.getTime()) return "Yesterday";

  const weekday = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
  }).format(groupDate);
  const day = groupDate.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    groupDate
  );
  return `${weekday}, ${day} ${month}`;
}

export const isLongerThanTwoWeeks = (start, end) => {
  // right part converts 14 days into milliseconds
  return (end.getTime() - start.getTime()) > 14 * (1000 * 60 * 60 * 24);
};