import styled from "styled-components";

import { TRANSITION } from "@/components/Dom/Notifications/DefaultNotification/styles";
import { StyledNotificationCenter } from "@/components/Dom/Notifications/NotificationCenter/styles";

import { colors } from "@/styles/colors";
import { TruncateText } from "@/styles/mixins";
import { Hover } from "@/styles/mixins";
import { MidHeadline } from "@/styles/typography";

export const StyledNewChatMessageNotification = styled.div`
  position: relative;
  padding: 1.4rem 2rem;
  border-radius: var(--bubble-radius) var(--bubble-radius) 0
    var(--bubble-radius);
  width: 22rem;
  background-color: rgba(${colors.white.rgba}, 0.6);
  cursor: pointer;
  transition: background-color ${TRANSITION};

  display: grid;
  grid-template-areas:
    "headline headline headline headline time"
    "text text text text text";

  ${Hover(`background-color: rgba(${colors.white.rgba}, 1);`)};
`;

export const Headline = styled(MidHeadline)`
  grid-area: headline;
  margin-bottom: 0.4rem;

  transition: color ${TRANSITION};

  ${Hover(`color: ${colors.grey.light.hex}`, StyledNotificationCenter)}
`;

export const Time = styled(MidHeadline)`
  grid-area: time;
  text-align: right;
  color: ${colors.grey.light.hex};
  opacity: 0;
  transition: opacity ${TRANSITION};

  ${Hover(`opacity: 1;`, StyledNotificationCenter)}
`;

export const TextWrapper = styled.div`
  grid-area: text;
  position: relative;
  width: 100%;
  word-break: break-word;
  ${TruncateText({ maxLines: 2 })};
`;
