import { Camera } from "three";

import Animator from "@/components/Scene/Avatar/Animator";
import ParticleSystem from "@/components/Scene/Common/ParticleSystem";

import rehService from "@/services/RehService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

export type TReaction = "WAVING" | "DANCE" | "IDEA" | "THUMBS";
const mapIndex = {
  THUMBS: 0,
  WAVING: 1,
  DANCE: 2,
  IDEA: 3,
};

export default class ReactionController {
  userId: TUser["id"];
  isSelf: boolean;

  animator: Animator;
  particleSystem: ParticleSystem;

  currentReaction: TReaction | null;
  activeTimeoutId: any | null;

  constructor() { }

  init(animator: Animator, userId: TUser["id"]) {
    this.currentReaction = null;

    this.userId = userId;
    this.isSelf = userService.getState().isSelf(this.userId);

    this.animator = animator;
    this.particleSystem = new ParticleSystem("iconsAtlas");

    if (!this.isSelf)
      rehService
        .getState()
        .subscribe(
          "module",
          "SERVER_CLIENT_REACTION",
          ({ reaction, userId }) => {
            if (this.userId !== userId) return;

            this.start(reaction);
          }
        );
  }

  start(reaction: TReaction): number {
    if (this.currentReaction === reaction) return 0;

    this.stop();
    this.currentReaction = reaction;

    if (this.isSelf) {
      rehService.getState().sendMessage("module", "CLIENT_REACTION", {
        reaction,
        userId: this.userId,
      });
    }

    const duration = this.animator.playReaction(reaction);

    const isDance = reaction === "DANCE";

    this.particleSystem.directionType = isDance ? "RADIAL" : "UP";
    this.particleSystem.speed = isDance ? 1.0 : 0.65;
    this.particleSystem.distance = isDance ? 0.75 : 1.5;

    this.particleSystem.start(duration, mapIndex[reaction]);

    this.activeTimeoutId = setTimeout(() => {
      this.stop();
    }, duration);

    return duration;
  }

  stop() {
    this.particleSystem.stop();

    if (this.activeTimeoutId) {
      clearTimeout(this.activeTimeoutId);
      this.activeTimeoutId = null;
    }

    this.currentReaction = null;
  }

  update(deltaTime: number, camera: Camera) {
    this.particleSystem.update(deltaTime, camera);
  }
}
