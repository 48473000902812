import create from "zustand";

import cmsService from "@/services/CmsService";
import debugService from "@/services/DebugService";
import { TModuleBlueprint } from "@/services/ModuleService/types";
import { TSpace } from "@/services/SpaceService/types";

type TModuleBlueprintService = {
  moduleBlueprints: TModuleBlueprint[];

  init: (spaceId: TSpace["id"]) => Promise<void>;
};

const ModuleBlueprintService = create<TModuleBlueprintService>((set, get) => {
  return {
    moduleBlueprints: [],

    init: async (spaceId) => {
      try {
        const moduleBlueprints = await cmsService
          .getState()
          .requestData(`/api/host-panel/getBlueprints?spaceId=${spaceId}`);

        set({ moduleBlueprints });
      } catch (e) {
        debugService
          .getState()
          .addLog("ERROR", `ModuleBlueprintService::init ${e}`);
      }
    },
  };
});

export default ModuleBlueprintService;
