import { Vector3 } from "three";
import create from "zustand";

import {
  JoinRoomError,
  TJoinOptions,
  TModule,
  TModulesResponse,
} from "./types";

import cmsService from "@/services/CmsService";
import debugService from "@/services/DebugService";
import discoverQuestService from "@/services/DiscoverQuestService";
import navService from "@/services/NavService";
import { playerService, PlayerTransform } from "@/services/PlayerService";
import rehService from "@/services/RehService";
import sceneService, {
  TMediaAreaData,
  TTriggerAreaData,
} from "@/services/SceneService";
import spaceService from "@/services/SpaceService";
import { TSpace } from "@/services/SpaceService/types";
import userService from "@/services/UserService";
import videoService from "@/services/VideoService";
import vrService from "@/services/VrService";
import { isModuleValid } from "@/utils/Module";
import { ONBOARDING_MODULE_SLUG } from "@/components/Pages/Setup/Avatar";

type TModuleService = {
  init: () => Promise<Array<TModule>>;

  modules: Array<TModule>;

  updateModule: (moduleId: TModule["id"], data: Partial<TModule>) => void;
  removeModule: (moduleId: TModule["id"]) => void;
  updateModules: () => Promise<Array<TModule>>;

  getModuleById: (moduleId: TModule["id"]) => TModule | null;
  getModuleBySlug: (slug: TModule["slug"]) => TModule | null;
  getModulesBySpaceId: (spaceId: TSpace["id"]) => Array<TModule>;

  currentModuleId: TModule["id"] | null;
  getCurrentModule: () => TModule | null;
  isCurrentModuleLocal: () => boolean | null;
  setCurrentModule: (moduleId: number | null) => TModule | null;
  getRoomUrlFromCurrentModule: () => string | null;

  canAccessModule: (moduleId: TModule["id"]) => boolean;
  currentModuleSupportsVr: () => boolean;

  joinModule: (options: TJoinOptions) => Promise<boolean>;
  leaveModule: () => void;

  updateMediaArea: (
    moduleId: TModule["id"],
    mediaAreaId: TMediaAreaData["id"],
    data: TMediaAreaData
  ) => void;
  updateTriggerAreas: (
    moduleId: TModule["id"],
    triggerAreas: TTriggerAreaData[]
  ) => void;
};

const moduleService = create<TModuleService>((set, get) => {
  const isModulePathValid = (
    slugInfo: TJoinOptions["slugInfo"]
  ): TModule["id"] | null => {
    const { logError } = debugService.getState();

    if (!slugInfo) {
      logError("moduleService::isValidModulePath(): Invalid input parameter!");
      return null;
    }

    const { moduleSlug, spaceSlug } = slugInfo;

    if (!moduleSlug || !spaceSlug) {
      logError("moduleService::isValidModulePath(): Invalid input parameter!");
      return null;
    }

    const module = get().getModuleBySlug(moduleSlug);
    if (!module) {
      logError("moduleService::isValidModulePath(): No module found!");
      return null;
    }

    if (module.local) return module.id;

    const space = spaceService.getState().getSpaceBySlug(spaceSlug);
    if (!space) {
      logError("moduleService::isValidModulePath(): No space found!");
      return null;
    }

    if (!space.modules.includes(module.id)) {
      logError(
        "moduleService::isValidModulePath(): Module is not part of space!"
      );
      return null;
    }

    return module.id;
  };

  return {
    modules: [],
    currentModuleId: null,

    init: () => {
      return get().updateModules();
    },

    updateModule: (moduleId, data) => {
      if (!moduleId || !data) return;
      set((state) => {
        const modules = state.modules.map((module) => {
          if (module.id === moduleId) return { ...module, ...data };
          return module;
        });

        return { modules };
      });
    },

    removeModule: (moduleId) => {
      if (!moduleId) return;

      set((state) => {
        const modules = state.modules.filter(
          (module) => module.id !== moduleId
        );

        return { modules };
      });
    },

    updateModules: () => {
      return new Promise<Array<TModule>>(async (resolve, reject) => {
        try {
          const modules = await cmsService.getState().requestData("getModules");

          if (!modules) {
            reject(TModulesResponse.NO_MODULES_FOUND);
            return;
          }

          set({ modules });

          resolve(modules);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },

    getModuleById: (moduleId) => {
      if (!moduleId) return null;

      const module = get().modules.find((module) => module.id === moduleId);
      return module ? module : null;
    },

    getCurrentModule: () => {
      // @ts-expect-error
      return get().getModuleById(get().currentModuleId);
    },

    isCurrentModuleLocal: () => {
      const currentModule = get().getCurrentModule();
      if (!currentModule) return null;

      return currentModule.local;
    },

    setCurrentModule: (currentModuleId) => {
      set({ currentModuleId });
      // persist in db since this info relates to player-data
      playerService.getState().setCurrentModule(currentModuleId);

      if (!currentModuleId) return null;

      return get().getCurrentModule();
    },

    getModulesBySpaceId: (spaceId) => {
      if (!spaceId) return [];

      const modules = get().modules.filter(
        (module) => module.space === spaceId
      );

      return modules ? modules : [];
    },

    getModuleBySlug: (slug) => {
      const module = get().modules.find((module) => {

        // If you stumble upon this, you are probably wondering,
        // why on earth this is here. Let me explain:
        // There is a special onboarding module, which is not part of a space:
        // it is a free floating module, that gets shown when a user first
        // enters the platform. The user can run around and there is an avatar, etc.
        // This special onboarding module, was supposed to always be called
        // ONBOARDING_MODULE_SLUG, but there is a logical problem: The slug of a
        // module is auto generated on saving a module, and consists of the
        // module's title + the module's id. As we don't know the id of the module
        // before creation, this puts us in a bad situation. The previous devs'
        // solution was to go into the database and "fix" it there. This is a
        // terrible idea imo, which is why I am introducing this exception here.
        // Now this was a long comment, I hope it brought some light to your
        // quest to understanding the matter. Have a wonderful day and good luck!
        // 🥔
        if(slug === ONBOARDING_MODULE_SLUG) {
          return module.slug?.startsWith(ONBOARDING_MODULE_SLUG);
        }

        return module.slug === slug
      });
      return module || null;
    },

    getRoomUrlFromCurrentModule: () => {
      // ToDo: Test this!
      return get().getCurrentModule()?.roomUrls?.[0]?.roomUrl || null;
    },

    canAccessModule: (moduleId) =>
      Boolean(get().getModuleById(moduleId)?.canAccess),

    currentModuleSupportsVr: () => {
      const currentModule = get().getCurrentModule();
      if (
        !currentModule ||
        currentModule.supportsVr === undefined ||
        currentModule.supportsVr === null
      )
        return false;

      return currentModule.supportsVr;
    },

    joinModule: (options = {}) => {
      return new Promise(async (resolve, reject) => {
        // eslint-disable-next-line prefer-const
        let { slugInfo = null, moduleId = null } = options;

        const local = slugInfo?.spaceSlug === "local";

        if (slugInfo) moduleId = isModulePathValid(slugInfo);
        if (!moduleId || !isModuleValid(moduleId)) {
          reject(JoinRoomError.INVALID_MODULE);
          return;
        }

        const module = get().setCurrentModule(moduleId);
        if (!module) {
          reject(JoinRoomError.INVALID_MODULE);
          return;
        }

        const loadScenePromise = sceneService.getState().loadScene(module);

        playerService.getState().start();

        const startTime = Date.now();

        const { getStartTransform } = sceneService.getState();

        const spawnTransform = getStartTransform(
          module.blueprint.assetMetadata.startSpawn
        );

        if (!local) {
          try {
            // Wait for both the roomUrl and the location loading
            const [roomUrl] = await Promise.all([
              /*roomUrlPromise,*/ loadScenePromise,
            ]);

            // There shouldn't be a code path leading to this but better make sure!
            if (!roomUrl) {
              reject("No room Url to connect to");
              return;
            }

            // if (!getRoomUrlFromModule(module)) {
            //   module.roomUrls.push({
            //     id: 0,
            //     __component: "room-url-entry.room-url",
            //     roomUrl,
            //   });
            // }

            //roomUrl, startTransform

            if (
              vrService.getState().currentDeviceSupportsVr() &&
              module.supportsVr
            ) {
              vrService.setState({ vrPlaySpaceTransform: spawnTransform });
            }

            await rehService.getState().connect("module");
          } catch (error) {
            reject(error);
            return;
          }
        } else {
          const { ownUser } = userService.getState();
          if (!ownUser) {
            reject(
              `ModuleService::joinModule(): Failed to join offline module = own user not found!`
            );
            return;
          }

          const { allPlayers } = playerService.getState();

          const transform = new PlayerTransform(
            new Vector3(
              spawnTransform.position.x,
              spawnTransform.position.y,
              spawnTransform.position.z
            ),
            spawnTransform.rotation
          );

          allPlayers.set(ownUser.id, transform);

          await loadScenePromise;
        }

        sceneService.getState().setInitialized(true);
        videoService.getState().startSpaceShareOnModuleJoin();
        navService.getState().closeAllPanels();

        resolve(true);

        // eslint-disable-next-line no-console
        console.log(
          `joined module in ${(Date.now() - startTime) * 0.001} seconds`
        );

        return;
      });
    },
    leaveModule: () => {
      playerService.getState().stop();
      rehService.getState().disconnect("module");
      sceneService.getState().unloadScene();
      set({ currentModuleId: null });

      discoverQuestService.getState().stopQuest();

      if (videoService.getState().isInStaticCall()) {
        videoService.getState().onRoomLeft();
      }
    },

    updateMediaArea: (moduleId, mediaAreaId, data) => {
      if (!moduleId || !mediaAreaId || !data) return;
      const { modules } = get();

      const module = modules.find((module) => module.id === moduleId);

      if (!module) return;

      //Find media area to update
      const updatedModules = modules.map((module) => {
        if (module.id === moduleId) {
          module.mediaAreas = module.mediaAreas?.map((mediaArea) => {
            if (mediaArea.id === mediaAreaId)
              mediaArea = { ...mediaArea, ...data };

            return mediaArea;
          });
        }

        return module;
      });

      set({ modules: updatedModules });
    },
    updateTriggerAreas: (moduleId, triggerAreas) => {
      if (!moduleId || !triggerAreas) return;
      const { modules } = get();

      const module = modules.find((module) => module.id === moduleId);

      if (!module) return;

      //Find trigger area to update
      const updatedModules = modules.map((module) => {
        if (module.id === moduleId) module.triggerAreas = triggerAreas;
        return module;
      });

      set({ modules: updatedModules });
    },
  };
});

export default moduleService;
