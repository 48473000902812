import dynamic from "next/dynamic";
import * as React from "react";
import { useEffect } from "react";
import { Vector3 } from "three";

import Logo from "@/components/Pages/Setup/Common/Logo";
import NpcController from "@/components/Scene/Npc/NpcController";

import assetService from "@/services/AssetService";
import avatarConfigService from "@/services/AvatarConfigService";
import debugService from "@/services/DebugService";
import initService from "@/services/InitService";
import inputService from "@/services/InputService";
import loadingScreenService from "@/services/LoadingScreenService";
import moduleService from "@/services/ModuleService";
import { TJoinOptions } from "@/services/ModuleService/types";
import npcService from "@/services/NpcService";
import onboardingService, {
  TOnboardingStep,
} from "@/services/OnboardingService";
import { playerService, TAvatarConfig } from "@/services/PlayerService";
import sceneService from "@/services/SceneService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import { createRandomUser } from "@/utils/User";

import { AvatarWrapper, StyledButton, StyledFlexAndGrow } from "./styles";
import { getDefaultModuleId } from "@/utils/Space";

export const ONBOARDING_MODULE_SLUG: string = "localtutorial";

const AvatarConfigurator = dynamic(
  () => import("@/components/Dom/AvatarConfigurator"),
  {
    ssr: false,
  }
);

export const eveNpcUser: TUser = createRandomUser({
  email: "virtual.spaces@pwc.com",
  firstname: "Eve",
  lastname: "", // @ts-expect-error
  profileImage: {
    width: 280,
    height: 155,
    url: "/assets/images/Tutorial-Image-1.jpg",
  }, // @ts-expect-error
  userData: {
    id: 0,
    position: "Assistant",
    department: "Metaverse",
    company: "PwC",
    userId: "0",
  },
  badge: 666,
});

const createOnboardingNpc = () => {
  // rotate towards player if close to npc behaviour
  const customBehaviour = (controller: NpcController) => {
    const { isMoving, transformCache, playerTransform } = controller;

    if (!isMoving) {
      const distanceToPlayer = transformCache.currentPosition.distanceToSquared(
        playerTransform.currentPosition
      );

      if (distanceToPlayer < 24.0) controller.rotateToPlayer();
    }
  };

  const createEveNpcConfig = (): TAvatarConfig => {
    return {
      skin: "Skin_2_Formal",
      extras: ["Extra_0_Earring", "Extra_0_Watch", "Extra_2_Necklace_Thin"],
      outfit: "Outfit_2_Formal",
      hairCut: "Hair_0_Afro",
      hairColor: "Black",
      skinColor: "Dark",
      outfitStyle: "Outfit_2_FormalWhite",
    };
  };

  // create npc with custom behaviour to rotate towards player if he is close
  const { controller } = npcService.getState().add({
    user: eveNpcUser,
    avatarConfig: createEveNpcConfig(),
    customBehaviour,
    onClick: () => {
      if (onboardingService.getState().currentStep !== TOnboardingStep.CALL)
        return;

      onboardingService.setState({ showProfile: true });
    },
  });

  // setup npc start position in front of player
  const ownUser = userService.getState().ownUser;
  if (!ownUser) return;

  const playerTransform = playerService.getState().allPlayers.get(ownUser.id);
  if (!playerTransform) return;

  const npcStartPosition = playerTransform.currentPosition
    .clone()
    .add(new Vector3(-2, 0.0, -2));

  // set avatar start position and rotation
  controller.setPosition(npcStartPosition);
  controller.rotateToPlayer();

  // npc state machine based on onboarding step
  const updateAction = (currentStep, previousStep) => {
    // cache notification area positions
    const notificationAreaPositions = new Array<Vector3>();
    sceneService.getState().triggerAreas.forEach((triggerArea) => {
      const notificationAreaId = parseInt(triggerArea.userData.triggerAreaName);

      if (isNaN(notificationAreaId)) return;
      notificationAreaPositions[notificationAreaId] = triggerArea.position;
    });
    const targetPosition = notificationAreaPositions[currentStep - 2];

    switch (currentStep) {
      case TOnboardingStep.GREET: {
        inputService.getState().enableInput();

        setTimeout(() => {
          controller.startReaction("WAVING");
        }, 2000);
        break;
      }
      case TOnboardingStep.MOVE: {
        controller
          .moveTo(notificationAreaPositions[currentStep - 1])
          .then((c) =>
            c.rotateToPlayer().then((c) => c.startReaction("WAVING"))
          );
        break;
      }
      case TOnboardingStep.CALL: {
        controller
          .moveTo(targetPosition)
          .then((c) =>
            c.rotateToPlayer().then((c) => c.startReaction("WAVING"))
          );
        break;
      }
      case TOnboardingStep.MEDIA: {
        controller
          .startReaction("THUMBS")
          .then((c) => c.moveTo(targetPosition))
          .then((c) =>
            c.rotateToPlayer().then((c) => c.startReaction("WAVING"))
          );
        break;
      }
      case TOnboardingStep.CELEBRATE: {
        controller
          .moveTo(targetPosition)
          .then((c) =>
            c.rotateToPlayer().then((c) => c.startReaction("DANCE"))
          );
        break;
      }
    }
  };

  onboardingService.subscribe(updateAction, (state) => state.currentStep);
};

const Avatar = () => {
  const isInitialized = initService((state) => state.isInitialized);
  const isAssetsInitialized = assetService((state) => state.isInitialized);
  const isSceneInitialized = sceneService((state) => state.isSceneInitialized);

  // init => lock input and set start onboarding step => load module
  useEffect(() => {
    if (!isInitialized || !isAssetsInitialized) return;

    inputService.getState().lockInput();
    onboardingService.getState().setStep(TOnboardingStep.CONFIGURE);

    const init = async () => {
      try {
        const joinOptions: TJoinOptions = {
          slugInfo: {
            moduleSlug: ONBOARDING_MODULE_SLUG,
            spaceSlug: "local",
          },
        };

        await moduleService.getState().joinModule(joinOptions);

        const ownUser: TUser | null = userService.getState().getOwnUser();
        if (!ownUser) return;

        await avatarConfigService.getState().loadConfig(ownUser.id);


        // If a user only has one module, we want to make sure that the portal at the end of the 
        // onboarding module always leads to that default module. The default would be sending
        // users back to the root / spaces map, which might be confusing for first-timers.
        // We achieve this, by finding the default module id, finding the portal in the current
        // (onboarding) module and overriding its destinations to include the default module id.
        // This is not a great solution, but the default system of trigger areas / portals is to
        // set the destination in the CMS, and the user's default module is not a CMS setting but
        // a dynamically detected thing. So… this is not nice, but it works.
        // If you find a better solution around this onboarding tutorial, I would love to learn!
        const defaultModuleId = getDefaultModuleId();
        if(defaultModuleId) {

          const currentModule = moduleService.getState().getCurrentModule();
          if(currentModule) {

            const updatedTriggerAreas = currentModule.triggerAreas?.map(area => {
              if(area.type == 'portal') {
                area.destinations.unshift(defaultModuleId);
                
                console.info('Updated portal destinations', area.destinations, currentModule);
              }
              return area;
            });

            moduleService.getState().updateTriggerAreas(currentModule.id, updatedTriggerAreas)
          }

        }


        console.log('moduleService..getCurrentModule', moduleService.getState().getCurrentModule());
      } catch (error) {
        debugService
          .getState()
          .logError(`Setup - Avatar::init(): Failed to load module = ${error}`);
      }
    };
    init();

    return () => {
      moduleService.getState().leaveModule();
    };
  }, [isInitialized, isAssetsInitialized]);

  // app is initialized and scene loaded => load custom npc and reveal page
  useEffect(() => {
    if (!isSceneInitialized) return;

    createOnboardingNpc();

    // delay to wait for scene graph being ready
    setTimeout(() => {
      loadingScreenService.getState().pageReady();

      playerService.getState().reactionController?.start("WAVING");
    }, 2000);
  }, [isSceneInitialized]);

  const handleConfigurationDone = () => {
    playerService.getState().reactionController?.start("THUMBS");
    onboardingService.getState().setStep(TOnboardingStep.TRANSITION);
  };

  if (!isInitialized) return null;

  return (
    <>
      <StyledFlexAndGrow
        body={
          <AvatarWrapper>
            <Logo />
            <AvatarConfigurator
              title={`Welcome ${
                userService.getState().ownUser?.firstname
              }, it's time to express your virtual self`}
              subtitle="Make a start with quick settings"
            />
          </AvatarWrapper>
        }
        footer={
          <AvatarWrapper>
            <StyledButton
              big
              text="Save and continue"
              handleClick={handleConfigurationDone}
              fullWidth
            />
          </AvatarWrapper>
        }
      />
    </>
  );
};

export async function getStaticProps() {
  return {
    props: { pageData: {} },
  };
}

Avatar.enableScene = true;

export default Avatar;
