import create from "zustand";

import cmsService from "../CmsService";
import debugService from "../DebugService";
import moduleService from "../ModuleService";
import { TModule } from "../ModuleService/types";
import userService from "../UserService";
import { TUser } from "../UserService/types";
import {
  TGroupPayload,
  THostPanelGroup,
  THostPanelUser,
  TTokenPayload,
  TTokenUser,
  TUserPayload,
} from "./types";

import moduleBlueprintService from "@/services/ModuleBlueprintService";
import { TMediaAreaData, TTriggerAreaData } from "@/services/SceneService";
import spaceService from "@/services/SpaceService";
import { TSpace } from "@/services/SpaceService/types";
import { parseHostPanelUser } from "@/utils/User";

export enum EHostTab {
  OVERVIEW = "Overview",
  MODULE = "Module",
  ADD_MODULE = "AddModule",
  MEDIA_AREAS = "MediaAreas",
  TRIGGER_AREAS = "Trigger Areas",
  ACCESS_MANAGEMENT = "AccessManagement",
  TOKEN_GENERATOR = "TokenGenerator"
}

export enum HOST_ENDPOINTS {
  SPACE_UPDATE = `/api/host-panel/updateSpace`,
  UPLOAD_MEDIA_FILE = `/api/host-panel/uploadMediaFile`,
  UPDATE_MEDIA_AREA = `/api/host-panel/updateMediaArea`,
  UPDATE_TRIGGER_AREA = `/api/host-panel/updateTriggerAreas`,
  MODULE_CREATE = `/api/host-panel/createModule`,
  MODULE_UPDATE = `api/host-panel/updateModule`,
  MODULE_DELETE = `api/host-panel/deleteModule`,
  GROUP_GET = `/api/host-panel/getGroups`,
  GROUP_CREATE = `/api/host-panel/createGroup`,
  GROUP_UPDATE = `/api/host-panel/updateGroup`,
  GROUP_DELETE = `/api/host-panel/deleteGroup`,
  GROUP_ADD_EMAIL = `/api/host-panel/addEmailToGroup`,
  GROUP_REMOVE_EMAIL = `/api/host-panel/removeEmailFromGroup`,
  USERS_GET = `/api/host-panel/getUsers`,
  USERS_CREATE = `/api/host-panel/createUsers`,
  USERS_REMOVE = `/api/host-panel/removeUserFromSpace`,
  USERS_UPLOAD = `/api/host-panel/createUsersFromCsv`,
  USERS_TOKEN = `/api/host-panel/generateTokens`,
}

type THostService = {
  currentTab: EHostTab;
  currentSpace: TSpace | null;
  hostModuleId: number | null;
  init: (space: TSpace) => void;
  setCurrentSpace: (space: TSpace) => void;
  setHostTab: (tab: EHostTab, moduleId?: number | null) => void;

  getModules: (space: TSpace) => TModule[];
  createModule: (data: Partial<TModule>, thumbnail: Blob) => Promise<void>;
  updateModule: (
    moduleId: TModule["id"],
    data: Partial<TModule>,
    thumbnail?: Blob
  ) => Promise<void>;
  updateDefaultModule: (moduleId) => Promise<any>;
  deleteModule: (moduleId: TModule["id"]) => Promise<void>;

  updateMediaArea: (
    mediaAreaId: TMediaAreaData["id"],
    data: Partial<TMediaAreaData>
  ) => Promise<void>;
  updateTriggerArea: (
    triggerAreaId: TTriggerAreaData["id"],
    data: Partial<TTriggerAreaData>
  ) => Promise<any>;

  users: THostPanelUser[];
  setUser: (user: TUser) => void;
  getUsers: () => Promise<THostPanelUser[]>;
  addUser: (userData: TUserPayload) => Promise<any>;
  addUserToGroup: (
    email: string,
    groupId: THostPanelGroup["id"]
  ) => Promise<Boolean>;
  removeUserFromGroup: (email: string) => Promise<Boolean>;
  removeUser: (email: string) => Promise<any>;
  uploadFromCsv: (file: File) => Promise<any>;
  createTokenUsers: (tokenData: TTokenPayload) => Promise<TTokenUser[]>;
  createUserCsv: (
    users: THostPanelUser[],
    domain: string
  ) => string | undefined;

  groups: THostPanelGroup[];
  getGroups: () => Promise<THostPanelGroup[]>;
  createGroup: (groudData: TGroupPayload) => Promise<THostPanelGroup>;
  updateGroup: (
    groupId: THostPanelGroup["id"],
    groupData: TGroupPayload
  ) => Promise<THostPanelGroup[]>;
  deleteGroup: (groupId: THostPanelGroup["id"]) => void;
};

export const createImageUpload = (name: string, image: Blob): FormData => {
  const formData = new FormData();
  formData.append(name, image);

  return formData;
};

const hostService = create<THostService>((set, get) => {
  return {
    currentTab: EHostTab.OVERVIEW,
    currentSpace: null,
    hostModuleId: null,

    init: (space: TSpace) => {
      return new Promise<Boolean>(async (resolve, reject) => {
        set({
          currentSpace: space,
          currentTab: EHostTab.OVERVIEW,
          hostModuleId: null,
        });

        try {
          await moduleBlueprintService.getState().init(space.id);
          await get().getGroups();
          await get().getUsers();
          resolve(true);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },
    setCurrentSpace: (space: TSpace) => {
      set({ currentSpace: space });
    },
    setHostTab: (tab: EHostTab, moduleId = null) => {
      if (moduleId) {
        set({ currentTab: tab, hostModuleId: moduleId });
        return;
      }

      set({ currentTab: tab, hostModuleId: null });
    },

    //////  Module
    getModules: (space: TSpace) => {
      const modules = space.modules.reduce((acc, moduleId) => {
        if (moduleId !== space.defaultModule) {
          const module = moduleService.getState().getModuleById(moduleId);
          if (module) acc.push(module);
        }
        return acc;
      }, [] as TModule[]);
      return modules;
    },
    updateDefaultModule: async (moduleId) => {
      const spaceId = get().currentSpace?.id;

      return new Promise<Array<THostPanelGroup>>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::updateDefaultModule: no space was set`);
          reject();
          return;
        }

        try {
          const response = await cmsService
            .getState()
            .sendData(`${HOST_ENDPOINTS.SPACE_UPDATE}?spaceId=${spaceId}`, {
              defaultModuleId: moduleId,
            });

          resolve(response);
        } catch (error) {
          debugService
            .getState()
            .logError(
              `HostService::updateDefaultModule: failed to crete group.`
            );
          debugService
            .getState()
            .logError(`HostService::updateDefaultModule: ${error}`);
          reject(error);
        }
      });
    },
    createModule: async (data, previewImage) => {
      const spaceId = get().currentSpace?.id;

      if (!spaceId) return;

      try {
        data.space = spaceId;

        //@ts-expect-error
        if (data.blueprint) data.blueprint = parseInt(data.blueprint);

        if (previewImage) {
          const uploadedData = await cmsService
            .getState()
            .sendData(
              `${HOST_ENDPOINTS.UPLOAD_MEDIA_FILE}?space=${spaceId}`,
              createImageUpload("previewImage", previewImage),
              "POST"
            );

          if (uploadedData?.length) {
            data.previewImage = uploadedData[0].id;
          }
        }

        const res = await cmsService
          .getState()
          .sendData(HOST_ENDPOINTS.MODULE_CREATE, data, "PUT");

        if (res.success) {
          await moduleService.getState().updateModules();
          spaceService.getState().addModuleToSpace(spaceId, res.id);
          get().setHostTab(EHostTab.MODULE, res.id);

          if (!get().currentSpace?.defaultModule) {
            await get()
              .updateDefaultModule(res.id)
              .then(() => window.location.reload());
          }
        }
      } catch (e) {
        debugService
          .getState()
          .addLog("ERROR", `hostService::createModule ${e}`);
      }
    },
    updateModule: async (moduleId, data, previewImage) => {
      const spaceId = get().currentSpace?.id;

      if (!moduleId || !data || !spaceId) return;

      try {
        if (previewImage) {
          const uploadedData = await cmsService
            .getState()
            .sendData(
              `${HOST_ENDPOINTS.UPLOAD_MEDIA_FILE}?space=${spaceId}`,
              createImageUpload("previewImage", previewImage),
              "POST"
            );

          if (uploadedData?.length) {
            data.previewImage = uploadedData[0].id;
          }
        }

        const updatedData = await cmsService
          .getState()
          .sendData(
            `${HOST_ENDPOINTS.MODULE_UPDATE}?moduleId=${moduleId}`,
            data,
            "POST"
          );

        if (updatedData) {
          moduleService.getState().updateModule(moduleId, updatedData);
        }
      } catch (e) {
        debugService
          .getState()
          .addLog("ERROR", `hostService::updateModule ${e}`);
      }
    },
    deleteModule: async (moduleId) => {
      const spaceId = get().currentSpace?.id;

      if (!moduleId || !spaceId) return;

      try {
        const res = await cmsService
          .getState()
          .sendData(
            `${HOST_ENDPOINTS.MODULE_DELETE}?moduleId=${moduleId}`,
            {},
            "DELETE"
          );

        if (res.success) {
          spaceService.getState().removeModuleFromSpace(spaceId, moduleId);
          moduleService.getState().removeModule(moduleId);

          hostService.getState().setHostTab(EHostTab.OVERVIEW);
        }
      } catch (e) {
        debugService
          .getState()
          .addLog("ERROR", `hostService::deleteModule ${e}`);
      }
    },

    updateMediaArea: async (mediaAreaId, data) => {
      const { hostModuleId } = get();

      const res = await cmsService
        .getState()
        .sendData(
          `${HOST_ENDPOINTS.UPDATE_MEDIA_AREA}?mediaAreaId=${mediaAreaId}`,
          data
        );

      if (res.success && hostModuleId) {
        moduleService
          .getState()
          .updateMediaArea(
            hostModuleId,
            parseInt(`${mediaAreaId}`),
            res.updatedMediaArea
          );
      }
    },
    updateTriggerArea: async (triggerAreaId, data) => {
      const module = moduleService.getState().modules
        .find(module => module.id === get().hostModuleId);

      return new Promise<Array<any>>(async (resolve, reject) => {
        if (!module) {
          reject("Failed to update trigger area: couldn't find module.");
          return;
        }

        const updatedTriggerAreaData: TTriggerAreaData[] = module.triggerAreas?.map(area => {
          if (area.id === triggerAreaId) {
            return {
              ...area,
              ...data
            };
          }
          return area;
        }) || [];

        try {
          const response = await cmsService
            .getState()
            .sendData(
              `${HOST_ENDPOINTS.UPDATE_TRIGGER_AREA}?moduleId=${module.id}`,
              updatedTriggerAreaData
            );

          if (!response.success) reject("Failed to update trigger area.");
          const { hostModuleId } = get();

          if (hostModuleId) {
            moduleService
              .getState()
              .updateTriggerAreas(hostModuleId, response.triggerAreas);
          }

          resolve(response.success);
        } catch (error) {
          debugService
            .getState()
            .logError(`HostService::updateTriggerArea: failed to get groups.`);
          debugService.getState().logError(`HostService::updateTriggerArea: ${error}`);
          reject("Failed to update trigger area.");
        }
      });


    },

    ////// Users
    users: [],
    setUser: (user) => {
      const users = get().users.map((_user) => {
        if (_user.id === user.id) {
          const parsedUser = parseHostPanelUser(_user, user);
          return parsedUser;
        }
        return _user;
      });
      set({ users });
    },
    getUsers: async () => {
      const spaceId = get().currentSpace?.id;

      return new Promise<Array<any>>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::getUsers: no space was set`);
          reject();
          return;
        }
        const space = spaceService.getState().getSpaceById(spaceId);
        if (!space) {
          debugService
            .getState()
            .logError(`HostService::getUsers: couldn't get space`);
          reject();
          return;
        }


        try {
          const spaceUsers = await cmsService
            .getState()
            .requestData(`${HOST_ENDPOINTS.USERS_GET}?spaceId=${spaceId}`);

          const userIds = spaceUsers.reduce((acc, spaceUser) => {
            const userInSpace = space.users.find(
              (_user) => _user.email === spaceUser.email
            );
            if (userInSpace) acc.push(userInSpace.id);
            return acc;
          }, []);
          const usersData = await userService.getState().getUsersByIds(userIds);

          const users: THostPanelUser[] = await Promise.all(
            spaceUsers.map(async (spaceUser) => {
              let userData = usersData.find(user => user.email === spaceUser.email);
              if (!userData) return parseHostPanelUser(spaceUser, null);;

              const group = get().groups.find((group) =>
                group.userEmails?.includes(spaceUser.email)
              );
              if (group) spaceUser.group = group;

              return parseHostPanelUser(spaceUser, userData);
            })
          );

          if (users.length > 0)
            users?.sort((a, b) => a.email.localeCompare(b.email));

          set({ users });
          resolve(users);
          return;
        } catch (error) {
          debugService
            .getState()
            .logError(`HostService::getUsers: failed to get groups.`);
          debugService.getState().logError(`HostService::getUsers: ${error}`);
          reject(`Failed to get users.`);
        }
      });
    },
    addUser: async (userData) => {
      const spaceId = get().currentSpace?.id;

      return new Promise<any>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::getUsers: no space was set`);
          reject(`No space set`);
          return;
        }
        const space = spaceService.getState().getSpaceById(spaceId);
        if (!space) {
          debugService
            .getState()
            .logError(`HostService::getUsers: couldn't get space`);
          reject();
          return;
        }

        try {
          const { email, groupId } = userData;

          const response = await cmsService.
            getState()
            .sendData(`${HOST_ENDPOINTS.USERS_CREATE}?spaceId=${spaceId}`, [email], "PUT");

          if (response.success) {
            if (groupId) {
              try {
                await get().addUserToGroup(email, groupId);
              } catch (error) {
                debugService
                  .getState()
                  .logError(
                    `HostService::addUser: failed to add user to group.`
                  );
                reject(`HostService::addUser: failed to add user to group.`);
                return;
              }
            }

            if (!response.newUserEmails) {
              debugService
                .getState()
                .logError(
                  `HostService::addUser: couldn't get new user emails array.`
                );
              reject();
              return;
            }
            const newUser = response.newUserEmails[0];
            let parsedUser;
            if (newUser) {
              parsedUser = Object.assign(newUser);
              const spaceUser = space.users.find(
                (_spaceUser) => _spaceUser.email === newUser.email
              );
              if (spaceUser) {
                const userData = await userService
                  .getState()
                  .getUserById(spaceUser.id);
                parsedUser = parseHostPanelUser(newUser, userData);
              }
            }

            set({ users: [parsedUser, ...get().users] });
            resolve(true);
            return;
          } else {
            reject();
            return;
          }
        } catch (error) {
          debugService
            .getState()
            .logError(`HostService::addUser: failed to crete group.`);
          debugService.getState().logError(`HostService::addUser: ${error}`);
          reject(`Failed to add ${userData.email} to space.`);
        }

        return;
      });
    },
    addUserToGroup: (email, groupId) => {
      const spaceId = get().currentSpace?.id;

      return new Promise<any>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::addUserToGroup: no space was set`);
          reject();
          return;
        }

        try {
          const response = await cmsService
            .getState()
            .sendData(
              `${HOST_ENDPOINTS.GROUP_ADD_EMAIL}?spaceId=${spaceId}`,
              { spaceId, groupId, email },
              "PUT"
            );

          if (!response.success) {
            reject();
            return;
          }

          set({ groups: response.groups });
          resolve(response.success);
          return;
        } catch (error) {
          debugService.getState().logError(`HostService::addUserToGroup: failed to add user group.`);
          debugService.getState().logError(`HostService::addUserToGroup: ${error}`);
          const group = get().groups.find(group => group.id === groupId);
          reject(`Failed to add ${email} to group ${group!.name}.`);
        }

        return;
      });
    },
    removeUserFromGroup: (email) => {
      const spaceId = get().currentSpace?.id;

      return new Promise<any>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::addUserToGroup: no space was set`);
          reject();
          return;
        }

        try {
          const response = await cmsService
            .getState()
            .sendData(
              `${HOST_ENDPOINTS.GROUP_REMOVE_EMAIL}?spaceId=${spaceId}`,
              { spaceId, email },
              "PUT"
            );

          if (!response.success) {
            reject();
            return;
          }

          set({ groups: response.groups });
          resolve(response.success);
          return;
        } catch (error) {
          debugService.getState().logError(`HostService::removeUserFromGroup: failed to remove user from group.`);
          debugService.getState().logError(`HostService::removeUserFromGroup: ${error}`);
          reject(`Failed to remove ${email} from group.`);
        }

        return;
      });
    },
    removeUser: async (email) => {
      const spaceId = get().currentSpace?.id;

      return new Promise<any>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::createNewGroup: no space was set`);
          reject();
          return;
        }

        try {
          const response = await cmsService
            .getState()
            .sendData(
              `${HOST_ENDPOINTS.USERS_REMOVE}?spaceId=${spaceId}&email=${email}`,
              {},
              "PUT"
            );

          if (!response.success) {
            reject(`Failed to remove access to ${email}.`); reject
          }

          const users = get().users.filter(_user => _user.email !== email);
          set({ users });
          resolve(response.success);
        } catch (error) {
          debugService.getState().logError(`HostService::removeUser: failed to remove user.`);
          debugService.getState().logError(`HostService::removeUser: ${error}`);
          reject(`Failed to remove access to ${email}.`);
        }

        return;
      });
    },
    uploadFromCsv: async (file) => {
      const spaceId = get().currentSpace?.id;

      return new Promise<any>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::uploadFromCsv: no space was set`);
          reject();
          return;
        }
        const space = spaceService.getState().getSpaceById(spaceId);
        if (!space) {
          debugService
            .getState()
            .logError(`HostService::getUsers: couldn't get space`);
          reject();
          return;
        }

        try {
          const formData = new FormData();
          formData.append("file", file);

          const response = await cmsService
            .getState()
            .sendData(
              `${HOST_ENDPOINTS.USERS_UPLOAD}?spaceId=${spaceId}`,
              formData,
              "PUT",
              true,
              { "Content-Type": "multipart/form-data" }
            );

          if (!response.success) {
            debugService
              .getState()
              .logError(`HostService::uploadFromCsv: failed to crete users.`);
            reject();
            return;
          }

          let newUsers = response.newUsers;
          const newUserIds = newUsers.reduce((acc, newUser) => {
            if (newUser.id) acc.push(newUser.id);
            return acc;
          }, []);
          const newUsersData = await userService
            .getState()
            .getUsersByIds(newUserIds);

          newUsers = newUsers.map((newUser) => {
            if (!newUser.id) return newUser;

            const userData = newUsersData.find(
              (user) => user.email === newUser.email
            );
            if (!userData) return newUser;
            return parseHostPanelUser(newUser, userData);
          });

          set({ users: [...newUsers, ...get().users] });
          resolve(response);
          return;
        } catch (error) {
          debugService.getState().logError(`HostService::uploadFromCsv: failed to upload users from csv.`);
          debugService.getState().logError(`HostService::uploadFromCsv: ${error}`);
          reject(`Failed to upload users from CSV file.`);
        }

        return;
      });
    },
    createTokenUsers: async (payload) => {
      const spaceId = get().currentSpace?.id;

      return new Promise<any>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::uploadFromCsv: no space was set`);
          reject();
          return;
        }

        try {
          const response = await cmsService.getState().sendData(
            `${HOST_ENDPOINTS.USERS_TOKEN}`,
            {
              space: spaceId,
              ...payload,
            },
            "PUT"
          );

          if (!response.success) {
            debugService
              .getState()
              .logError(
                `HostService::createTokenUsers: failed to crete tokens.`
              );
            reject();
            return;
          }

          let tokenUsers = response.users;

          if (payload.group) {
            await get().getGroups();
            const group = get().groups.find(
              (group) => group.id === payload.group
            );
            tokenUsers = tokenUsers.map((user) => {
              return {
                ...user,
                group,
              };
            });
          }

          resolve(tokenUsers);
          return;
        } catch (error) {
          debugService.getState().logError(`HostService::createTokenUsers: failed to crete tokens.`);
          debugService.getState().logError(`HostService::createTokenUsers: ${error}`);
          reject(`Failed to create ${payload.quantity} token users.`);
        }

        return;
      });
    },
    createUserCsv: (users, domain) => {
      const spaceId = get().currentSpace?.id;
      if (!spaceId) {
        debugService
          .getState()
          .logError(`HostService::uploadFromCsv: no space was set`);
        return;
      }

      const space = spaceService.getState().getSpaceById(spaceId);
      if (!space) {
        debugService
          .getState()
          .logError(`HostService::getUsers: couldn't get space`);
        return;
      }

      let csvContent =
        `PwC Spaces token user account list\n\n` +
        `User id,Space Title,Group Name,token user account sign in url,valid date (utc),invalid date (utc)\n`;

      const userRows = users.map((user) => {
        const data = [
          user.id,
          space.title,
          user.group?.name,
          user.token ? `${domain}/setup/signin#${user.token}` : "",
          user.validDate ? new Date(user.validDate).toISOString() : "",
          user.invalidDate ? new Date(user.invalidDate).toISOString() : "",
        ];
        return data.join(",");
      });

      csvContent = csvContent + userRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      return window.URL.createObjectURL(blob);
    },

    ////// Groups
    groups: [],
    getGroups: async () => {
      const spaceId = get().currentSpace?.id;
      return new Promise<Array<THostPanelGroup>>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::createNewGroup: no space was set`);
          reject();
          return;
        }

        try {
          let groups = await cmsService
            .getState()
            .requestData(`${HOST_ENDPOINTS.GROUP_GET}?spaceId=${spaceId}`);

          groups = groups.sort((a, b) => a.name.localeCompare(b.name));

          set({ groups });
          resolve(groups);
          return;
        } catch (error) {
          debugService
            .getState()
            .logError(`HostService::getGroups: failed to get groups.`);
          debugService.getState().logError(`HostService::getGroups: ${error}`);
          reject(`Failed to get groups.`);
        }
      });
    },
    createGroup: async (groupData) => {
      const spaceId = get().currentSpace?.id;
      return new Promise<THostPanelGroup>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::createNewGroup: no space was set`);
          reject();
          return;
        }

        try {
          const payload = {
            space: spaceId,
            name: groupData.name,
            userEmails: [],
            access: groupData.access || [],
          };
          const { groups } = await cmsService
            .getState()
            .sendData(HOST_ENDPOINTS.GROUP_CREATE, payload, "PUT");

          set({ groups });
          resolve(groups);
          return;
        } catch (error) {
          debugService.getState().logError(`HostService::createGroup: failed to crete group.`);
          debugService.getState().logError(`HostService::createGroup: ${error}`);
          reject(`Failed to create new group ${groupData.name}.`);
        }

        return;
      });
    },
    updateGroup: async (groupId, groupData) => {
      const spaceId = get().currentSpace?.id;

      return new Promise<Array<THostPanelGroup>>(async (resolve, reject) => {
        if (!spaceId) {
          debugService
            .getState()
            .logError(`HostService::updateGroup: no space was set`);
          reject();
          return;
        }

        try {
          const { group } = await cmsService
            .getState()
            .sendData(
              `${HOST_ENDPOINTS.GROUP_UPDATE}?groupId=${groupId}`,
              {
                space: spaceId,
                ...groupData
              }
            );

          if (group) {
            const updatedGroups = get().groups.map((_group) => {
              if (_group.id === groupId) return group;
              return _group;
            });

            set({ groups: updatedGroups });
            resolve(group);
          }
        } catch (error) {
          debugService.getState().logError(`HostService::updateGroup: failed to update group.`);
          debugService.getState().logError(`HostService::updateGroup: ${error}`);
          reject(`Failed to update group.`);
        }
      });
    },
    deleteGroup: async (groupId) => {
      const spaceId = get().currentSpace?.id;
      if (!spaceId) {
        debugService
          .getState()
          .logError(`HostService::deleteGroup: no space was set`);
        return;
      }

      return new Promise<Boolean>(async (resolve, reject) => {
        try {
          const response = await cmsService
            .getState()
            .sendData(
              `${HOST_ENDPOINTS.GROUP_DELETE}?spaceId=${spaceId}&groupId=${groupId}`,
              {},
              "DELETE"
            );

          if (response.success) {
            const newGroups = get().groups.filter(
              (group) => group.id !== groupId
            );
            set({ groups: newGroups });
          }

          resolve(response.success);
          return;
        } catch (error) {
          debugService.getState().logError(`HostService::deleteGroup: failed to delete group.`);
          debugService.getState().logError(`HostService::deleteGroup: ${error}`);
          const group = get().groups.find(group => group.id === groupId);
          reject(`Failed to delete group ${group!.name}`);
        }

        return;
      });
    },
  };
});

export default hostService;
