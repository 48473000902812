import create from "zustand";

import { TSpace } from "../SpaceService/types";

import { TStrapiDocument } from "@/types/StrapiDocument";

import chatService, { TChat } from "@/services/ChatService";
import cmsService from "@/services/CmsService";
import graphicsService from "@/services/GraphicsService";
import moduleService from "@/services/ModuleService";
import { TModule } from "@/services/ModuleService/types";
import sceneService from "@/services/SceneService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

export type TMetaNavContent = {
  privacyPolicy: TStrapiDocument | null;
  termsConditions: TStrapiDocument | null;
  legalNoticeUrl: string | null;
  faq: TStrapiDocument | null;
};

const DEFAULT_META_NAV_CONTENT = {
  privacyPolicy: {
    url: "https://assetsdev11272562.blob.core.windows.net/assetsdev11272562-container/Data%20Privacy%20Statement%20Virtual%20Spaces%20DE.pdf",
  } as TStrapiDocument,
  termsConditions: null,
  legalNoticeUrl: "https://www.pwc.de/de/impressum.html",
  faq: null,
};

export enum ENavSection {
  SPACES = "Spaces",
  CHATS = "Chats",
}

export enum EOverviewPanel {
  CHATS = "Chats",
  SPACES = "Spaces",
  NEW_CHAT = "NewChat",
  PROFILE = "Profile",
  ADD_TO_CALL = "AddToCall",
}

export type TPanelData = TModule & {
  id: number | null;
  users: Array<TUser["id"]>;
};

const initialNavSection = new Map(
  Object.values(ENavSection).map((value) => [value, false])
);
const initialNavSectionExpansion = new Map(
  Object.values(ENavSection).map((value) => [value, false])
);
const initialOverviewPanels = new Map(
  Object.values(EOverviewPanel).map((value) => [value, null])
);

// this will fall back to the global or default meta nav content if the input is incomplete
export const buildValidMetaNavContent = (
  metaNavContent: TMetaNavContent
): TMetaNavContent => {
  const fallbackMetaNavContent = navService.getState().metaNavContent
    ? navService.getState().metaNavContent
    : DEFAULT_META_NAV_CONTENT;
  if (!metaNavContent) return fallbackMetaNavContent;

  const { privacyPolicy, legalNoticeUrl, termsConditions, faq } =
    fallbackMetaNavContent;

  return {
    privacyPolicy: metaNavContent.privacyPolicy
      ? metaNavContent.privacyPolicy
      : privacyPolicy,
    legalNoticeUrl: metaNavContent.legalNoticeUrl
      ? metaNavContent.legalNoticeUrl
      : legalNoticeUrl,
    termsConditions: metaNavContent.termsConditions
      ? metaNavContent.termsConditions
      : termsConditions,
    faq: metaNavContent.faq ? metaNavContent.faq : faq,
  };
};

type TNavService = {
  isNavVisible: boolean;
  isSignInFormVisible: boolean;
  currentOverviewPanel: EOverviewPanel | null;
  tabIndex: number;
  navSections: Map<ENavSection, boolean>;
  navSectionsExpanded: Map<ENavSection, boolean>;
  overviewPanels: Map<EOverviewPanel, TPanelData | null>;
  spaceNavItemSelected: TSpace["id"] | null;
  moduleNavItemSelected: TModule["id"] | null;
  activeItem: boolean;
  activeSection: ENavSection;

  metaNavContent: TMetaNavContent;

  init: () => Promise<boolean>;

  showNav: () => void;
  hideNav: () => void;

  toggleSignInFrom: () => void;

  toggleNavSectionActiveItem: (status: boolean, section: ENavSection) => void;

  // controls wether the sections on main nav (spaces and chats) are expanded,
  // showing the modules and the private chats
  setNavSectionExpansion: (section: ENavSection, value?: boolean) => void;
  toggleNavSectionExpansion: (section: ENavSection) => void;

  openNavSection: (section: ENavSection) => void;
  closeNavSection: (section: ENavSection) => void;
  toggleNavSection: (section: ENavSection) => void;
  closeAllNavSections: () => void;

  // controls wether a space nav item is expanded or not,
  // showing its submodules
  setSpaceNavItemSelected: (
    spaceId: TSpace["id"] | null,
    module?: TModule["id"] | null
  ) => void;

  openPanel: (
    panel: EOverviewPanel,
    id?: number | null,
    tabIndex?: number
  ) => void;
  closePanel: (panel: EOverviewPanel) => void;
  closeAllPanels: () => void;
  setPanel: (
    panel: EOverviewPanel | null,
    id?: number | null,
    tabIndex?: number
  ) => void;
};

const navService = create<TNavService>((set, get) => ({
  isNavVisible: true,
  isSignInFormVisible: false,
  navSections: initialNavSection,
  navSectionsExpanded: initialNavSectionExpansion,
  moduleNavItemSelected: null,
  spaceNavItemSelected: null,
  currentOverviewPanel: null,
  overviewPanels: initialOverviewPanels,
  tabIndex: 0,
  metaNavContent: DEFAULT_META_NAV_CONTENT,
  activeItem: false,
  activeSection: ENavSection.SPACES,

  showNav: () => {
    set({ isNavVisible: true });
  },

  init: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const cmsMetaNavContent: TMetaNavContent = await cmsService
          .getState()
          .requestData("/getMetaNavContent");

        set({
          metaNavContent: buildValidMetaNavContent(cmsMetaNavContent),
        });

        resolve(true);
        return;
      } catch (error) {
        reject(error);
        return;
      }
    });
  },

  hideNav: () => {
    get().closeAllNavSections();
    set({ isNavVisible: false });
  },

  toggleSignInFrom: () => {
    const currentState = get().isSignInFormVisible;
    set({ isSignInFormVisible: !currentState });
  },

  openNavSection: (section: ENavSection) => {
    set((state) => {
      state.navSections.set(section, true);
    });
  },
  closeNavSection: (section: ENavSection) => {
    set((state) => {
      state.navSections.set(section, false);
    });
  },
  toggleNavSectionActiveItem: (status: boolean, section: ENavSection) => {
    set({ activeItem: status, activeSection: section });
  },
  closeAllNavSections: () => {
    initialNavSection.forEach((value, key, map) => {
      map.set(key, false);
    });
  },
  toggleNavSection: (section: ENavSection) => {
    const currentState = get().navSections.get(section);
    set((state) => {
      state.navSections.set(section, !currentState);
    });
  },
  toggleNavSectionExpansion: (section: ENavSection) => {
    const currentState = get().navSectionsExpanded.get(section);
    get().setNavSectionExpansion(section, !currentState);
  },
  setNavSectionExpansion: (section: ENavSection, value: boolean) => {
    set((state) => {
      state.navSectionsExpanded.set(section, value);
    });
    if (value === false) get().closeNavSection(section);
  },
  setSpaceNavItemSelected: (spaceId: TSpace["id"], moduleId = null) => {
    // if user clicks on a module that is already active, closes the panel
    if (moduleId === get().moduleNavItemSelected) {
      set({
        spaceNavItemSelected: null,
        moduleNavItemSelected: null,
      });
      return;
    }

    set({
      spaceNavItemSelected: spaceId,
      moduleNavItemSelected: moduleId,
    });
  },

  openPanel: (panel: EOverviewPanel, id = null, tabIndex = 0) => {
    let data;
    let chat: TChat | null = null;

    chatService.getState().disconnectChat();

    // get data based on panel type
    switch (panel) {
      case EOverviewPanel.SPACES: {
        if (!id) break;
        data = moduleService.getState().getModuleById(id);
        chat = chatService.getState().getChatByModule(data);
        get().setNavSectionExpansion(ENavSection.SPACES, true);
        get().openNavSection(ENavSection.SPACES);
        get().setSpaceNavItemSelected(data.space, id);
        break;
      }
      case EOverviewPanel.CHATS: {
        if (!id) break;
        data = chatService.getState().chats.find((chat) => chat.id === id);
        chat = data as TChat;
        chat.users = chat.users.filter(
          (userId) => !userService.getState().isSelf(userId)
        );
        get().setSpaceNavItemSelected(null);
        get().setNavSectionExpansion(ENavSection.CHATS, true);
        get().openNavSection(ENavSection.CHATS);
        break;
      }
      case EOverviewPanel.PROFILE: {
        graphicsService.setState({ renderScene: true, enableScene: true });
        sceneService.setState({
          communicationAreaCta: { name: "", status: false },
        });
        data = {};
        break;
      }
      default: {
        data = {};
        break;
      }
    }

    // connect to chat if panel content is chats or spaces
    if (chat) {
      chatService.getState().resetNewMessageCount(chat.id);
      chatService.getState().connectChat(chat);
    }

    const overviewPanels = get().overviewPanels;
    overviewPanels.set(panel, data);

    set({
      currentOverviewPanel: panel,
      overviewPanels,
      tabIndex,
    });
  },
  closePanel: (panel: EOverviewPanel) => {
    if (get().overviewPanels.get(panel) === null) return;

    let closeSpaceNavItem = {};
    if (panel === EOverviewPanel.SPACES) {
      closeSpaceNavItem = {
        spaceNavItemSelected: null,
        moduleNavItemSelected: null,
      };
    }

    chatService.getState().disconnectChat();

    const overviewPanels = get().overviewPanels;
    overviewPanels.set(panel, null);

    set({
      ...closeSpaceNavItem,
      currentOverviewPanel: null,
      overviewPanels,
    });
  },
  closeAllPanels: () => {
    chatService.getState().disconnectChat();

    const overviewPanels = new Map(initialOverviewPanels);
    get().closeAllNavSections();
    set({
      currentOverviewPanel: null,
      overviewPanels,
      spaceNavItemSelected: null,
      moduleNavItemSelected: null,
      navSectionsExpanded: initialNavSectionExpansion,
    });
  },

  // This function decided when to hide or show a panel element
  setPanel: (panel, id = null, tabIndex = 0) => {
    const currentPanel = get().currentOverviewPanel;
    const currentPanelData = currentPanel
      ? get().overviewPanels.get(currentPanel)
      : null;

    if (
      panel === null ||
      (panel === EOverviewPanel.CHATS && id === currentPanelData?.id)
    ) {
      get().closeAllPanels();
      return;
    }

    switch (panel) {
      case EOverviewPanel.CHATS:
      case EOverviewPanel.SPACES:
        if (id === currentPanelData?.id) {
          get().closePanel(panel);
        } else {
          get().openPanel(panel, id, tabIndex);
        }
        break;
      case EOverviewPanel.ADD_TO_CALL:
      case EOverviewPanel.NEW_CHAT:
      case EOverviewPanel.PROFILE:
        get().openPanel(panel);
        break;
      default:
        break;
    }
  },
}));

export default navService;
