import badgeService from "@/services/BadgeService";
import debugService from "@/services/DebugService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import { arrayPick } from "@/utils/Array";
import { randomIntFromInterval } from "@/utils/Math";

import { avatarColours } from "@/styles/colors";
import { THostPanelUser } from "@/services/HostService/types";

export const profileColor = (id) => {
  if (id % 5 === 0) return avatarColours[0];
  if (id % 4 === 0) return avatarColours[1];
  if (id % 3 === 0) return avatarColours[2];
  else return avatarColours[3];
};

export const getInitials = (firstname, lastname) => {
  if (!firstname && !lastname) return "AP";
  if (!firstname) return `${lastname[0]}`;
  if (!lastname) return `${firstname[0]}`;
  return `${firstname[0]}${lastname[0]}`;
};

type TCreateNamesListOptions = {
  separator?: string;
  filterSelf?: boolean;
};

export const createNamesListFromUsers = (
  users: TUser[],
  options: TCreateNamesListOptions = {}
) => {
  const { separator = ",", filterSelf = true } = options;

  //Check if we need to filter out ourselves and check for undefined users
  const filteredUsers: Array<TUser> = users.filter((user) => {
    if (!user) return false;
    if (filterSelf && userService.getState().isSelf(user.id)) return false;
    return true;
  });

  const userNames = filteredUsers.map((user) =>
    userService.getState().buildUserName(user)
  );

  return userNames.join(`${separator} `);
};

export const createNamesListFromUserIds = async (
  userIds: Array<number> = [],
  options: TCreateNamesListOptions = {}
) => {
  if (!userIds[0]) return;

  try {
    const users: Array<TUser> = await userService
      .getState()
      .getUsersByIds(userIds);

    return createNamesListFromUsers(users, options);
  } catch (error) {
    debugService.getState().logError(`createNamesListFromUserIds(): ${error}`);
    return "";
  }
};

export const filterUserByName = async (
  users: Array<TUser>,
  searchUsername: string
): Promise<Array<TUser>> => {
  return new Promise<Array<TUser>>(async (resolve, reject) => {
    if (!users?.length) {
      reject("Invalid input argument!");
      return;
    }
    if (!searchUsername) {
      resolve(users);
      return;
    }

    const userNameParts: Array<string> = searchUsername.split(" ");

    if (!userNameParts.length) {
      reject("Invalid search username!");
      return;
    }

    const userFirstname: string = userNameParts[0];
    const userLastname: string = userNameParts[1] ? userNameParts[1] : "";

    try {
      const users = await userService
        .getState()
        .getUsersByName(userFirstname, userLastname);
      resolve(users);
      return;
    } catch (error) {
      reject(error);
      return;
    }
  });
};
export const sortUsers = (users: Array<TUser>): Array<TUser> => {
  const { buildUserName } = userService.getState();

  return users.sort((userA, userB) => {
    const userNameA = buildUserName(userA);
    const userNameB = buildUserName(userB);

    if (userNameA < userNameB) return -1;
    if (userNameA > userNameB) return 1;

    return 0;
  });
};

export const createRandomUser = (presets: Partial<TUser> = {}): TUser => {
  const id = presets.id || randomIntFromInterval(900000000, 999999999);

  const user: TUser = {
    id,
    badge: arrayPick(badgeService.getState().badges)?.id,
    currentModuleId: 0,
    currentSpaceId: 0,
    email: `${id}@pwc.com`,
    lastname: arrayPick(["Bot"]),
    firstname: arrayPick(["Npc"]),
    username: id.toString(),
    online: false,
    role: arrayPick(["Visitor", "Host"]),
    //@ts-ignore
    profileImage: undefined,
    //@ts-ignore
    userData: undefined,
    //@ts-ignore
    validDate: undefined,
    //@ts-ignore
    invalidDate: undefined,
  };

  return { ...user, ...presets };
};

export const parseHostPanelUser = (spaceUser: THostPanelUser, userData: TUser | null) => {

  let _spaceUser: THostPanelUser = {
    email: spaceUser.email,
    role: spaceUser.role,
    idSpaceUser: spaceUser.id,
  };

  if (userData) {
    _spaceUser = {
      ..._spaceUser,
      id: userData.id,
      firstname: userData.firstname,
      lastname: userData.lastname,
      validDate: userData.validDate,
      invalidDate: userData.invalidDate,
    }
  }

  return _spaceUser;
}