import { useEffect } from "react";

import Button from "../../Common/Button";

import soundService from "@/services/AudioService";
import notificationService from "@/services/NotificationService";

import { Text } from "@/styles/typography";

import {
  IconWrapper,
  TextWrapper,
  NotificationHeadline,
  ButtonWrapper,
  StyledButtontNotification as StyledButtonNotification,
} from "./styles";

type TDefaultNotification = {
  headline: string;
  text: string;
  btnLabel: string;
  icon?: {
    element: any;
    width: number;
    height: number;
  };
  onClick: () => void;
  playSound?: boolean;
  id?: string;
};

const ButtonNotification = ({
  headline,
  text,
  btnLabel = "OK",
  icon = { element: undefined, width: 10, height: 10 },
  onClick,
  playSound = true,
  id, //Automatically gets injected in notificationService.addNotification()
}: TDefaultNotification) => {
  if (playSound) {
    useEffect(() => {
      soundService
        .getState()
        .play("/assets/audio/notification.mp3", { delay: 200 });
    }, []);
  }

  const onClickClick = () => {
    onClick();
    if (id) notificationService.getState().removeNotification(id);
  };

  return (
    <StyledButtonNotification>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {icon.element && (
          <IconWrapper iconWidth={icon.width} iconHeight={icon.height}>
            {icon.element}
          </IconWrapper>
        )}

        <TextWrapper>
          <NotificationHeadline bold={true}>{headline}</NotificationHeadline>

          <Text>{text}</Text>

          <ButtonWrapper>
        <Button text={btnLabel} onClick={onClickClick} />
      </ButtonWrapper>
        </TextWrapper>
      </div>
    </StyledButtonNotification>
  );
};

export default ButtonNotification;
