import styled from "styled-components";

import { BigMidHeadline } from "@/styles/typography";

export const AvatarForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const Title = styled(BigMidHeadline)`
  line-height: 130%;
  max-width: 20rem;
`;
