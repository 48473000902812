import axios, { AxiosResponse, Method } from "axios";
import create from "zustand";

import authService from "@/services/AuthService";
import httpClient from "@/utils/HttpClient";

type TCacheData = {
  url: string;
  timestamp: number;
  data: Object;
};

type TCmsService = {
  sendData: (
    url: string,
    data: object,
    method?: Method,
    useAuthorization?: boolean,
    customHeaders?: any
  ) => Promise<any>;
  requestData: (
    url: string,
    checkCache?: boolean,
    useAuthorization?: boolean
  ) => Promise<any>;
};

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error.response?.status == 412) {
      localStorage.setItem("forcedLogout", "true");
      authService.getState().signOut("/setup/signin");
    }
  }
);

const parseNetworkErrorMessage = (error): string => {
  let rejectMessage = "Request failed!";

  if (!error) return rejectMessage;

  if (error.message === "Network Error") return "Failed to connect to CMS!";

  if (error.response) {
    const { config, status } = error.response;

    const urlParts = error.response.config.url.split("/");

    // remove http and domain
    urlParts.shift();
    urlParts.shift();

    const routeName = urlParts.join("/");

    rejectMessage = `${config.method} request to ${routeName} failed = ${status}!`;
  }

  return rejectMessage;
};

const cmsService = create<TCmsService>((set, get) => {
  const API_URL = process.env.NEXT_PUBLIC_API_URL;
  const CACHE_TIME = 4999; // milliseconds

  const formatUrl = (url) =>
    url.charAt(0) === "/"
      ? `${API_URL}/${url.substring(1)}`
      : `${API_URL}/${url}`;

  /*
   *  Cache
   */
  let cache = new Array<TCacheData>();

  const getCacheEntry = (url) => {
    cache = cleanCache(Date.now());

    const cachedData = cache.find((entry) => entry.url === url);

    return cachedData?.data;
  };

  //Remove all entries that are older than the cache time
  const cleanCache = (now) => {
    return cache.filter((entry) => now - entry.timestamp < CACHE_TIME);
  };

  const setCacheEntry = (url: string, data) => {
    const newCacheEntry: TCacheData = {
      url,
      timestamp: Date.now(),
      data,
    };

    cache.push(newCacheEntry);
  };

  return {
    sendData: async (
      url,
      data,
      method = "POST",
      useAuthorization = true,
      customHeaders
    ) => {
      return new Promise<any>(async (resolve, reject) => {
        if (!url || !data) reject("Invalid input arguments!");

        const _url = formatUrl(url);

        // only send headers here and no config object like in requestData
        let headers = {};
        // Send sessionId from local storage with each request to validate if a user might have loged in on a different device
        const sessionId = localStorage.getItem("sessionId");
        if (sessionId) {
          headers["sessionId"] = sessionId;
        }

        if (useAuthorization && authService.getState().token) {
          headers["Authorization"] = `Bearer ${authService.getState().token}`;
        }

        if (customHeaders)
          headers = {
            ...customHeaders,
            ...headers,
          };

        try {
          const response = await httpClient.sendData(
            _url,
            data,
            headers,
            method
          );

          // ToDo: Extract strapi error message!
          if (
            response?.status < 200 ||
            response.status > 299 ||
            response?.data == null
          ) {
            reject(
              `Receiving data from ${response.config.url} failed = ${response.config.method} ${response.status} ${response.statusText}`
            );
            return;
          }

          resolve(response.data);
        } catch (error) {
          reject(parseNetworkErrorMessage(error));
          return;
        }
      });
    },

    requestData: async (url, checkCache = true, useAuthorization = true) => {
      return new Promise<any>(async (resolve, reject) => {
        if (!url) {
          reject("Invalid input arguments!");
          return;
        }

        const _url = formatUrl(url);

        const config =
          useAuthorization && authService.getState().token
            ? {
                headers: {
                  Authorization: `Bearer ${authService.getState().token}`,
                },
              }
            : {};

        const cachedData = checkCache ? getCacheEntry(_url) : null;
        if (cachedData) {
          resolve(cachedData);
          return;
        }

        try {
          const response = await httpClient.requestData(_url, config);

          if (response?.status < 200 || response.status > 299) {
            reject(
              `Requesting data from ${response.config?.url} failed = ${response.config?.method} ${response.status} ${response.statusText}`
            );
            return;
          }

          setCacheEntry(_url, response.data);
          resolve(response.data);

          return;
        } catch (error) {
          reject(parseNetworkErrorMessage(error));
          return;
        }
      });
    },
  };
});

export default cmsService;
