import { v4 as uuidv4 } from "uuid";
import create from "zustand";

import NpcController, {
  customBehaviourFunction,
} from "@/components/Scene/Npc/NpcController";

import { getRandomAvatarConfig } from "@/services/AvatarConfigService/customizationScheme";
import {
  playerService,
  PlayerTransform,
  TAvatarConfig,
} from "@/services/PlayerService";
import sceneService from "@/services/SceneService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import { Octree } from "@/utils/Collision/Octree";
import { createRandomUser } from "@/utils/User";

export type TNpc = {
  id: string;
  user: TUser;
  controller: NpcController;
};

type TNpcService = {
  npcs: Map<TNpc["id"], TNpc>;

  add: (options?: {
    user?: TUser;
    avatarConfig?: TAvatarConfig;
    customBehaviour?: customBehaviourFunction | null;
    onClick?: () => void | null;
  }) => TNpc;
  remove: (id: TNpc["id"]) => void;
  get: (id: TNpc["id"]) => TNpc | undefined;
};

const npcService = create<TNpcService>((set, get) => {
  return {
    npcs: new Map<TNpc["id"], TNpc>(),

    add: ({
      user = createRandomUser(),
      avatarConfig = getRandomAvatarConfig(),
      customBehaviour,
      onClick,
    } = {}) => {
      const npc: TNpc = {
        id: uuidv4(),
        user,
        controller: new NpcController(
          sceneService.getState().groundCollisionOctree as Octree,
          playerService
            .getState()
            .allPlayers.get(
              (userService.getState().ownUser as TUser).id
            ) as PlayerTransform,
          customBehaviour,
          avatarConfig,
          onClick
        ),
      };

      set({
        npcs: get().npcs.set(npc.id, npc),
      });

      return npc;
    },

    remove: (id) => {
      const { npcs } = get();
      if (npcs) npcs.delete(id);
      set({ npcs: new Map(npcs) });
    },

    get: (id) => get().npcs.get(id),
  };
});

export default npcService;
