import styled from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { SmallHeadline } from "@/styles/typography";

export const ArrowWrapper = styled.div`
  background-color: ${colors.white.default};
  transition: transform 0.2s ${ease.inOutCubic};
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  color: ${colors.orange.hex};
  display: grid;
  place-items: center;

  svg {
    width: 0.6rem;
  }
`;

export const StyledButtonArrow = styled.button`
  min-width: 11rem;
  padding: 0.5rem;
  background-color: ${colors.orange.hex};
  display: flex;
  align-items: center;
  border-radius: 2rem;
`;

export const StyledText = styled(SmallHeadline)`
  text-transform: uppercase;
  margin-left: 0.5rem;
  margin-right: 1.5rem;
  color: ${colors.white.default};
`;
