import styled from "styled-components";

import { StyledButtonArrow } from "@/components/Dom/Common/ButtonArrow/styles";

import { colors } from "@/styles/colors";
import { Text } from "@/styles/typography";

export const StyledInfoNotification = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 80rem;
  max-width: 100%;
  background-color: ${colors.white.default};
  border-radius: 1rem;
  overflow: hidden;
`;

export const TextWrapper = styled.div`
  padding: 4rem 2rem 4rem 4rem;
  grid-column: 1 / 3;

  ${StyledButtonArrow} {
    margin-top: 3rem;
  }
`;

export const StyledText = styled(Text)`
  margin-top: 1.7rem;
  color: ${colors.grey.normal.hex};
`;

export const StyledSubText = styled(Text)`
  margin-top: 2.6rem;
`;

export const ImageWrapper = styled.div`
  clip-path: circle(100% at 101% 36%);
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;

  ${StyledButtonArrow} {
    position: absolute;
    bottom: 3rem;
    right: 2.5rem;
  }
`;
