import styled from "styled-components";

import { StyledUserBadge } from "@/components/Dom/Common/UserBadge/styles";

import { TextStyles } from "@/styles/typography";

export const StyledUserName = styled.span`
  ${(props) => props.applyFontStyle && TextStyles};

  ${StyledUserBadge} {
    margin-right: 0.4em;
    font-size: 0.6em;
  }
`;
