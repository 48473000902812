import * as React from "react";

import { TUser } from "@/services/UserService/types";
import { useSasParams } from "@/utils/Azure";
import { getImageUrl } from "@/utils/Strapi";
import {
  createNamesListFromUsers,
  getInitials,
  profileColor,
} from "@/utils/User";

import { StyledAvatar, StyledAvatarImage, StyledGroupCount } from "./styles";

const Avatar = ({ user }: { user: TUser }) => {
  const { id, profileImage, firstname, lastname } = user;
  const initials = getInitials(firstname, lastname);
  const imageUrl = getImageUrl(profileImage, "thumbnail");
  const { urlWithParams: bgImageUrlWithParams } = useSasParams(imageUrl);

  return (
    <StyledAvatar theme={profileColor(id)}>
      <span aria-hidden>{initials}</span>
      {bgImageUrlWithParams &&
        <img
          alt={`${firstname} ${lastname} profile picture`}
          src={bgImageUrlWithParams}
        />}
    </StyledAvatar>
  );
};

type TAvatarImage = {
  users: TUser | TUser[];
  title?: string;
};

const AvatarImage = ({ users, title, ...others }: TAvatarImage) => {
  if (!users) return null;
  const _users = Array.isArray(users) ? users : [users];
  const isGroup = _users.length > 1;

  return (
    <StyledAvatarImage
      title={title || createNamesListFromUsers(_users)}
      isGroup={isGroup}
      {...others}
    >
      {_users.flatMap((user, index) =>
        index < 2 ? <Avatar user={user} key={user.id} /> : null
      )}

      {isGroup && <StyledGroupCount>{_users.length}</StyledGroupCount>}
    </StyledAvatarImage>
  );
};

export default AvatarImage;
