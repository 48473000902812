import Router from "next/router";
import React from "react";
import create from "zustand";

import { TSpace, TSpaceMap, TGroup, ESpaceRole } from "./types";

import ButtonNotification from "@/components/Dom/Notifications/ButtonNotification";

import cmsService from "@/services/CmsService";
import debugService from "@/services/DebugService";
import hostService from "@/services/HostService";
import { TModule } from "@/services/ModuleService/types";
import {
  buildValidMetaNavContent,
  TMetaNavContent,
} from "@/services/NavService";
import notificationService from "@/services/NotificationService";
import rehService from "@/services/RehService";
import userService from "@/services/UserService";
import videoService from "@/services/VideoService";
import { getEnumKeyByValue } from "@/utils/Misc";
import Megaphone from "~/public/assets/icons/Megaphone.svg";

type TSpaceService = {
  spaces: TSpaceMap;

  init: (isOnHostPage: boolean) => Promise<TSpaceMap>;
  updateSpaces: () => Promise<TSpaceMap>;

  getSpaceById: (spaceId: number) => TSpace | null;
  getSpaceBySlug: (spaceTitleSlug: string) => TSpace | null;
  getHostedSpaces: () => Map<number, TSpace> | undefined;
  // null if in no space
  currentSpaceId: number | null;

  joinSpace: (params: {
    id?: TSpace["id"];
    slug?: TSpace["slug"];
  }) => Promise<TSpace | null>;
  leaveSpace: () => void;

  setCurrentSpace: (spaceId: TSpace["id"] | null) => TSpace | null;
  getCurrentSpace: () => TSpace | null;
  getGroupsFromCurrentSpace: () => Array<TGroup>;
  getRoomUrlFromCurrentSpace: () => string | null;
  getMetaNavContent: (spaceId: TSpace["id"]) => TMetaNavContent | null;

  getRoleFromCurrentSpace: () => ESpaceRole | null;

  removeModuleFromSpace: (
    spaceId: TSpace["id"],
    moduleId: TModule["id"]
  ) => void;
  addModuleToSpace: (spaceId: TSpace["id"], moduleId: TModule["id"]) => void;
};

const spaceService = create<TSpaceService>((set, get) => {
  return {
    spaces: new Map<number, TSpace>(),
    currentSpaceId: null,

    init: async (isOnHostPage) => {
      return new Promise(async (resolve) => {
        const spaces = await get().updateSpaces();

        const { updateUser, updateUsers } = userService.getState();

        rehService
          .getState()
          .subscribe("communication", "SERVER_ALL_CLIENTS", ({ clients }) => {
            const { cachedUsers } = userService.getState();
            // Reset currentSpaceId to 0 which is null
            updateUsers([...cachedUsers.keys()], "currentSpaceId", 0);
            updateUsers(clients, "currentSpaceId", get().currentSpaceId);
          });

        rehService
          .getState()
          .subscribe(
            "communication",
            "SERVER_CLIENT_CONNECTED",
            ({ userId }) => {
              updateUser(userId, "currentSpaceId", get().currentSpaceId);
            }
          );

        rehService
          .getState()
          .subscribe("communication", "SERVER_CLIENT_LEFT", ({ userId }) => {
            updateUser(userId, "currentSpaceId", 0);
          });

        const pathToSlug = (path) => path.split("/")[1];

        if (!isOnHostPage) {
          await get()
            .joinSpace({ slug: pathToSlug(Router.router?.asPath) })
            .catch(() => Router.push("/"));
        }

        Router.events.on("routeChangeComplete", async (path) => {
          const slug = pathToSlug(path);
          if (slug === "local") return;

          try {
            await get().joinSpace({ slug });
          } catch (error) {
            debugService
              .getState()
              .logError(
                `spaceService::routeChangeComplete(): Failed to join space = ${error}`
              );
          }
        });

        rehService
          .getState()
          .subscribe(
            "communication",
            "SERVER_CLIENT_NOTIFICATION",
            ({ message, payload }) => {
              notificationService
                .getState()
                .addNotification(
                  <ButtonNotification
                    headline={"Host Message"}
                    text={message}
                    btnLabel={payload.btnLabel || "OK"}
                    icon={{ element: <Megaphone />, width: 38, height: 24 }}
                    onClick={() =>
                      payload.moduleSlug ? Router.push(payload.moduleSlug) : {}
                    }
                  />,
                  {
                    autoRemove: false,
                  }
                );
            }
          );

        resolve(spaces);
      });
    },

    joinSpace: ({ id = 0, slug = "" } = {}) => {
      return new Promise(async (resolve, reject) => {
        const shouldBeInSpace = Boolean(Router.router?.query.space);

        if (!shouldBeInSpace) {
          get().leaveSpace();
          resolve(null);
          return;
        }

        const space = id ? get().getSpaceById(id) : get().getSpaceBySlug(slug);

        // space is deleted or unpublished
        if (!space) {
          reject("Invalid id");
          get().setCurrentSpace(null);
          return;
        }

        //Already connected to the right space
        if (space.id === get().currentSpaceId) {
          resolve(space);
          return;
        }

        //Leave old space if joining new one
        if (get().currentSpaceId && space.id !== get().currentSpaceId) {
          get().leaveSpace();
        }

        //Join new space
        try {
          get().setCurrentSpace(space.id);
          await rehService.getState().connect("communication");
        } catch (e) {
          await Router.push("/");
        }

        resolve(space);
      });
    },

    leaveSpace: () => {
      if (!get().currentSpaceId) return;

      const videoRoomId = videoService.getState().currentRoomId;
      if (videoRoomId) {
        videoService.getState().leave(videoRoomId);
        videoService.getState().onRoomLeft();
      }

      rehService.getState().disconnect("communication");
      get().setCurrentSpace(null);
    },

    updateSpaces: () => {
      return new Promise<TSpaceMap>(async (resolve, reject) => {
        try {
          const spacesArray: Array<TSpace> = await cmsService
            .getState()
            .requestData("getSpaces");

          //Sort spaces by publish date
          // From: https://stackoverflow.com/questions/10123953/how-to-sort-an-object-array-by-date-property
          const sortedSpaces = spacesArray.sort(
            // @ts-ignore
            (a, b) => new Date(a["published_at"]) - new Date(b["published_at"])
          );

          const spaces: TSpaceMap = new Map<number, TSpace>(
            sortedSpaces.map((space) => [space.id, space])
          );

          set({ spaces });

          resolve(spaces);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },
    getCurrentSpace: () => {
      const { spaces, currentSpaceId } = get();
      return currentSpaceId ? spaces.get(currentSpaceId) || null : null;
    },
    setCurrentSpace: (currentSpaceId) => {
      set({ currentSpaceId });
      return get().getCurrentSpace();
    },
    getSpaceById: (spaceId) => {
      return spaceId ? get().spaces.get(spaceId) || null : null;
    },
    getSpaceBySlug: (slug) => {
      slug = slug.startsWith("/") ? slug.substring(1) : slug;

      const spacesValueIterator = get().spaces.values();

      let space = spacesValueIterator.next();

      while (!space.done) {
        if (space.value.slug === slug) return space.value;
        space = spacesValueIterator.next();
      }

      return null;
    },
    getHostedSpaces: () => {
      const hostUser = userService.getState().getOwnUser();

      if (!hostUser) {
        debugService
          .getState()
          .logError("spaceService::getHostedSpaces: couldn't find own user");
        return;
      }

      const hostedSpaces = new Map(get().spaces);
      hostedSpaces.forEach((space, key) => {
        const userInSpace = space.users.find((user) => user.id === hostUser.id);

        // if user is not found in space OR if user is not a host,
        // disregard space
        if (!userInSpace || userInSpace.role !== ESpaceRole.HOST) {
          hostedSpaces.delete(key);
          return;
        }
      });

      return hostedSpaces;
    },
    getMetaNavContent: (spaceId) => {
      const space = get().getSpaceById(spaceId);
      if (!space) return null;

      const metaNavContent = {
        privacyPolicy: space.privacyPolicy,
        termsConditions: space.termsConditions,
        legalNoticeUrl: space.legalNoticeUrl,
        faq: space.faq,
      } as TMetaNavContent;
      return buildValidMetaNavContent(metaNavContent);
    },

    getRoomUrlFromCurrentSpace: () => {
      return get().getCurrentSpace()?.roomUrl || null;
    },
    getGroupsFromCurrentSpace: () => {
      return get().getCurrentSpace()?.groups || [];
    },
    getRoleFromCurrentSpace: () => {
      const ownUser = userService.getState().ownUser;
      if (!ownUser) return null;

      const space = get().getCurrentSpace();
      if (!space) return null;

      const spaceUsers = space.users;
      const spaceUser = spaceUsers.find(({ id }) => id === ownUser.id);
      if (!spaceUser) return null;

      if (!Object.values(ESpaceRole).includes(spaceUser.role)) return null;
      const roleKey = getEnumKeyByValue(ESpaceRole, spaceUser.role);

      return ESpaceRole[roleKey];
    },

    removeModuleFromSpace: async (spaceId, moduleId) => {
      if (!spaceId || !moduleId) return;

      const { spaces } = get();

      if (!spaces) return;

      const space = spaces.get(spaceId);

      if (!space) return;

      space.modules = space.modules.filter((id) => moduleId !== id);
      spaces.set(spaceId, space);

      if (space.defaultModule === moduleId) {
        if (space.modules.length > 0) {
          await hostService
            .getState()
            .updateDefaultModule(space.modules[0])
            .then(() => {
              window.location.reload();
            });
        } else {
          // todo: this does not unassign the default module completely from the space however this is not an issue for now
          // @ts-ignore
          space.defaultModule = undefined;
        }
      }

      set({ spaces: new Map(spaces) });
    },

    addModuleToSpace: (spaceId, moduleId) => {
      if (!spaceId || !moduleId) return;

      const { spaces } = get();

      if (!spaces) return;

      const space = spaces.get(spaceId);

      if (!space) return;

      space.modules.push(moduleId);
      spaces.set(spaceId, space);

      set({ spaces: new Map(spaces) });
    },
  };
});

export default spaceService;
