import styled from "styled-components";

import { Title } from "@/components/Dom/AvatarConfigurator/styles";
import Button from "@/components/Dom/Common/Button";
import FlexAndGrow from "@/components/Dom/Common/FlexAndGrow";
import { BodyContent } from "@/components/Dom/Common/FlexAndGrow/styles";

export const StyledFlexAndGrow = styled(FlexAndGrow)`
  min-height: 100%;

  ${BodyContent} {
    padding: 0;
  }

  ${Title} {
    max-width: initial;
    line-height: 120%;
  }
`;

export const AvatarWrapper = styled.div`
  padding: var(--sidebar-panel-padding);
`;

export const StyledButton = styled(Button)`
  margin: auto;
`;
