import Router from "next/router";
import create from "zustand";

import ConfirmDialog from "@/components/Dom/Common/ConfirmDialog";
import InfoNotification, {
  TInfoNotification,
} from "@/components/Dom/Notifications/InfoNotification";

import mediaOverlayService from "@/services/MediaOverlayService";
import notificationService from "@/services/NotificationService";
import popupOverlayService from "@/services/PopupOverlayService";
import userService from "@/services/UserService";
import localStorage from "@/utils/LocalStorage";
import { buildDefaultSpaceSlug } from "@/utils/Space";

const notifications: TInfoNotification[] = [
  {
    headline: "",
    text:
      "Nice to meet you, my name is Eve and I will introduce you to Virtual Spaces. Please follow me on a quick tour to learn what you can do on our virtual business platform.",
    buttonText: "start",
    imageUrl: "/assets/images/Tutorial-Image-1.jpg",
  },
  {
    headline: "Navigation",
    text:
      "To move around in Virtual Spaces use the arrow keys or W,S,A,D to navigate your avatar. Hold the left mouse button and drag your mouse to look around.",
    subText: "Please walk to the first tutorial zone",
    buttonText: "Next 1/5",
    imageUrl: "/assets/images/Tutorial-Image-2.jpg",
  },
  {
    headline: "Communication",
    text:
      "Great - you made it! There are many ways to communicate with others in Virtual Spaces. You can engage in video calls and text chats with one or many other users.\n",
    subText:
      "Click on my avatar to open the profile and click the call button.",
    buttonText: "Next 2/5",
    imageUrl: "/assets/images/Tutorial-Image-3.jpg",
  },
  {
    headline: "Media Area",
    text:
      "You can enjoy various content types (images, videos, pdfs, livestreams, links, and websites) placed on the media areas throughout Virtual Spaces.",
    subText:
      "Just click on the button below the media area to enjoy in full screen. Close it again to continue.",
    buttonText: "Next 4/5",
    imageUrl: "/assets/images/Tutorial-Image-5.jpg",
  },
  {
    headline: "Now you’re ready",
    text:
      "You have almost finished the tutorial and are ready to enter the space map through the portal ahead of you.",
    subText:
      "Before you move on, let’s celebrate and have a little dance by clicking on the emoji button in the lower right corner and then the music note.",
    buttonText: "Finish 5/5",
    imageUrl: "/assets/images/Tutorial-Image-6.jpg",
  },
];

export enum TOnboardingStep {
  NONE = -3,
  CONFIGURE,
  TRANSITION,

  // Number corresponds to index of the notifications
  GREET,
  MOVE,

  // The following next states can be updated by notification areas
  CALL,
  MEDIA,
  CELEBRATE,

  DONE,
}

type TOnboardingService = {
  currentStep: TOnboardingStep;
  currentNotificationId: string | null;

  showCallWindow: boolean;
  showProfile: boolean;
  finishedOnboarding: boolean;

  finishOnboarding: () => void;

  setStep: (step: TOnboardingStep) => void;
};

const onboardingService = create<TOnboardingService>((set, get) => {
  const setNotification = (newStep: TOnboardingStep) => {
    const previousNotificationId = get().currentNotificationId;
    const notificationData = notifications[newStep];

    if (!notificationData) {
      set({ currentNotificationId: null });
      return;
    }

    if (newStep === TOnboardingStep.GREET) {
      const { ownUser } = userService.getState();
      notificationData.headline = `Hi ${ownUser ? ownUser.firstname : ""}`;
    }

    if (previousNotificationId)
      notificationService.getState().removeNotification(previousNotificationId);

    const handleWorldMapTransition = (notificationId) => {
      notificationService.getState().removeNotification(notificationId);

      if (sessionStorage.getItem("direct")) {
        Router.push(buildDefaultSpaceSlug());
        sessionStorage.removeItem("direct");
        return;
      }
      Router.push("/");
    };

    const handleNotificationClick = (notificationId) => {
      const newOnboardingStep = newStep + 1;

      switch (newOnboardingStep) {
        case TOnboardingStep.DONE: {
          popupOverlayService
            .getState()
            .open(
              <ConfirmDialog
                headline={
                  "You are about to leave this space and will proceed to the space map."
                }
                acceptButtonText={"Ok"}
                showDeclineButton={false}
                onAccept={() => handleWorldMapTransition(notificationId)}
              />
            );
          break;
        } // @ts-ignore
        case TOnboardingStep.CELEBRATE: {
          mediaOverlayService.getState().close();
        }
        // eslint-disable-next-line no-fallthrough
        default: {
          get().setStep(newOnboardingStep);
          break;
        }
      }
    };

    setTimeout(
      () => {
        const notificationId = notificationService
          .getState()
          .addNotification(
            <InfoNotification
              {...notificationData}
              onButtonClick={() => handleNotificationClick(notificationId)}
            />,
            {
              autoRemove: false,
              position: "bottom",
            }
          );
        set({ currentNotificationId: notificationId });
      },
      newStep === TOnboardingStep.GREET ? 2000 : 0
    );
  };

  return {
    currentNotificationId: null,
    currentStep: TOnboardingStep.NONE,

    showCallWindow: false,
    showProfile: false,

    finishedOnboarding: localStorage.get("finishedOnboarding"),

    finishOnboarding: () => {
      set({ finishedOnboarding: true });
      localStorage.set("finishedOnboarding", true);
    },

    setStep: (newStep) => {
      if (get().currentStep === newStep) return;

      set({ currentStep: newStep });

      if (get().currentStep !== TOnboardingStep.CALL)
        set({ showProfile: false, showCallWindow: false });

      if (get().currentStep >= TOnboardingStep.GREET)
        setNotification(get().currentStep);
    },
  };
});

export default onboardingService;
