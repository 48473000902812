import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";
import { BigHeadline } from "@/styles/typography";

export const TRANSITION = "0.35s linear";

export const IconWrapper = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  width: 7.6rem;
  height: 7.6rem;
  background-color: ${colors.orange.hex};
  border-radius: 50%;
  margin-right: 3rem;
  flex-shrink: 0;
  transition: background-color ${TRANSITION};

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => `${props.iconWidth}px`};
    height: ${(props) => `${props.iconHeight}px`};
    z-index: 2;
    fill: ${colors.white.default};
    transition: fill ${TRANSITION};
  }
`;

export const NotificationHeadline = styled(BigHeadline)``;

export const NotificationHoverHeadline = styled(NotificationHeadline)`
  display: none;
`;

export const TextWrapper = styled.div`
  display: grid;
  grid-gap: 1.1rem;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 1.7rem;
  right: 0.8rem;
  width: 0.8rem;
  height: 0.8rem;
  z-index: 2;
  cursor: pointer;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;

  &:before {
    content: "";
    position: absolute;
    top: -0.9rem;
    right: -0.9rem;
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 50%;
  }

  svg {
    path {
      fill: ${colors.orange.hex};
      transition: fill ${TRANSITION};
    }
  }
`;

export const StyledDefaultNotification = styled.div`
  position: relative;
  padding: 2rem;
  display: flex;
  align-items: center;
  border-radius: var(--notification-radius);
  width: fit-content;
  max-width: 36.7rem;
  background-color: ${colors.white.default};
  ${(props) => props.onClick && "cursor: pointer;"};
  transition: background-color ${TRANSITION};

  ${Hover(
    css`
      background-color: ${colors.orange.hex};
      color: ${colors.white.default};

      ${IconWrapper} {
        background-color: ${colors.white.default};

        svg {
          fill: ${colors.orange.hex};
        }
      }

      ${CloseWrapper} {
        svg path {
          fill: ${colors.white.default};
        }
      }

      ${(props) =>
        props.hasHoverHeadline &&
        css`
          ${NotificationHeadline} {
            display: none;
          }
          ${NotificationHoverHeadline} {
            display: block;
          }
        `}
    `
  )}
`;
