import { TStrapiImage } from "@/types/StrapiImage";
import { TBadge } from "@/services/BadgeService";


export type TUserData = {
  id: number;
  position: string;
  department: string;
  linkedinName: string;
  xingName: string;
  interests: Array<{ name: string; }>;
  company: string;
  userId: string;
};

export type TUser = {
  id: number;
  username: string;
  email: string;
  role: any;
  badge: TBadge["id"];
  online: boolean;
  firstname: string;
  lastname: string;
  profileImage: TStrapiImage;

  // This role has no impact on access logic!
  // It is only used for the view layer.
  // type strapi role
  // optional time constraint dates for guest user role in utc iso date time string
  validDate: string | null;
  invalidDate: string | null;

  userData: TUserData;
  currentModuleId: number;
  currentSpaceId: number;
  sessionId: string;
};

export enum TUserRequestInfo {
  NO_USER_FOUND = "No user found!",
  INVALID_ARGUMENT = "Invalid routine argument!",
  DATABASE_PARITY = "User cache has parity with database."
}
