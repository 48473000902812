import axios, { AxiosPromise, Method } from "axios";

const httpClient = {
  sendData: async (
    url: string,
    data: object,
    headers: any | undefined = {},
    method: Method = "POST"
  ): Promise<AxiosPromise> => {
    return axios({
      url,
      data,
      headers,
      method,
    });
  },

  requestData: (url: string, config: Object = {}): Promise<any> => {
    return axios.get(url, config);
  },
};

export default httpClient;
