import {
  AnimationAction,
  AnimationClip,
  AnimationMixer,
  Object3D,
} from "three";

import { TReaction } from "@/components/Scene/Avatar/ReactionController";

// Source: https://easings.net/#easeInOutQuad
function easeInOutQuad(x: number): number {
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

const crossfadeEase = easeInOutQuad;
const crossfadeDuration = 0.4;

type TIdleActions = "Idle" | "IdleTurnLeft" | "IdleTurnRight";
type TTranslationActions =
  | "Walking"
  | "WalkingBackwards"
  | "WalkingTurnLeft"
  | "WalkingTurnRight";
export type TReactionActions =
  | "Emoji_Idea"
  | "Emoji_ThumbsUp"
  | "Emoji_Waving"
  | "Emoji_Dance_ArmsUp"
  | "Emoji_Dance_Clapping"
  | "Emoji_Dance_Twist";

const reactionAnimationNames = [
  "Emoji_Idea",
  "Emoji_ThumbsUp",
  "Emoji_Waving",
  "Emoji_Dance_ArmsUp",
  "Emoji_Dance_Clapping",
  "Emoji_Dance_Twist",
];

const isReaction = (actionName: TActionName): boolean => {
  return actionName.startsWith("Emoji");
};

const isDance = (actionName: TActionName): boolean => {
  return actionName.includes("Dance");
};

const isIdle = (actionName: TActionName): boolean => {
  return actionName === "Idle";
};

export type TAnimInput = {
  forward: boolean;
  backward: boolean;
  left: boolean;
  right: boolean;
} | null;

type TActionName = TIdleActions | TTranslationActions | TReactionActions;

type TAction = {
  action: AnimationAction;
  time: number;
  duration: number;
};

export default class Animator {
  mixer: AnimationMixer;
  actions: Map<TActionName, TAction>;

  curActionName: TActionName;
  frame: number;

  offset: number;
  updateTick: number;

  prevDanceActionIndex: number;
  reactionTimeoutId: null | any;

  constructor() {}

  init(
    root: Object3D,
    clips: Array<AnimationClip>,
    offset: number,
    updateTick: number
  ) {
    this.mixer = new AnimationMixer(root);
    this.actions = new Map<TActionName, TAction>();

    this.frame = Math.round(Math.random() * 3);

    this.offset = offset;
    this.updateTick = updateTick;

    this.prevDanceActionIndex = 0;
    this.reactionTimeoutId = null;

    // load animation actions from gltf clips and set init state
    clips.forEach((clip) => {
      const action = this.mixer.clipAction(clip, root);
      const actionName = clip.name as TActionName;

      const idling = isIdle(actionName);

      action.weight = idling ? 1.0 : 0.0;

      action.play();

      this.actions.set(actionName, {
        action,
        time: idling ? crossfadeDuration : 0.0,
        duration: Math.floor(clip.duration * 1000),
      });
    });

    this.curActionName = "Idle";

    // init with random time offset
    this.mixer.update(this.frame);
  }

  playReaction(reaction: TReaction): number {
    if (this.reactionTimeoutId) clearTimeout(this.reactionTimeoutId);

    const getActionNameFromReaction = (reaction: TReaction) => {
      const reactionLowerCase = reaction.toLowerCase();

      const reactions = reactionAnimationNames.filter((name) =>
        name.toLowerCase().includes(reactionLowerCase)
      ) as Array<TActionName>;

      if (reaction !== "DANCE") return reactions[0];

      // select random dance excluding the previous one
      let danceActionIndex = this.prevDanceActionIndex;

      while (danceActionIndex === this.prevDanceActionIndex)
        danceActionIndex = Math.floor(Math.random() * reactions.length);

      this.prevDanceActionIndex = danceActionIndex;
      return reactions[danceActionIndex];
    };

    this.curActionName = getActionNameFromReaction(reaction);

    // @ts-expect-error
    const { action, duration } = this.actions.get(this.curActionName);
    action.time = 0.0;

    const DANCE_LOOPS: number = 4.0;
    const durationScalar: number = isDance(this.curActionName)
      ? DANCE_LOOPS
      : 1.0;
    const time: number = duration * durationScalar - 250;

    this.reactionTimeoutId = setTimeout(() => {
      if (isReaction(this.curActionName)) this.curActionName = "Idle";
      this.reactionTimeoutId = null;
    }, time);

    return time;
  }

  update(deltaTime: number, preview = false, input: TAnimInput = null) {
    // input control to animationClip
    let actionName: TActionName = "Idle";

    if (!preview && input) {
      if (input.right) actionName = "IdleTurnRight";
      if (input.left) actionName = "IdleTurnLeft";
      if (input.forward) {
        actionName = "Walking";
        if (input.right) actionName = "WalkingTurnRight";
        if (input.left) actionName = "WalkingTurnLeft";
      } else if (input.backward) {
        actionName = "WalkingBackwards";
      }
    }
    if (actionName === "Idle" && isReaction(this.curActionName))
      actionName = this.curActionName;

    this.curActionName = actionName;

    this.actions.forEach((entry, clipName) => {
      const enabled = this.curActionName === clipName;

      if (enabled)
        entry.time = Math.min(crossfadeDuration, entry.time + deltaTime);
      else entry.time = Math.max(0, entry.time - deltaTime);

      entry.action.weight = crossfadeEase(entry.time / crossfadeDuration);
    });

    if ((this.frame + this.offset) % this.updateTick === 0)
      this.mixer.update(deltaTime * this.updateTick);

    this.frame++;
  }
}
