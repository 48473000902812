import create from "zustand";

import { TStrapiImage } from "@/types/StrapiImage";

import assetService from "@/services/AssetService";
import cmsService from "@/services/CmsService";
import { appendSasParams } from "@/utils/Azure";
import { getImageUrl } from "@/utils/Strapi";

import { colors } from "@/styles/colors";

export type TBadge = {
  id: number;
  title: string;
  color: string;
  maskImage: TStrapiImage;
};

type TBadgeService = {
  badges: TBadge[];
  init: () => Promise<boolean>;
  getBadge: (id: TBadge["id"]) => TBadge | undefined;
};

const badgeService = create<TBadgeService>((set, get) => {
  const createBotBadge = (): TBadge => {
    return {
      id: 666,
      title: "Bot",
      color: colors.grey.normal.hex,
      // @ts-ignore
      maskImage: {
        url: "/assets/images/botIconMask.png",
      },
    };
  };
  return {
    badges: [],

    init: () => {
      return new Promise<boolean>(async (resolve, reject) => {
        const badges = await cmsService.getState().requestData("/badges");
        badges.push(createBotBadge());

        const loadMaskImage = async (title, url) => {
          if (!url) return;

          const _url = await appendSasParams(url);

          try {
            await assetService.getState().loadAsset(title, "texture", [_url]);
          } catch (error) {
            reject(error);
          }
        };

        badges.forEach(({ title, maskImage }) => {
          loadMaskImage(title, getImageUrl(maskImage));
        });

        set({ badges });
        resolve(true);
      });
    },

    getBadge: (id) => {
      // Fallback for when no badge is defined
      // this will happen when in the user-controller in strapi the sanitizeUser function is not used
      if (!id) return get().badges.find((badge) => badge.title === "Guest");

      return get().badges.find((badge) => badge.id === id);
    },
  };
});

export default badgeService;
