import styled from "styled-components";

import { StyledAvatarImage } from "@/components/Dom/Common/AvatarImage/styles";

import { colors } from "@/styles/colors";
import { TruncateText } from "@/styles/mixins";
import { BigHeadline, MidHeadline, Text } from "@/styles/typography";

export const StyledVideoNotification = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 3rem;
  border-radius: var(--notification-radius);
  margin-right: 2.5rem;
  background-color: ${colors.white.default};
  width: fit-content;
  min-width: 40rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.callState === "gettingCall" ? "column-reverse" : "column"};
  margin-right: 3rem;
  max-width: 13rem;

  ${(props) =>
    props.callState === "gettingGroupCall" && TruncateText({ maxLines: 3 })}
`;

export const Headline = styled(MidHeadline)`
  font-size: 1.2rem;
`;

export const SubHeadline = styled(Text)`
  font-size: 1.2rem;
  color: ${colors.grey.light.hex};
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-right: -4rem;

  --avatar-size: 6rem;

  ${StyledAvatarImage} {
    margin-right: -1.5rem;

    &:last-child {
      --avatar-size: 6.8rem; //Add the border size to the avatar size
      border: 0.4rem solid ${colors.white.default};
    }
  }
`;

export const ParticipantsNumber = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  display: grid;
  place-items: center;
  margin-right: -1rem;
  border-radius: 50%;
  background-color: ${colors.grey.lighter.hex};
  border: 0.3rem solid ${colors.white.default};
  z-index: 1;
`;

export const NumberText = styled(BigHeadline)`
  margin-left: 0.5rem;
  color: ${colors.grey.text.hex};
`;
