import create from "zustand";

import graphicsService from "@/services/GraphicsService";
import inputService from "@/services/InputService";
import navService from "@/services/NavService";
import { TMediaAreaData } from "@/services/SceneService";
import videoService from "@/services/VideoService";
import { TVideoGrid } from "@/services/VideoService/types";

type TMediaOverlayService = {
  isOpen: boolean;
  mediaAreaData: TMediaAreaData | null;
  mediaElement: any;
  open: (data: TMediaAreaData) => void;
  close: () => void;
};

const mediaOverlayService = create<TMediaOverlayService>((set, get) => {
  return {
    isOpen: false,
    mediaAreaData: null,
    mediaElement: null,

    open: async (mediaAreaData) => {
      const { isOpen, close } = get();
      // if overlay is already open first close it
      if (isOpen) {
        await close();
      }

      graphicsService.setState({ renderScene: false });

      if (mediaAreaData.type === "iframe" || mediaAreaData.type === "pdf") {
        navService.getState().closeAllPanels();
        // when type is iframe force single column grid
        videoService.getState().toggleVideoGrid(TVideoGrid.SMALL);
      }

      inputService.getState().lockInput();
      set({
        isOpen: true,
        mediaAreaData,
      });
    },

    close: async () => {
      const { isOpen, mediaAreaData } = get();
      if (!isOpen || !mediaAreaData) return;

      navService.getState().showNav();
      inputService.getState().enableInput();
      graphicsService.setState({ renderScene: true });

      set({ isOpen: false, mediaAreaData: null, mediaElement: null });
    },
  };
});

export default mediaOverlayService;
