import { ToggleButton } from "@/components/Dom/VideoChat/Common/styles";

import videoService from "@/services/VideoService";

import { colors } from "@/styles/colors";

import Microphone from "~/public/assets/icons/Microphone.svg";

const MicrophoneButton = () => {
  const isMuted = videoService((state) => state.localParticipant.isMuted);

  return (
    <ToggleButton
      icon={<Microphone />}
      iconWidth={10}
      iconHeight={14.5}
      iconColor={isMuted ? colors.white.default : colors.grey.normal.hex}
      backgroundColor={
        isMuted ? colors.red.normal.hex : colors.grey.lightest.hex
      }
      big
      onClick={videoService.getState().toggleMicrophone}
      isActive={!isMuted}
    />
  );
};

export default MicrophoneButton;
