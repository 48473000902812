import { toggleSession } from "@react-three/xr";
import { Vector3 } from "three";
import create from "zustand";

import { playerService } from "../PlayerService";

type TVrTransform = {
  position: Vector3;
  rotation: number;
};

type TVrService = {
  isInVrMode: boolean;
  isImmersiveMode: boolean;
  vrPlaySpaceTransform: TVrTransform;
  xrSession: XRSession | undefined;
  currentDeviceSupportsVr: () => boolean | undefined;
  startVrSession: () => void;
  requestMicrophoneAccess: () => boolean;
  endVrSession: () => void;
};

const vrService = create<TVrService>((set, get) => {
  return {
    isInVrMode: false,
    isImmersiveMode: false,
    vrPlaySpaceTransform: { position: new Vector3(), rotation: 0.0 },
    xrSession: undefined,

    requestMicrophoneAccess: async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        return true;
      } catch (err) {
        return false;
      }
    },

    currentDeviceSupportsVr: () => {
      if (typeof window !== "undefined") {
        const supportsXR =
          window.navigator.xr?.isSessionSupported("immersive-vr");
        const isOculusBrowser = navigator.userAgent.includes("OculusBrowser");

        return (
          (supportsXR && isOculusBrowser) ||
          process.env.NEXT_PUBLIC_VR_DEV_MODE == "true"
        );
      }

      return false;
    },

    startVrSession: async () => {
      const hasMicrophoneAccess = await get().requestMicrophoneAccess();
      if (!hasMicrophoneAccess) {
        //TODO: @Paddy - Add error handling
      }

      const xrSession = await toggleSession("immersive-vr");

      if (xrSession !== undefined) {
        set({ xrSession });
        set({ isInVrMode: true });
        set({ isImmersiveMode: false });
        await playerService.getState().switchToVrTick();
      }
    },

    endVrSession: async () => {
      await toggleSession("immersive-vr");
      set({ isInVrMode: false, isImmersiveMode: false });
    },
  };
});

export default vrService;
