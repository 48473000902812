import {
  Color,
  CustomBlending,
  DoubleSide,
  FrontSide,
  Mesh,
  MeshBasicMaterial,
  OneFactor,
  OneMinusSrcAlphaFactor,
  ShaderMaterial,
  sRGBEncoding,
  Texture,
  TextureLoader,
  Vector3,
  VideoTexture,
} from "three";
import create from "zustand";

import { TStrapiDocument } from "@/types/StrapiDocument";
import { TStrapiImage } from "@/types/StrapiImage";
import { TStrapiVideo } from "@/types/StrapiVideo";

import assetService from "@/services/AssetService";
import debugService from "@/services/DebugService";
import graphicsService from "@/services/GraphicsService";
import { TModule } from "@/services/ModuleService/types";
// @ts-expect-error
import borderFrag from "@/shaders/border.frag";
// @ts-expect-error
import ctaBgFrag from "@/shaders/ctaBg.frag";
// @ts-expect-error
import defaultVert from "@/shaders/default.vert";
// @ts-expect-error
import portalFrag from "@/shaders/portal.frag";
import { appendSasParams } from "@/utils/Azure";
import { Octree } from "@/utils/Collision/Octree";
import { getImageUrl } from "@/utils/Strapi";

// UTILS
const disposeMesh = (mesh: any) => {
  mesh.geometry.dispose();
  mesh.geometry = undefined;
  if (mesh.material.map) {
    mesh.material.map.dispose();
    mesh.material.map = undefined;
  }
  if (mesh.material.lightMap) {
    mesh.material.lightMap.dispose();
    mesh.material.lightMap = undefined;
  }
  mesh.material.dispose();
  mesh.material = undefined;
};

// MODELS
type TSceneVideo = "scene_stream" | "scene_video" | "scene_screen_share";
export type TMediaArea =
  | "image"
  | "video"
  | "pdf"
  | "link"
  | "iframe"
  | TSceneVideo
  | "immersive_video";

export type TMediaAreaData = {
  id: number;
  name: string;
  type: TMediaArea;
  streamUrl: string;
  muted: boolean;
  title?: string;
  enabled: boolean;
  url: string;
  media: TStrapiImage | TStrapiVideo | TStrapiDocument;
  placeholder: TStrapiImage | TStrapiVideo;
  downloadable: boolean;
};

export type TTriggerAreaData = {
  id: number;
  name: string;
  enabled: boolean;
  type: "communication" | "portal" | "notification";
  isActive: boolean;
  isVisible: boolean;
  destinations: Array<TModule["id"]>;
  __component: "trigger-area-entry.trigger-area";
};

export type TTriggerState = {
  isActive: boolean;
  isVisible: boolean;
};

export type TMeshType = "mediaArea";

export type TVideoData = {
  url: string | null;
  type: TSceneVideo;
  muted: boolean;
  meshType: TMeshType;
  meshId: number;
};

type TSceneService = {
  // MEMBERS

  isSceneInitialized: boolean;
  isVideosStarted: boolean;
  isMediaAreaDataValid: (data: TMediaAreaData) => boolean;
  videoStartedCounter: number;

  materialLibrary: Map<string, any>;

  staticMeshes: Array<Mesh>;
  mediaAreas: Array<Mesh>;
  triggerAreas: Array<Mesh>;
  portals: Array<Mesh>;
  triggerBorders: Map<string, Mesh>;
  spawns: Array<Mesh>;

  triggerAreaState: Map<string, TTriggerState>;

  wallCollisionOctree: Octree | null;
  groundCollisionOctree: Octree | null;

  objectVideoData: Array<TVideoData>;

  communicationAreaCta: { name: string; status: boolean };

  // METHODS

  loadScene: (module: TModule) => Promise<any>;
  unloadScene: () => void;
  setInitialized: (initialized: boolean) => void;

  setTriggerAreaState: (
    name: string,
    state: { isActive: boolean | null; isVisible: boolean | null }
  ) => boolean;

  triggerCommunicationAreaCta: (state: {
    name: string;
    status: boolean;
  }) => void;

  loadStaticMesh: (staticMesh: Mesh) => void;
  loadAlphaTestMesh: (alphaTestMesh: Mesh) => void;
  loadMediaArea: (mediaAreaMesh: Mesh, mediaAreaData: TMediaAreaData) => void;
  loadMaterials: () => void;
  loadTriggerArea: (trigger: Mesh, triggerData: TTriggerAreaData) => void;
  loadTriggerBorder: (border: Mesh, triggerData: TTriggerAreaData) => void;
  loadSpawn: (spawn: Mesh) => void;
  loadPortal: (portal: Mesh) => void;

  loadCollisionMesh: (collisionMesh: Mesh) => void;

  loadAudioSource: (mesh: Mesh, audioSourceData: any) => void;

  getStartTransform: (startSpawn: any) => any;

  startVideo: (
    videoData: TVideoData,
    video: HTMLVideoElement | null
  ) => boolean;
  addVideo: (videoData: TVideoData) => boolean;
  removeVideo: (meshId: TVideoData) => boolean;
  hasVideo: (meshId: number) => TVideoData | undefined;
  preloadVideoError: () => void;

  textureLoader: TextureLoader;

  greenscreenVideoUrl: string | null;
};

const sceneService = create<TSceneService>((set, get) => {
  // find mesh by type and id
  // @Eric: used by scene video logic and only supports media areas right now but can be extended in the future
  const findMesh = (meshId: number, meshType: TMeshType): Mesh | null => {
    switch (meshType) {
      case "mediaArea": {
        const mediaArea = get().mediaAreas.find(
          (mediaArea) => mediaArea.id === meshId
        );

        if (!mediaArea) return null;
        return mediaArea;
      }
      default:
        return null;
    }
  };

  return {
    isSceneInitialized: false,
    isVideosStarted: false,
    videoStartedCounter: 0,

    materialLibrary: new Map<string, any>(),
    textureLibrary: new Map<string, Texture>(),

    staticMeshes: new Array<Mesh>(),
    mediaAreas: new Array<Mesh>(),
    triggerAreas: new Array<Mesh>(),
    portals: new Array<Mesh>(),
    triggerBorders: new Map<string, Mesh>(),
    spawns: new Array<Mesh>(),

    triggerAreaState: new Map<
      string,
      { isActive: boolean; isVisible: boolean }
    >(),

    groundCollisionOctree: null,
    wallCollisionOctree: null,

    objectVideoData: new Array<TVideoData>(),

    textureLoader: new TextureLoader(),

    greenscreenVideoUrl: null,
    communicationAreaCta: { name: "", status: false },

    isMediaAreaDataValid: (data) => {
      switch (data.type) {
        case "image":
        case "video":
        case "pdf":
        case "immersive_video":
          return Boolean(data.media && data.placeholder);
        case "iframe":
        case "link":
          return Boolean(data.url && data.placeholder);
        case "scene_screen_share":
          return Boolean(data.placeholder);
        case "scene_stream":
          return Boolean(data.url);
        case "scene_video":
          return Boolean(data.media);
        default:
          return false;
      }
    },

    loadScene: async (module) => {
      const moduleBlueprint = module.blueprint;

      if (!module.canAccess) {
        throw new Error(
          "sceneService::loadScene(): User has no access to module!"
        );
      }

      if (!moduleBlueprint.asset?.id) {
        throw new Error(
          "sceneService::loadScene(): No moduleBlueprint asset id found"
        );
      }

      const startTime = Date.now();

      // load glb with the asset service and keep scene in ram of later reload
      const assetId = moduleBlueprint.asset.id;

      await assetService
        .getState()
        .loadAsset(assetId, "geometry", [moduleBlueprint.asset.url]);

      // preload instance materials for the media areas
      await get().loadMaterials();

      // get scene from the asset service
      const scene = assetService.getState().getAsset(assetId).data.scene;

      let waitForVideosLoaded = false;

      // load meshes based on custom properties defined in blender
      scene.traverse((mesh) => {
        if (mesh.isMesh && mesh.geometry !== undefined) {
          // destructure custom properties from mesh user data

          const {
            mediaAreaName,
            alphaTest,
            triggerAreaName,
            triggerBorderName,
            spawnData,
            collisionType,
            audioSourceName,
            portalIris,
          } = mesh.userData;

          if (mediaAreaName) {
            // find media area data by name key given from custom property
            const mediaAreaData = module.mediaAreas?.find(
              (mediaAsset) => mediaAsset.name === mediaAreaName
            );

            // ToDo: @Eric - Add better error handling for input data - see mediaAreaData.type?.includes(...)

            // display media area if data is found an type matches condition
            if (mediaAreaData !== undefined && mediaAreaData.enabled) {
              if (!get().isMediaAreaDataValid(mediaAreaData)) {
                debugService
                  .getState()
                  .logWarn(
                    `sceneService::loadScene(): Failed to load media area "${mediaAreaData.name}" - missing media!`
                  );
              } else {
                // console.log(
                //   "sceneService::loadScene(): Loading media area =",
                //   mediaAreaData
                // );
                get().loadMediaArea(mesh, mediaAreaData);

                if (
                  mediaAreaData.type === "scene_stream" ||
                  mediaAreaData.type === "scene_video"
                ) {
                  waitForVideosLoaded = true;
                }
              }
            }
          } else if (collisionType) {
            get().loadCollisionMesh(mesh);
          } else if (triggerAreaName) {
            // find trigger area data by name key given from custom property
            const triggerData = module.triggerAreas?.find(
              (triggerArea) => triggerArea.name === triggerAreaName
            );
            // display trigger area if it is enabled in cms
            if (triggerData !== undefined && triggerData.enabled) {
              get().loadTriggerArea(mesh, triggerData);
            }
          } else if (triggerBorderName) {
            // ToDo: @Eric - Remove trigger border code!
            /*
            // find trigger area data by name key given from custom property
            const triggerData: TTriggerData = moduleBlueprint.triggerAreas.find(
              (triggerArea) => triggerArea.name === triggerBorderName
            );
            // display trigger area if it is enabled in cms
            if (triggerData !== undefined && triggerData.enabled) {
              get().loadTriggerBorder(mesh, triggerData);
            }
             */
          } else if (spawnData) {
            get().loadSpawn(mesh);
          } else if (audioSourceName) {
            const audioSourceData = moduleBlueprint.audioSources.find(
              (audioSource) => audioSource.name === audioSourceName
            );
            if (audioSourceData !== null && audioSourceData.audio !== null) {
              get().loadAudioSource(mesh, audioSourceData);
            }
          } else if (portalIris) {
            get().loadPortal(mesh);
          } else if (alphaTest !== undefined) {
            get().loadAlphaTestMesh(mesh);
          } else {
            get().loadStaticMesh(mesh);
          }
        }
      });

      if (!waitForVideosLoaded) set({ isVideosStarted: true });

      return new Promise((resolve, reject) => {
        if (
          get().staticMeshes.length > 0 &&
          get().spawns.find((spawn) => spawn.userData.spawnData === "start")
        ) {
          // eslint-disable-next-line no-console
          console.log(
            `loaded scene in ${(Date.now() - startTime) * 0.001} seconds`
          );
          resolve(true);
          return;
        } else {
          reject(
            "locationService::processAsset(): Failed to resolve process asset promise: asset null!"
          );
          return;
        }
      });
    },
    unloadScene: () => {
      get().materialLibrary.forEach((material, name) => {
        material.dispose();
        material = null;
      });
      get().materialLibrary.clear();

      get().triggerBorders.forEach((value, key) => {
        disposeMesh(value);
      });
      get().triggerBorders.clear();

      get().staticMeshes.forEach((mesh) => disposeMesh(mesh));
      get().mediaAreas.forEach((mediaArea) => disposeMesh(mediaArea));
      get().triggerAreas.forEach((trigger) => disposeMesh(trigger));
      get().portals.forEach((portal) => disposeMesh(portal));
      get().spawns.forEach((spawn) => disposeMesh(spawn));
      get().triggerAreaState.clear();

      set({
        videoStartedCounter: 0,
        isVideosStarted: false,
        staticMeshes: [],
        groundCollisionOctree: null,
        wallCollisionOctree: null,
        spawns: [],
        mediaAreas: [],
        triggerAreas: [],
        portals: [],
        objectVideoData: [],
        greenscreenVideoUrl: null,
      });

      get().setInitialized(false);
    },

    setInitialized: (initialized) => {
      graphicsService.getState().setRenderScene(initialized);
      set({ isSceneInitialized: initialized });
    },

    loadStaticMesh: (staticMesh) => {
      const instancedStaticMesh: Mesh = staticMesh.clone();

      const importedMaterial: any = staticMesh.material;

      const materialLibraryKey = importedMaterial.emissiveMap
        ? importedMaterial.name
        : importedMaterial.color.getHexString();

      let material = get().materialLibrary.get(materialLibraryKey);

      if (!material) {
        material = new MeshBasicMaterial({
          precision: "lowp",
        });

        if (importedMaterial.map) material.map = importedMaterial.map;
        else material.color = importedMaterial.color;

        if (importedMaterial.emissiveMap) {
          material.lightMap = importedMaterial.emissiveMap;
          const uvChannelUsedForLightmap = 1;
          material.lightMap.channel = uvChannelUsedForLightmap;
        }

        material.side = FrontSide;

        get().materialLibrary.set(materialLibraryKey, material);
      }

      instancedStaticMesh.material = material;

      instancedStaticMesh.matrixAutoUpdate = false;

      get().staticMeshes.push(instancedStaticMesh);
    },
    loadAlphaTestMesh: (alphaTestMesh: Mesh) => {
      const instancedAlphaTestMesh: Mesh = alphaTestMesh.clone();
      const importedMaterial: any = alphaTestMesh.material;

      const materialLibraryKey =
        alphaTestMesh.userData.alphaTest === "leaf"
          ? importedMaterial.emissiveMap.id
          : importedMaterial.map.id;

      let material = get().materialLibrary.get(materialLibraryKey);

      if (!material) {
        material = new MeshBasicMaterial({
          precision: "lowp",
          map: importedMaterial.map,
          color: importedMaterial.color,
          lightMap: importedMaterial.emissiveMap,
          side: DoubleSide,
          alphaTest: 0.9,
        });

        get().materialLibrary.set(materialLibraryKey, material);
      }

      instancedAlphaTestMesh.material = material;

      instancedAlphaTestMesh.matrixAutoUpdate = false;

      get().staticMeshes.push(instancedAlphaTestMesh);
    },

    triggerCommunicationAreaCta: (state) => {
      return set({ communicationAreaCta: state });
    },

    setTriggerAreaState: (
      name,
      state = { isActive: null, isVisible: null }
    ) => {
      if (!get().triggerAreaState.has(name)) return false;
      const { isActive, isVisible } = get().triggerAreaState.get(
        name
      ) as TTriggerState;

      const newTriggerAreaState = new Map(get().triggerAreaState);

      newTriggerAreaState.set(name, {
        isActive: state.isActive !== null ? state.isActive : isActive,
        isVisible: state.isVisible !== null ? state.isVisible : isVisible,
      });

      set({ triggerAreaState: newTriggerAreaState });
      return true;
    },
    loadTriggerArea: (trigger, triggerData) => {
      const instancedTrigger: Mesh = trigger.clone();

      const material: MeshBasicMaterial = new MeshBasicMaterial({
        color: 0x00ff00,
        precision: "lowp",
      });

      instancedTrigger.material = material;

      instancedTrigger.name = `trigger_${triggerData.name}`;

      instancedTrigger.userData.data = triggerData;

      get().triggerAreas.push(instancedTrigger);
      get().triggerAreaState.set(triggerData.name, {
        isActive: false,
        isVisible: true,
      });
    },
    loadTriggerBorder: (border, triggerData) => {
      const instancedBorder: Mesh = border.clone();

      const colorBorder: Color = new Color(0x22992e);
      const colorBorderArray: Array<number> = colorBorder.toArray();

      instancedBorder.material = new ShaderMaterial({
        uniforms: {
          u_color: {
            value: {
              x: colorBorderArray[0],
              y: colorBorderArray[1],
              z: colorBorderArray[2],
            },
          },
          u_opacity: { value: 0.0 },
        },
        vertexShader: defaultVert,
        fragmentShader: borderFrag,
        side: DoubleSide,
        precision: "lowp",
        transparent: true,
      });

      instancedBorder.name = `border_${triggerData.name}`;

      get().triggerBorders.set(triggerData.name, instancedBorder);
    },

    loadSpawn: (spawn) => {
      const instancedSpawn: Mesh = spawn.clone();

      const material: MeshBasicMaterial = new MeshBasicMaterial({
        precision: "lowp",
        color: 0x0000ff,
      });
      material.wireframe = true;

      instancedSpawn.material = material;

      get().spawns.push(instancedSpawn);
    },

    loadMaterials: async () => {
      const { getAsset } = assetService.getState();

      let material: any;
      let texture: Texture;

      const colorIconInactive: Color = new Color(0xd04a02);

      const colorIconActive: Color = new Color(0xeb8c00);

      texture = getAsset("iconCamera").data as Texture;
      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });

      get().materialLibrary.set("iconCameraInactive", material);

      material = material.clone();
      material.color = colorIconActive;
      get().materialLibrary.set("iconCameraActive", material);

      texture = getAsset("iconCameraSimple").data as Texture;
      material = new MeshBasicMaterial({
        alphaTest: 0.1,
        map: texture,
      });
      get().materialLibrary.set("iconCameraSimple", material);

      texture = getAsset("iconEnlarge").data as Texture;

      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });

      get().materialLibrary.set("iconEnlargeInactive", material);

      material = material.clone();
      material.color = colorIconActive;
      get().materialLibrary.set("iconEnlargeActive", material);

      texture = getAsset("iconStream").data as Texture;

      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });

      get().materialLibrary.set("iconStreamInactive", material);

      material = material.clone();
      material.color = colorIconActive;
      get().materialLibrary.set("iconStreamActive", material);

      texture = getAsset("iconVideo").data as Texture;

      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });

      get().materialLibrary.set("iconVideoInactive", material);
      get().materialLibrary.set("iconImmersivevideoInactive", material);

      material = material.clone();
      material.color = colorIconActive;
      get().materialLibrary.set("iconVideoActive", material);
      get().materialLibrary.set("iconImmersivevideoActive", material);

      texture = getAsset("iconImage").data as Texture;

      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });

      get().materialLibrary.set("iconImageInactive", material);

      material = material.clone();
      material.color = colorIconActive;
      get().materialLibrary.set("iconImageActive", material);

      texture = getAsset("iconPdf").data as Texture;

      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });

      get().materialLibrary.set("iconPdfInactive", material);

      material = material.clone();
      material.color = colorIconActive;
      get().materialLibrary.set("iconPdfActive", material);

      texture = getAsset("iconLink").data as Texture;

      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });

      get().materialLibrary.set("iconLinkInactive", material);

      material = material.clone();
      material.color = colorIconActive;
      get().materialLibrary.set("iconLinkActive", material);

      texture = getAsset("iconIframe").data as Texture;

      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });

      get().materialLibrary.set("iconIframeInactive", material);

      material = material.clone();
      material.color = colorIconActive;
      get().materialLibrary.set("iconIframeActive", material);

      texture = getAsset("iconScreenshare").data as Texture;

      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });

      get().materialLibrary.set("iconScreenshareInactive", material);

      material = material.clone();
      material.color = colorIconActive;
      get().materialLibrary.set("iconScreenshareActive", material);

      texture = getAsset("iconQuest").data as Texture;

      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });

      get().materialLibrary.set("iconQuestInactive", material);

      material = material.clone();
      material.color = colorIconActive;
      get().materialLibrary.set("iconQuestActive", material);

      texture = getAsset("maPlaceholderFallback").data as Texture;
      texture.flipY = false;

      material = new MeshBasicMaterial({
        map: texture,
      });

      get().materialLibrary.set("maPlaceholderFallback", material);

      // Microphone textures

      texture = getAsset("iconMicrophone").data as Texture;

      material = new MeshBasicMaterial({
        transparent: true,
        map: texture,
        color: colorIconInactive,
      });
      get().materialLibrary.set("iconMicrophone", material);

      material = material.clone();
      material.color = colorIconActive;
      material.map = getAsset("iconMicrophoneMuted").data as Texture;
      get().materialLibrary.set("iconMicrophoneMuted", material);

      texture = getAsset("iconMicrophoneOn").data as Texture;
      material = new MeshBasicMaterial({
        map: texture,
        transparent: true,
        alphaTest: 0.3,
        blending: CustomBlending,
        blendSrc: OneFactor,
        blendDst: OneMinusSrcAlphaFactor,
      });
      get().materialLibrary.set("iconMicrophoneOn", material);

      texture = getAsset("iconMicrophoneOff").data as Texture;
      material = new MeshBasicMaterial({
        map: texture,
        transparent: true,
        alphaTest: 0.3,
        blending: CustomBlending,
        blendSrc: OneFactor,
        blendDst: OneMinusSrcAlphaFactor,
      });
      get().materialLibrary.set("iconMicrophoneOff", material);

      // Hand textures

      texture = getAsset("iconHand").data as Texture;
      material = new MeshBasicMaterial({
        map: texture,
        transparent: true,
        alphaTest: 0.3,
        blending: CustomBlending,
        blendSrc: OneFactor,
        blendDst: OneMinusSrcAlphaFactor,
      });
      get().materialLibrary.set("iconHand", material);

      texture = getAsset("iconHandActive").data as Texture;
      material = new MeshBasicMaterial({
        map: texture,
        transparent: true,
        alphaTest: 0.3,
        blending: CustomBlending,
        blendSrc: OneFactor,
        blendDst: OneMinusSrcAlphaFactor,
      });
      get().materialLibrary.set("iconHandActive", material);

      // Call textures

      texture = getAsset("iconCallEnd").data as Texture;
      material = new MeshBasicMaterial({
        map: texture,
        transparent: true,
        alphaTest: 0.1,
        blending: CustomBlending,
        blendSrc: OneFactor,
        blendDst: OneMinusSrcAlphaFactor,
      });
      get().materialLibrary.set("iconCallEnd", material);

      // Minify texture

      texture = getAsset("iconMinify").data as Texture;

      material = new MeshBasicMaterial({
        alphaTest: 0.1,
        map: texture,
        color: colorIconInactive,
      });
      get().materialLibrary.set("iconMinify", material);

      // Arrow texture

      texture = getAsset("iconArrow").data as Texture;

      material = new MeshBasicMaterial({
        alphaTest: 0.1,
        map: texture,
        color: colorIconInactive,
      });
      get().materialLibrary.set("iconArrow", material);

      // Loading indicator texture

      texture = getAsset("iconLoading").data as Texture;

      material = new MeshBasicMaterial({
        alphaTest: 0.1,
        map: texture,
        color: colorIconInactive,
      });
      get().materialLibrary.set("iconLoading", material);

      // Loading indicator texture end

      material = new ShaderMaterial({
        uniforms: {
          u_size: { value: { x: 0.525, y: 0.15 } },
          u_opacity: { value: 1.0 },
        },
        vertexShader: defaultVert,
        fragmentShader: ctaBgFrag,
        precision: "lowp",
        transparent: false,
      });

      get().materialLibrary.set("maBg", material);
    },
    loadMediaArea: async (mediaAreaMesh, mediaAreaData) => {
      const instancedMediaArea: Mesh = mediaAreaMesh.clone();

      instancedMediaArea.userData = mediaAreaData;

      let material;

      // ToDo(Eric) Use hash instead of url
      if (instancedMediaArea.userData.placeholder)
        material = get().materialLibrary.get(
          instancedMediaArea.userData.placeholder.url
        );
      else material = get().materialLibrary.get("maPlaceholderFallback");

      if (!material) {
        material = new MeshBasicMaterial({
          precision: "mediump",
        });

        const url: string = await appendSasParams(
          getImageUrl(instancedMediaArea.userData.placeholder, "medium")
        );

        const texture: Texture = get().textureLoader.load(url);

        texture.encoding = sRGBEncoding;
        texture.flipY = false;

        material = new MeshBasicMaterial({
          precision: "mediump",
          map: texture,
          side: DoubleSide,
        });

        // ToDo(Eric) Use hash instead of url as key?
        get().materialLibrary.set(
          instancedMediaArea.userData.placeholder.url,
          material
        );
      }

      instancedMediaArea.material = material;

      switch (instancedMediaArea.userData.type as TMediaArea) {
        case "scene_stream": {
          const videoData: TVideoData = {
            url: mediaAreaData.url,
            //@ts-expect-error
            type: mediaAreaData.type,
            muted: mediaAreaData.muted,
            meshId: instancedMediaArea.id,
            meshType: "mediaArea",
          };

          get().addVideo(videoData);

          // instancedMediaArea.visible = false;
          break;
        }
        case "scene_video": {
          const videoData: TVideoData = {
            url: mediaAreaData.media.url,
            //@ts-expect-error
            type: mediaAreaData.type,
            muted: mediaAreaData.muted,
            meshId: instancedMediaArea.id,
            meshType: "mediaArea",
          };

          get().addVideo(videoData);
          break;
        }
        default:
          break;
      }

      get().mediaAreas.push(instancedMediaArea);
    },
    loadPortal: (portal) => {
      const instancedPortal: Mesh = portal.clone();

      let material = get().materialLibrary.get("portal");

      if (!material) {
        material = new ShaderMaterial({
          uniforms: {
            time: { value: 0.0 },
            colorIntensity: { value: 1.2 },
            ringThinness: { value: 7.0 }, // higher value means thinner rings
            ringRotationSpeed: { value: 1.4 }, // higher value means faster rotation
            ringFadeAmount: { value: 12.0 }, // higher value means faster rotation
            //portal color orange: #D04A02
            portalColor: { value: new Vector3(0.8156, 0.2901, 0.0078) },
          },
          vertexShader: defaultVert,
          fragmentShader: portalFrag,
          precision: "lowp",
          side: DoubleSide,
          transparent: true,
        });

        get().materialLibrary.set("portal", material);
      }

      instancedPortal.material = material;

      instancedPortal.matrixAutoUpdate = false;
      get().portals.push(instancedPortal);
    },
    loadCollisionMesh: (collisionMesh) => {
      switch (collisionMesh.userData.collisionType) {
        case "ground":
          set({
            groundCollisionOctree: new Octree(collisionMesh.clone() as Mesh),
          });
          break;
        case "wall":
          set({
            wallCollisionOctree: new Octree(collisionMesh.clone() as Mesh),
          });
          break;
        default:
          break;
      }
    },

    loadAudioSource: (audioSourceMesh, audioSourceData) => {
      // ToDo: Play audio with service.
    },

    getStartTransform: (startSpawn) => {
      const startTransform = {
        position: new Vector3(0.0, 0.0, 0.0),
        rotation: 0.0,
      };

      const startPosition = new Vector3(0.0, 0.0, 0.0);

      if (startSpawn) {
        startPosition.set(
          startSpawn.position.x,
          startSpawn.position.y,
          startSpawn.position.z
        );
        const startOffset = new Vector3(
          startSpawn.offset.x,
          0.0,
          startSpawn.offset.y
        );
        startOffset.setX(
          Math.cos(Math.random() * Math.PI) *
          startOffset.x *
          (Math.random() + 0.5) *
          0.6
        );
        startOffset.setZ(
          Math.cos(Math.random() * Math.PI) *
          startOffset.z *
          (Math.random() + 0.5) *
          0.6
        );
        startPosition.add(startOffset);
      } else
        throw new Error(
          `sceneService::getStartTransform(): Error invalid start spawn object argument provided!`
        );

      startTransform.position.copy(startPosition);
      startTransform.rotation = startSpawn.rotation;

      return startTransform;
    },

    addVideo: (videoData) => {
      set((state) => ({
        objectVideoData: [...state.objectVideoData, videoData],
      }));
      return true;
    },
    startVideo: (videoData, video) => {
      if (!video || !videoData) return false;

      const mesh: Mesh | null = findMesh(videoData.meshId, videoData.meshType);

      if (!mesh) return false;

      mesh.userData.rollbackMaterial = mesh.material;

      const videoTexture = new VideoTexture(video);
      videoTexture.encoding = sRGBEncoding;
      videoTexture.flipY = false;

      const videoMaterial: MeshBasicMaterial = new MeshBasicMaterial({
        map: videoTexture,
      });

      mesh.material = videoMaterial;

      /*
      // ToDo(Eric) Handle this at a central place where also the stream global audio can be played
      // This is a workaround to start the video with audio even if the user has not interacted with the dom before
      // It will be called once even if the user interacted with the dom before ... in the future we should handle the variable in a central space
      const unmuteVideo = () => {
        video.muted = false;
        document.removeEventListener("click", unmuteVideo);
      };

      if (!videoData.muted) document.addEventListener("click", unmuteVideo);
      */

      video.play();

      set((state) => ({
        videoStartedCounter: state.videoStartedCounter + 1,
      }));

      if (get().videoStartedCounter === get().objectVideoData.length) {
        const onVideoReady = () => {
          set({ isVideosStarted: true });
          video.removeEventListener("canplay", onVideoReady);
          video.removeEventListener("error", onVideoReady);
        };

        video.addEventListener("canplay", onVideoReady);
        video.addEventListener("error", onVideoReady);
      }

      return true;
    },
    preloadVideoError: () => {
      set((state) => ({
        videoStartedCounter: state.videoStartedCounter + 1,
      }));

      if (get().videoStartedCounter === get().objectVideoData.length) {
        set({ isVideosStarted: true });
      }
    },
    removeVideo: (videoData) => {
      const mesh: Mesh | null = findMesh(videoData.meshId, videoData.meshType);
      if (!mesh) return false;

      const material: MeshBasicMaterial | null = mesh.material as MeshBasicMaterial;
      if (!material) return false;

      if (material.map) {
        material.map.dispose();
        material.map = null;
      }

      material.dispose();
      mesh.material = mesh.userData.rollbackMaterial;
      delete mesh.userData.rollbackMaterial;

      const objectVideoData = get().objectVideoData.filter(
        (curVideoData) => curVideoData !== videoData
      );
      set({ objectVideoData });

      return true;
    },
    hasVideo: (meshId) => {
      return get().objectVideoData.find(
        (videoData) => videoData.meshId === meshId
      );
    },
  };
});

export default sceneService;
