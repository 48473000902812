import navService from "@/services/NavService";
import videoService from "@/services/VideoService";

import { colors } from "@/styles/colors";

import { StyledCallEndButton } from "./styles";

import Phone from "~/public/assets/icons/Phone.svg";

const CallEndButton = () => {
  const { currentRoomId, callState } = videoService((state) => ({
    currentRoomId: state.currentRoomId,
    callState: state.callState,
  }));

  const onClick = async () => {
    if (!currentRoomId) return;

    if (callState.includes("calling")) {
      videoService.getState().cancel(currentRoomId);
    } else if (callState.includes("getting")) {
      videoService.getState().decline(currentRoomId);
    } else {
      videoService.getState().leave(currentRoomId);

      if (videoService.getState().isFullscreen) {
        videoService.getState().closeFullscreen();
        navService.getState().showNav();
      }
    }
  };

  return (
    <StyledCallEndButton
      icon={<Phone />}
      iconWidth={15}
      iconHeight={15}
      big={true}
      backgroundColor={colors.red.normal.hex}
      onClick={onClick}
    />
  );
};

export default CallEndButton;
