import { TStrapiImage } from "@/types/StrapiImage";

export const getImageUrl = (
  image: TStrapiImage,
  format?: "large" | "medium" | "small" | "thumbnail" | null
) => {
  if (!image) return null;

  if (!format || !image.formats || (format && !image.formats[`${format}`]))
    return image.url;

  return image.formats[`${format}`].url;
};
