import {
  Color,
  Material,
  Mesh,
  MeshBasicMaterial,
  MeshLambertMaterial,
  Object3D,
  Texture,
  Vector3,
} from "three";

import ParticleSystem from "@/components/Scene/Common/ParticleSystem";

import assetService from "@/services/AssetService";
import avatarConfigService from "@/services/AvatarConfigService";
import {
  hairColors,
  skinColors,
} from "@/services/AvatarConfigService/customizationScheme";
import { TAvatarConfig } from "@/services/PlayerService";
import { TUser } from "@/services/UserService/types";

const smoothFalloff = (x: number) => {
  return -1.0 * Math.pow(x, 2) + 1;
};

export class Configurator {
  materials = new Map<string, Material>();
  meshes: Array<Mesh>;
  isInit: boolean;
  particleSystem: ParticleSystem;

  constructor() { }

  init(root: Object3D, userId: TUser["id"]) {
    this.particleSystem = new ParticleSystem(
      "puffAtlas",
      "RADIAL",
      1.75,
      10,
      1.2,
      0.55,
      smoothFalloff
    );

    this.loadMaterials();
    this.loadMeshes(root);

    avatarConfigService.getState().loadConfig(userId);
  }

  loadMaterials() {
    this.materials = new Map<string, Material>();

    this.materials.set(
      "Fallback",
      new MeshBasicMaterial({
        precision: "lowp",
        name: "Fallback",
        color: new Color(0xffffff),
      })
    );
    this.materials.set(
      "Skin",
      new MeshLambertMaterial({
        precision: "lowp",
        name: "Skin",
      })
    );
    this.materials.set(
      "Hair",
      new MeshLambertMaterial({
        precision: "lowp",
        name: "Hair",
      })
    );
    this.materials.set(
      "Outfit",
      new MeshLambertMaterial({
        precision: "mediump",
        name: "Outfit",
      })
    );
  }
  loadMeshes(root) {
    this.meshes = new Array<Mesh>();

    const fallbackMaterial = this.materials.get("Fallback");

    const center = new Vector3(0, 0, -90);

    // ToDo: Filter out materials.
    root.traverse((mesh) => {
      if (mesh.isMesh) {
        // TMP Frustrum Culling fix => Is there a method to already receive valid data?
        mesh.geometry.boundingSphere.set(center, 180);
        mesh.material = fallbackMaterial;
        this.meshes.push(mesh);
      }
    });
  }

  update(config: TAvatarConfig) {
    const { meshes, materials } = this;

    const show = new Array<string>();
    show.push(config.skin);
    show.push(config.outfit);

    show.push(config.hairCut);
    // TMP fix for hat hair
    if (config.hairCut.includes("Hat")) show.push("Hair_0_Hat_Hair");

    if (config.extras && config.extras.length) {
      config.extras.forEach((extra) => {
        show.push(extra);
      });
    }

    const skinColor = skinColors[config.skinColor];
    const hairColor = hairColors[config.hairColor];

    const { getAsset } = assetService.getState();

    // customizations
    meshes.forEach((mesh: Mesh) => {
      mesh.visible = show.findIndex((name) => mesh.name === name) !== -1;

      if (!mesh.visible) return;
      const meshName = mesh.name;

      // ToDo: Cache colors
      // Handle meshes in map to improve query time?

      if (meshName.startsWith("Skin")) {
        mesh.material = materials.get("Skin") as Material;
        (mesh.material as MeshLambertMaterial).color = new Color(
          skinColor
        ).convertLinearToSRGB();
        // TMP fix for hat hair
      } else if (meshName.startsWith("Hair") && !meshName.endsWith("Fabric")) {
        mesh.material = materials.get("Hair") as Material;
        (mesh.material as MeshLambertMaterial).color = new Color(
          hairColor
        ).convertLinearToSRGB();
      } else if (meshName.startsWith("Outfit")) {
        mesh.material = materials.get("Outfit") as Material;
        (mesh.material as MeshLambertMaterial).map = getAsset(
          config.outfitStyle
        ).data as Texture;
      } else mesh.material = materials.get("Outfit") as Material;
    });

    if (!this.isInit) this.isInit = true;
  }

  setDebug = (enabled: boolean): void => {
    this.materials.forEach((material, key) => {
      (material as MeshLambertMaterial).wireframe = enabled;
    });
  };
}
