/**
 * Fn to get CSS variables into JS
 */

export enum CSSVariableReturnTypes {
  Original, // returns the original value without formatting, eg. 30rem
  Numeral, // return value as number, eg. 30
}

export const getCSSVariable = (
  selector: string,
  returnType: CSSVariableReturnTypes
): number | string => {
  const root = getComputedStyle(document.documentElement);
  const value = root.getPropertyValue(selector);
  if (returnType === CSSVariableReturnTypes.Original) {
    return value;
  } else if (returnType === CSSVariableReturnTypes.Numeral) {
    return parseFloat(value);
  } else {
    return value;
  }
};

export const pathToSlug = (path) => path.split("/")[1];

export const getEnumKeyByValue = (enumObject, value) => {
  const valueIndex = Object.values(enumObject).indexOf(value);
  const key = Object.keys(enumObject)[valueIndex];
  return key;
};

export const extractTokenFromUrlBar = (): string => {
  let token: string = window.location.hash.substr(1);
  const tokenPositionOfComma = token.indexOf(",");
  if (tokenPositionOfComma >= 0) {
    token = token.substr(0, token.indexOf(","));
  }
  return token;
};
