import React, { FC, HTMLAttributes, useEffect, useRef, useState } from "react";

import {
  FlexAndGrowBody,
  BodyContent,
  FlexAndGrowFooter,
  FlexAndGrowHeader,
  StyledFlexAndGrow,
  Gradient,
} from "./styles";

type TFlexAndGrow = HTMLAttributes<HTMLDivElement>;

interface IProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  onScrollBottom?: Function;
  updateParameters?: Array<unknown>;
  scrollToBottomOnChange?: boolean;
  gradients?: boolean;
}

const FlexAndGrow: FC<IProps & TFlexAndGrow> = ({
  header,
  body,
  footer,
  onScrollBottom,
  updateParameters,
  scrollToBottomOnChange = false,
  gradients = true,
  ...others
}) => {
  const bodyContentRef = useRef<HTMLDivElement>(null);

  const [showTopGradient, setShowTopGradient] = useState(false);
  const [showBottomGradient, setShowBottomGradient] = useState(true);

  const onScroll = () => {
    if (!bodyContentRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = bodyContentRef.current;

    const scrollPosition = scrollHeight - clientHeight;
    const scrollPercent = scrollTop / scrollPosition;

    setShowTopGradient(() => {
      if (!scrollPercent) return false;
      if (scrollPercent === 0) return false;
      return true;
    });
    setShowBottomGradient(scrollPercent !== 1);

    if (onScrollBottom && scrollPercent > 0.95) {
      onScrollBottom();
    }
  };

  useEffect(() => {
    if (!gradients) return;

    window.addEventListener("resize", onScroll);
    return () => {
      window.removeEventListener("resize", onScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (gradients) onScroll();

      if (!scrollToBottomOnChange || !bodyContentRef.current) return;

      bodyContentRef.current.scrollTo({
        top: bodyContentRef.current.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 0);
  }, updateParameters);

  return (
    <StyledFlexAndGrow {...others}>
      {header && <FlexAndGrowHeader>{header}</FlexAndGrowHeader>}

      {body && (
        <FlexAndGrowBody>
          {gradients && <Gradient visible={showTopGradient} />}
          <BodyContent ref={bodyContentRef} onScroll={onScroll}>
            {body}
          </BodyContent>
          {gradients && <Gradient visible={showBottomGradient} />}
        </FlexAndGrowBody>
      )}

      {footer && <FlexAndGrowFooter>{footer}</FlexAndGrowFooter>}
    </StyledFlexAndGrow>
  );
};

export default FlexAndGrow;
