import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover, MQ } from "@/styles/mixins";

export const ButtonStyles = css`
  background-color: ${colors.orange.hex};
  border-radius: var(--button-radius);
  color: ${colors.white.default};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.big ? 5 : 4)}rem;
  flex-shrink: 0;
  font-size: ${(props) => (props.big ? 1.5 : 1.2)}rem;
  font-weight: 700;
  place-items: center;
  position: relative;
  transition: background-color 0.3s ${ease.inOutCubic},
    color 0.3s ${ease.inOutCubic};
  text-transform: uppercase;
  width: 100%;

  ${(props) =>
    !props.fullWidth &&
    css`
      padding-left: 2.8rem;
      padding-right: 2.8rem;
      max-width: 17rem;
    `};

  ${(props) =>
    props.outline &&
    css`
      background-color: ${colors.white.default};
      border: 0.1rem solid ${colors.orange.hex};
      color: ${colors.orange.hex};
    `}

  ${Hover(`
      background-color: ${colors.tangerine.normal.hex};
      border-color: ${colors.tangerine.normal.hex};
      color: ${colors.white.default}
    `)}

  :disabled {
    background-color: ${colors.grey.lighter.hex};
    cursor: default;
    pointer-events: none;

    ${(props) =>
      props.outline &&
      css`
        background-color: ${colors.white.default};
        color: ${colors.grey.light.hex};
        border: 0.1rem solid ${colors.grey.light.hex};
      `}
  }

  ${MQ.LG`
    ${(props) =>
      !props.fullWidth &&
      css`
        max-width: 22.5rem;
      `}
  `}
`;

export const StyledButton = styled.button`
  ${ButtonStyles}

  ${(props) => props.extraStyle};
`;

export const StyledButtonIcon = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  display: flex;
`;
