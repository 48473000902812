import React from "react";
import { v4 as uuidv4 } from "uuid";
import create from "zustand";

import videoService from "@/services/VideoService";

export type TNotification = {
  id: string;
  element: JSX.Element;
  position: "top" | "right" | "bottom";
  isVisible: boolean;
};

type TNotificationOptions = {
  position?: TNotification["position"];
  autoRemove?: boolean;
  autoRemoveTimeout?: number;
  delay?: number;
};

type TNotificationService = {
  notifications: TNotification[];

  addNotification: (
    element: JSX.Element,
    options?: TNotificationOptions
  ) => string;
  removeNotification: (id: string) => void;
};

const notificationService = create<TNotificationService>((set, get) => {
  return {
    notifications: [],

    addNotification: (element, options = {}) => {
      const {
        position = "top",
        autoRemove = true,
        autoRemoveTimeout = 3000,
        delay = 0,
      } = options;

      const id = uuidv4();

      const newNotification: TNotification = {
        id,
        element: React.cloneElement(element, { id }),
        position:
          videoService.getState().currentRoom && position === "right"
            ? "top"
            : position,
        isVisible: true,
      };

      const handleNotification = () => {
        set((state) => ({
          notifications: [newNotification, ...state.notifications],
        }));

        if (autoRemove) {
          setTimeout(() => {
            get().removeNotification(id);
          }, autoRemoveTimeout);
        }
      };

      delay ? setTimeout(handleNotification, delay) : handleNotification();

      return id;
    },

    removeNotification: (id) => {
      //Just set isVisible property of notification to false to make it fade out
      set((state) => {
        const notifications = state.notifications.map((notification) => {
          if (notification.id === id) {
            notification.isVisible = false;
          }

          return notification;
        });

        return { notifications };
      });

      // Delay removal of dom element to wait for notification fade out in AnimatedNotification
      window.setTimeout(() => {
        set((state) => {
          const notifications = state.notifications.filter(
            (notification) => notification.id !== id
          );

          return { notifications };
        });
      }, 600);
    },
  };
});

export default notificationService;
