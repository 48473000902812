import { FC, HTMLAttributes } from "react";

import { StyledButtonRound } from "./styles";

type TButtonRound = HTMLAttributes<HTMLDivElement>;

interface IProps {
  icon: React.ReactNode;
  onClick: Function;
  mid?: boolean;
  big?: boolean;
  large?: boolean;
  forwardRef?: any;
}

const ButtonRound: FC<IProps & TButtonRound> = ({
  icon,
  onClick,
  big = false,
  mid = false,
  large = false,
  forwardRef = null,
  ...others
}) => {
  return (
    <StyledButtonRound
      large={large}
      big={big}
      mid={mid}
      onClick={onClick}
      ref={forwardRef}
      {...others}
    >
      {icon}
    </StyledButtonRound>
  );
};

export default ButtonRound;
