import {
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
} from "@twilio/video-processors";
import React from "react";
import {
  connect,
  createLocalVideoTrack,
  createLocalAudioTrack,
  Room,
  LocalVideoTrack,
  LocalDataTrack,
  RemoteParticipant,
  VideoProcessor,
} from "twilio-video";
import create from "zustand";

import {
  AvailableVideoEffects,
  EMessageType,
  TLocalParticipant,
  TMessage,
  TVideoGrid,
  TVideoUserState,
  TAvailableDevice,
} from "./types";

import UserName from "@/components/Dom/Common/UserName";
import StatusNotification from "@/components/Dom/Notifications/StatusNotification";
import VideoNotification from "@/components/Dom/VideoChat/VideoNotification";

import soundService from "@/services/AudioService";
import debugService from "@/services/DebugService";
import navService from "@/services/NavService";
import notificationService from "@/services/NotificationService";
import { playerService } from "@/services/PlayerService";
import rehService from "@/services/RehService";
import sceneService, {
  TTriggerAreaData,
  TVideoData,
} from "@/services/SceneService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import localStorage from "@/utils/LocalStorage";

export type TVideoService = {
  callState:
    | "available"
    | "calling"
    | "callingGroup"
    | "gettingCall"
    | "gettingGroupCall"
    | "leaving"
    | "inCall"
    | "callDeclined"
    | "error";
  notificationId: string | null;
  notificationSoundId: string | null;
  autoDeclineFunction: ReturnType<typeof setTimeout> | null;
  cameraAvailable: boolean;

  currentRoom: null | Room;
  currentRoomId: null | string; // TODO: data duplication, same as currentRoom.name
  isStaticRoom: boolean;
  currentRoomOwnerId: null | number | string;
  currentShareAreaName: string | null;
  currentRoomActiveParticipants: number;

  shareParticipantSid: string | null;
  localParticipant: TLocalParticipant;
  invitedUserIds: Array<string>;
  allUsersCallState: Map<number, any>;

  isShareActive: boolean;
  isSpaceShareActive: boolean;
  isFullscreen: boolean;
  isExtendedScreenShare: boolean;
  videoGrid: TVideoGrid;
  showParticipantsList: boolean;
  availableVideoDevices: Array<TAvailableDevice>;
  availableAudioInputDevices: Array<TAvailableDevice>;
  availableAudioOutputDevices: Array<TAvailableDevice>;
  selectedCamera: string;
  selectedAudioOutput: string;
  selectedAudioInput: string;
  dominantSpeaker: string;
  isInStaticCall: () => boolean;

  init: () => Promise<boolean>;
  getAvailableDevices: () => void;

  showVideoNotification: (userIds: Array<TUser["id"]>, callState: any) => void;
  removeVideoNotification: () => void;

  toggleParticipantsList: () => void;
  toggleMicrophone: () => void;
  toggleCamera: () => void;
  switchCamera: (deviceId: string) => void;
  switchAudioInput: (deviceId: string) => void;
  switchAudioOutput: (deviceId: string) => void;
  toggleVideoBackground: (
    videoProcessor: VideoProcessor,
    effectName: AvailableVideoEffects
  ) => void;
  toggleBlurredBackground: () => void;
  toggleBackgroundImage: () => void;
  toggleVideoGrid: (videoGridType: TVideoGrid) => void;
  toggleShare: () => void;
  toggleSpaceShare: (mediaAreaName: string) => void;
  switchToExtendedScreenShare: () => void;
  endRaisedHand: () => void;
  setRaisedHand: (status: boolean) => void;
  toggleRaisedHand: () => void;
  setDominantSpeaker: (participant: RemoteParticipant) => void;
  endDominantSpeaker: () => void;

  startSpaceShareOnModuleJoin: () => void;
  startSpaceShare: () => void;
  stopSpaceShare: () => void;
  openFullscreen: () => void;
  closeFullscreen: () => void;
  toggleExtendedScreenShare: () => void;

  createRoom: (userIds: Array<TUser["id"]>) => void;
  reconnect: () => void;

  join: (room: string) => void;
  joinStaticRoom: (roomName: string) => void;
  leave: (room: string) => void;
  invite: (room: string, clientIds: Array<number>) => void;
  accept: (room: string) => void;
  decline: (room: string, ownerId?: number | string) => void;
  cancel: (room: string) => void;
  sendMessage: (message: TMessage) => void;

  onRoomCreated: (body: { roomId: string; clientIds: Array<string> }) => void;
  onRoomInvited: (body: {
    ownerId: number;
    roomId: string;
    participants: { userId: TUser["id"] }[];
    waitForAccept: boolean;
  }) => void;
  onRoomInviteAccepted: (body: {
    participantId: number;
    roomId: string;
  }) => void;
  onRoomInviteDeclined: (body: {
    participantId: number;
    roomId: string;
  }) => void;
  onRoomCanceled: (body: { roomId: string }) => void;
  onRoomJoined: (body: { roomId: string; token: string }) => void;
  onRoomLeft: () => void;
  onParticipantDisconnected: (participant: RemoteParticipant) => void;
  onParticipantConnected: (participant: RemoteParticipant) => void;

  onMessage: (message: string) => void;

  onServerVideoCallState: ({
    videoStateKeys: [string],
    videoStateValues: any,
  }) => void;
  onUserStateChanged: (state: TVideoUserState) => void;
  getOwnDataTrack: () => LocalDataTrack;
};

// From: https://stackoverflow.com/a/23289012
const detectDevice = async (deviceKind: string) => {
  const md = navigator.mediaDevices;
  if (!md || !md.enumerateDevices) return [];

  const devices = await md.enumerateDevices();
  return devices.filter((device: MediaDeviceInfo) => {
    if (device.kind === deviceKind) return device;
    return null;
  });
};

const checkDeviceAvailability = (
  devices: Array<TAvailableDevice>,
  deviceId: string
) =>
  deviceId &&
  deviceId.length &&
  devices.some((device) => device.deviceId === deviceId);

const videoService = create<TVideoService>((set, get) => {
  const isPermissionError = (error) =>
    error.toString().toLowerCase().includes("notallowederror");

  const logState = (
    event: string,
    extraVariables: { name: string; value: any }[] = []
  ) => {
    const {
      callState,
      currentRoom,
      currentRoomId,
      notificationId,
      isStaticRoom,
      currentRoomOwnerId,
      localParticipant,
      allUsersCallState,
    } = get();

    console.log(
      `Event: ${event} \n`,
      "callState:",
      callState,
      "currentRoom:",
      currentRoom,
      "currentRoomId:",
      currentRoomId,
      "isStaticRoom:",
      isStaticRoom,
      "currentRoomOwnerId:",
      currentRoomOwnerId,
      "localParticipant:",
      localParticipant,
      "notificationId:",
      notificationId,
      "allUsersCallState:",
      allUsersCallState,
      `\n`,
      extraVariables.map(({ name, value }) => `${name}: ${value}`)
    );
  };

  return {
    rehNetworkService: null,

    notificationId: null,
    notificationSoundId: null,
    autoDeclineFunction: null,
    cameraAvailable: false,
    callState: "available",

    currentRoom: null,
    currentRoomId: null,
    isStaticRoom: false,
    currentRoomOwnerId: null,
    currentShareAreaName: null,
    currentRoomActiveParticipants: 0,

    shareParticipantSid: null,
    localParticipant: {
      isMuted: false,
      hasCamera: true,
      isSharing: false,
      isSpaceSharing: false,
      blurredVideoBackground: false,
      imageVideoBackground: false,
      hasRaisedHand: false,
    },
    invitedUserIds: [],
    allUsersCallState: new Map(),

    isShareActive: false,
    isSpaceShareActive: false,
    isFullscreen: false,
    isExtendedScreenShare: false,
    videoGrid: TVideoGrid.SMALL,
    showParticipantsList: false,
    availableVideoDevices: [],
    availableAudioInputDevices: [],
    availableAudioOutputDevices: [],
    selectedCamera: localStorage.get("selectedCamera"),
    selectedAudioOutput: localStorage.get("selectedAudioOutput"),
    selectedAudioInput: localStorage.get("selectedAudioInput"),
    dominantSpeaker: "",

    isInStaticCall: () => Boolean(get().currentRoomId && get().isStaticRoom),

    init: () => {
      return new Promise<boolean>(async (resolve, reject) => {
        const { subscribe } = rehService.getState();

        subscribe(
          "communication",
          "SERVER_VIDEO_CALL_STATE",
          get().onServerVideoCallState
        );
        subscribe(
          "communication",
          "VIDEO_USER_STATE_CHANGED",
          get().onUserStateChanged
        );

        subscribe("communication", "VIDEO_ROOM_CREATED", get().onRoomCreated);
        subscribe("communication", "VIDEO_ROOM_INVITED", get().onRoomInvited);
        subscribe(
          "communication",
          "VIDEO_ROOM_ACCEPTED",
          get().onRoomInviteAccepted
        );
        subscribe(
          "communication",
          "VIDEO_ROOM_DECLINED",
          get().onRoomInviteDeclined
        );
        subscribe("communication", "VIDEO_ROOM_CANCELED", get().onRoomCanceled);
        subscribe("communication", "VIDEO_ROOM_JOINED", get().onRoomJoined);
        subscribe("communication", "VIDEO_ROOM_LEFT", get().onRoomLeft);

        try {
          get().getAvailableDevices();

          resolve(true);
          return;
        } catch (error) {
          reject(error);
          return;
        }
      });
    },

    getAvailableDevices: async () => {
      const camerasAvailable = await detectDevice("videoinput");
      const audioDevices = await detectDevice("audioinput");
      const audioOutput = await detectDevice("audiooutput");

      set({ cameraAvailable: Boolean(camerasAvailable.length) });
      set({ availableVideoDevices: camerasAvailable });
      set({ availableAudioInputDevices: audioDevices });
      set({ availableAudioOutputDevices: audioOutput });
    },

    showVideoNotification: (userIds, callState) => {
      logState("showVideoNotification", [
        { name: "userIds", value: userIds },
        { name: "callState", value: callState },
      ]);
      if (get().notificationId || get().currentRoom) return;

      const { localParticipant, cameraAvailable } = get();

      localParticipant.hasCamera = cameraAvailable;
      localParticipant.isMuted = false;

      const audioFile = callState.includes("calling")
        ? "call-outgoing.mp3"
        : "call-incoming.mp3";

      // @ts-ignore
      const ownUserId = userService.getState().ownUser.id;

      const filteredUserIds = userIds.filter((userId) => userId !== ownUserId);

      set({
        localParticipant,
        callState,
        notificationSoundId: soundService
          .getState()
          .play(`/assets/audio/${audioFile}`, { name: "", loop: true }),
        notificationId: notificationService
          .getState()
          .addNotification(<VideoNotification userIds={filteredUserIds} />, {
            position: "right",
            autoRemove: false,
          }),
      });
    },

    removeVideoNotification: () => {
      const { notificationId, notificationSoundId, autoDeclineFunction } =
        get();

      if (notificationSoundId)
        soundService.getState().stop(notificationSoundId);

      if (notificationId) {
        notificationService.getState().removeNotification(notificationId);

        if (autoDeclineFunction) clearTimeout(autoDeclineFunction);

        set({
          notificationId: null,
          notificationSoundId: null,
          autoDeclineFunction: null,
        });
      }
    },

    toggleMicrophone: async () => {
      const {
        currentRoom,
        localParticipant,
        selectedAudioInput,
        availableAudioInputDevices,
      } = get();

      localParticipant.isMuted = !localParticipant.isMuted;

      if (currentRoom !== null) {
        if (
          localParticipant.isMuted === false &&
          currentRoom.localParticipant.audioTracks.size === 0
        ) {
          const localAudioTrack = await createLocalAudioTrack({
            ...(checkDeviceAvailability(
              availableAudioInputDevices,
              selectedAudioInput
            ) && { deviceId: { exact: selectedAudioInput } }),
          });
          await currentRoom.localParticipant.publishTrack(localAudioTrack);
        }

        currentRoom.localParticipant.audioTracks.forEach((publication) => {
          if (localParticipant.isMuted === true) {
            publication.track.disable();
          } else {
            publication.track.enable();
          }
        });
      }

      set({ localParticipant });
    },

    switchCamera: async (deviceId: string) => {
      const { currentRoom, cameraAvailable } = get();
      if (!cameraAvailable) return;
      if (currentRoom === null) return;

      // remove current tracks
      currentRoom.localParticipant.videoTracks.forEach((publication) => {
        if (publication.track.name !== "screen") {
          publication.track.stop();
          publication.unpublish();
        }
      });

      set({ selectedCamera: deviceId });
      localStorage.set("selectedCamera", deviceId);

      const localVideoTrack = await createLocalVideoTrack({
        deviceId: { exact: deviceId },
        width: 300,
        frameRate: 20,
      });

      await currentRoom.localParticipant.publishTrack(localVideoTrack, {
        priority: "standard",
      });
    },

    switchAudioInput: async (deviceId: string) => {
      const { currentRoom, cameraAvailable } = get();
      if (!cameraAvailable) return;
      if (currentRoom === null) return;

      currentRoom.localParticipant.audioTracks.forEach((publication) => {
        publication.track.stop();
        currentRoom.localParticipant.unpublishTrack(publication.track);
      });

      set({ selectedAudioInput: deviceId });
      localStorage.set("selectedAudioInput", deviceId);

      const localAudioTrack = await createLocalAudioTrack({
        deviceId: { exact: deviceId },
      });
      await currentRoom.localParticipant.publishTrack(localAudioTrack);
    },

    switchAudioOutput: async (deviceId: string) => {
      const { currentRoom, cameraAvailable } = get();
      if (!cameraAvailable) return;
      if (currentRoom === null) return;

      set({ selectedAudioOutput: deviceId });
      localStorage.set("selectedAudioOutput", deviceId);

      const elements = document.getElementsByTagName("audio");
      for (const element of elements as any) {
        element.setSinkId(deviceId);
      }
    },

    toggleCamera: async () => {
      const {
        currentRoom,
        localParticipant,
        cameraAvailable,
        selectedCamera,
        availableVideoDevices,
      } = get();
      if (!cameraAvailable) return;

      if (currentRoom !== null) {
        if (localParticipant.hasCamera === true) {
          currentRoom.localParticipant.videoTracks.forEach((publication) => {
            if (publication.track.name !== "screen") {
              publication.track.stop();
              publication.unpublish();
            }
          });
        } else {
          const localVideoTrack = await createLocalVideoTrack({
            ...(checkDeviceAvailability(
              availableVideoDevices,
              selectedCamera
            ) && {
              deviceId: { exact: selectedCamera },
            }),
            width: 300,
            frameRate: 20,
          });
          await currentRoom.localParticipant.publishTrack(localVideoTrack, {
            priority: "standard",
          });
        }
      }

      localParticipant.hasCamera = !localParticipant.hasCamera;
      set({ localParticipant });
    },
    toggleVideoBackground: async (videoProcessor, effectName) => {
      const { currentRoom, localParticipant } = get();

      /**
       * returns prev enabled video effects
       * this functions checks if an available video effect exists in localParticipant state and filters out the currently selected effect.
       */
      const enabledEffects = Object.keys(localParticipant).filter(
        (item) =>
          Object.values(AvailableVideoEffects).includes(
            item as string as AvailableVideoEffects
          ) &&
          localParticipant[item] &&
          item !== effectName
      );
      if (currentRoom !== null) {
        currentRoom.localParticipant.videoTracks.forEach((publication) => {
          if (publication.track.name !== "screen") {
            if (publication.track.processor === null) {
              publication.track.addProcessor(videoProcessor);
            } else if (enabledEffects.length) {
              enabledEffects.forEach(
                (element) => (localParticipant[element] = false)
              );
              publication.track.removeProcessor(publication.track.processor);
              publication.track.addProcessor(videoProcessor);
            } else {
              publication.track.removeProcessor(publication.track.processor);
            }
          }
        });
      }

      localParticipant[effectName] = !localParticipant[effectName];
      set({ localParticipant });
    },
    toggleBlurredBackground: async () => {
      const videoProcessor = new GaussianBlurBackgroundProcessor({
        assetsPath: "/twilio/",
        maskBlurRadius: 10,
        blurFilterRadius: 5,
      });
      await videoProcessor.loadModel();
      get().toggleVideoBackground(
        videoProcessor,
        AvailableVideoEffects.BlurredVideoBackground
      );
    },
    toggleBackgroundImage: async () => {
      const img = new Image();
      img.src = "/assets/backgrounds/background.jpeg";

      img.onload = async () => {
        const videoProcessor = new VirtualBackgroundProcessor({
          assetsPath: "/twilio/",
          backgroundImage: img,
        });
        await videoProcessor.loadModel();
        get().toggleVideoBackground(
          videoProcessor,
          AvailableVideoEffects.ImageVideoBackground
        );
      };
    },
    endRaisedHand: () => {
      const { currentRoom, setRaisedHand } = get();
      if (!currentRoom) return;
      setRaisedHand(false);
    },
    toggleRaisedHand: () => {
      const { currentRoom, localParticipant, setRaisedHand } = get();
      if (!currentRoom) return;
      setRaisedHand(!localParticipant.hasRaisedHand);
    },
    setRaisedHand: (status: boolean) => {
      const { currentRoom, localParticipant, endRaisedHand } = get();
      if (!currentRoom) return;

      localParticipant.hasRaisedHand = status;

      get().sendMessage({
        type: EMessageType.RAISE_HAND,
        data: { status: localParticipant.hasRaisedHand },
      });

      set({ localParticipant });

      // we automatically remove the raised hand after 1 minute
      if (localParticipant.hasRaisedHand) setTimeout(endRaisedHand, 60000);
    },
    setDominantSpeaker: (participantRemote: RemoteParticipant) => {
      const { currentRoom } = get();
      if (!currentRoom || !participantRemote) return;

      if (participantRemote.sid) {
        set({ dominantSpeaker: participantRemote.sid });
      }
      setTimeout(get().endDominantSpeaker, 5000);
    },
    endDominantSpeaker: () => {
      set({ dominantSpeaker: "" });
    },
    toggleParticipantsList: async () => {
      const { showParticipantsList } = get();
      set({ showParticipantsList: !showParticipantsList });
    },
    toggleShare: async () => {
      const { currentRoom } = get();
      if (!currentRoom) return;

      if (get().localParticipant.isSharing === true) {
        currentRoom.localParticipant.videoTracks.forEach((publication) => {
          if (publication.track.name === "screen") {
            publication.track.stop();
            publication.unpublish();
          }
        });
        get().closeFullscreen();
      } else {
        try {
          const stream = await navigator.mediaDevices.getDisplayMedia({
            video: { width: 1920, height: 1080, frameRate: 12 },
          });
          const screenTrack = new LocalVideoTrack(stream.getTracks()[0], {
            name: "screen",
            logLevel: "error",
          });

          //Catch a user leaving the call while localParticipant is currently in the screenshare dialogue
          if (!get().currentRoom) return;

          // https://www.twilio.com/docs/video/tutorials/using-track-priority-api
          await currentRoom.localParticipant.publishTrack(screenTrack, {
            priority: "high",
          });

          screenTrack.once("stopped", () => {
            currentRoom.localParticipant.unpublishTrack(screenTrack);
          });
        } catch (error) {
          if (isPermissionError(error))
            notificationService
              .getState()
              .addNotification(
                <StatusNotification
                  text={
                    "Failed to share screen. Please check you system permissions."
                  }
                  type={"danger"}
                />,
                { position: "right", autoRemoveTimeout: 8000 }
              );

          // eslint-disable-next-line no-console
          console.error(`videoService::onRoomJoined(): toggleShare = ${error}`);
          get().stopSpaceShare();
          return;
        }
      }
    },

    toggleVideoGrid: (videoGridType: TVideoGrid) => {
      set({ videoGrid: videoGridType });
    },
    toggleSpaceShare: (mediaAreaName) => {
      const { isShareActive, localParticipant, isSpaceShareActive } = get();

      // Click on media area when screenShare is already running
      if (isShareActive && localParticipant.isSharing && !isSpaceShareActive) {
        set({
          isSpaceShareActive: true,
          currentShareAreaName: mediaAreaName,
          isFullscreen: false,
        });
        get().startSpaceShare();
        get().sendMessage({
          type: EMessageType.SPACE_SHARE_STARTED,
          data: mediaAreaName,
        });
      } else if (
        !isShareActive &&
        !localParticipant.isSharing &&
        !isSpaceShareActive
      ) {
        //Click directly on media and screenShare is not running
        set({
          isSpaceShareActive: true,
          currentShareAreaName: mediaAreaName,
          isFullscreen: false,
        });
        get().toggleShare();
      } else {
        set({ isSpaceShareActive: false });
        get().toggleShare();
      }
    },

    switchToExtendedScreenShare: () => {
      const {
        isShareActive,
        localParticipant,
        isSpaceShareActive,
        currentShareAreaName,
      } = get();

      if (isShareActive && isSpaceShareActive) {
        if (!currentShareAreaName) return;

        // if the user who shares clicks the extend btn
        if (localParticipant.isSharing) {
          const { hasVideo, removeVideo } = sceneService.getState();

          const mediaArea = sceneService
            .getState()
            .mediaAreas.find(
              (mediaArea) => mediaArea.userData.name === currentShareAreaName
            );

          if (!mediaArea) return;

          const videoData: TVideoData | undefined = hasVideo(mediaArea.id);

          if (!videoData) return;

          removeVideo(videoData);

          set({
            isSpaceShareActive: false,
            isExtendedScreenShare: true,
            isFullscreen: false,
          });
        } else {
          set({
            isExtendedScreenShare: true,
          });
        }
      }
    },

    startSpaceShareOnModuleJoin: () => {
      const { currentRoomId } = get();
      if (currentRoomId) {
        const { isSpaceShareActive } = get();
        if (isSpaceShareActive) {
          const { currentShareAreaName } = get();
          if (currentShareAreaName) get().startSpaceShare();
        }
      }
    },
    startSpaceShare: () => {
      const { currentShareAreaName } = get();

      if (!currentShareAreaName) return;

      const { hasVideo, addVideo } = sceneService.getState();

      const mediaArea = sceneService
        .getState()
        .mediaAreas.find(
          (mediaArea) => mediaArea.userData.name === currentShareAreaName
        );

      if (!mediaArea) return;

      let videoData: TVideoData | undefined = hasVideo(mediaArea.id);

      if (videoData) return;

      videoData = {
        url: "",
        muted: true,
        type: "scene_screen_share",
        meshId: mediaArea.id,
        meshType: "mediaArea",
      };

      addVideo(videoData);
      return;
    },

    stopSpaceShare: () => {
      const { currentShareAreaName } = get();

      if (!currentShareAreaName) return;

      const { hasVideo, removeVideo } = sceneService.getState();

      const mediaArea = sceneService
        .getState()
        .mediaAreas.find(
          (mediaArea) => mediaArea.userData.name === currentShareAreaName
        );

      if (!mediaArea) return;

      const videoData: TVideoData | undefined = hasVideo(mediaArea.id);

      if (!videoData) return;

      removeVideo(videoData);
      set({
        currentShareAreaName: null,
        isSpaceShareActive: false,
        shareParticipantSid: null,
        isShareActive: false,
      });
    },

    toggleExtendedScreenShare: () => {
      const { isExtendedScreenShare } = get();
      set({ videoGrid: TVideoGrid.SMALL });
      set({
        isExtendedScreenShare: !isExtendedScreenShare,
        isFullscreen: false,
      });
    },

    openFullscreen: () => {
      set({ videoGrid: TVideoGrid.SMALL });
      set({ isFullscreen: true, isExtendedScreenShare: false });
      navService.getState().hideNav();
    },

    closeFullscreen: () => {
      set({ isFullscreen: false, isExtendedScreenShare: false });
      navService.getState().showNav();
    },

    createRoom: async (userIds) => {
      logState("createRoom", [{ name: "userIds", value: userIds }]);
      // ToDo(Max) After the request own user  data the own user is not longer included in the chat users we use here
      // I did this tmp hotfix to make 1:1 calls work again but lets discuss and find a better solution in the future -Eric

      // Add own user id to array
      const ownUser: TUser | null = userService.getState().getOwnUser();
      if (!ownUser) {
        debugService
          .getState()
          .logError(`userService::createRoom(): Did not found own user!`);
        return;
      }

      rehService.getState().sendMessage("communication", "VIDEO_CREATE", {
        clientIds: [...userIds, ownUser.id],
      });

      const callState = userIds.length > 2 ? "callingGroup" : "calling";

      get().showVideoNotification(userIds, callState);
    },

    reconnect: () => {
      const { currentRoomId } = get();
      logState("reconnect");

      if (currentRoomId) {
        rehService.getState().sendMessage("communication", "VIDEO_RECONNECT", {
          roomId: currentRoomId,
        });
      }
    },

    joinStaticRoom: async (roomName) => {
      logState("joinStaticRoom", [{ name: "roomName", value: roomName }]);

      rehService
        .getState()
        .sendMessage("communication", "VIDEO_JOIN_STATIC_ROOM", {
          roomName,
        });
    },
    join: async (roomId) => {
      logState("join", [{ name: "roomId", value: roomId }]);

      rehService
        .getState()
        .sendMessage("communication", "VIDEO_JOIN", { roomId });
    },
    leave: async (roomId) => {
      logState("leave", [{ name: "roomId", value: roomId }]);

      set({ callState: "leaving" });

      rehService
        .getState()
        .sendMessage("communication", "VIDEO_LEAVE", { roomId });

      // display media area cta in current trigger area
      get().stopSpaceShare();

      const curTriggerAreaName: string = roomId.split("_")[1];
      const curTriggerArea: TTriggerAreaData | null =
        playerService.getState().curTriggerArea;

      // return early if we are in no trigger area

      if (!curTriggerArea || !curTriggerAreaName) return;

      sceneService.getState().setTriggerAreaState(curTriggerArea.name, {
        isVisible: true,
        isActive: true,
      });
    },
    invite: async (roomId, clientIds) => {
      logState("invite", [
        { name: "roomId", value: roomId },
        { name: "clientIds", value: clientIds },
      ]);

      if (!get().currentRoom) return;

      const ownUser: TUser | null = userService.getState().getOwnUser();
      if (!ownUser) return;

      console.log(
        "invite - clientIds",
        !clientIds.find((clientId) => clientId === ownUser.id)
          ? [...clientIds, ownUser.id]
          : clientIds
      );

      rehService.getState().sendMessage("communication", "VIDEO_INVITE", {
        roomId,
        // Pass in the ownUserId if it's not in there
        clientIds: !clientIds.find((clientId) => clientId === ownUser.id)
          ? [...clientIds, ownUser.id]
          : clientIds,
      });

      // ToDo: Error handle
      const users = await userService.getState().getUsersByIds(clientIds);

      notificationService.getState().addNotification(
        <StatusNotification
          text={
            <>
              Invited <UserName users={users} applyFontStyle={false} />
            </>
          }
        />
      );
    },
    accept: async (roomId) => {
      logState("accept", [{ name: "roomId", value: roomId }]);

      const { currentRoomOwnerId } = get();

      rehService.getState().sendMessage("communication", "VIDEO_ACCEPT", {
        roomId,
        ownerId: currentRoomOwnerId,
      });

      get().removeVideoNotification();
    },
    decline: async (roomId, ownerId) => {
      logState("decline", [
        { name: "roomId", value: roomId },
        { name: "ownerId", value: ownerId },
      ]);

      const { currentRoomId, currentRoomOwnerId } = get();
      get().removeVideoNotification();

      rehService.getState().sendMessage("communication", "VIDEO_DECLINE", {
        roomId,
        ownerId: ownerId || currentRoomOwnerId,
      });

      if (roomId === currentRoomId) {
        set({
          currentRoomId: null,
        });
      }
    },
    cancel: async (roomId) => {
      logState("cancel", [{ name: "roomId", value: roomId }]);

      get().removeVideoNotification();

      rehService.getState().sendMessage("communication", "VIDEO_CANCEL", {
        roomId,
      });

      set({ currentRoomId: null, invitedUserIds: [] });
    },

    onRoomCreated: ({ roomId, clientIds }) => {
      logState("onRoomCreated", [
        { name: "roomId", value: roomId },
        { name: "clientIds", value: clientIds },
      ]);

      set({
        currentRoomId: roomId,
        invitedUserIds: clientIds,
      });
    },
    onRoomInvited: ({
      ownerId,
      roomId,
      participants = [],
      waitForAccept = true,
    }) => {
      logState("onRoomInvited", [
        { name: "ownerId", value: ownerId },
        { name: "roomId", value: roomId },
        { name: "participants", value: participants },
        { name: "waitForAccept", value: waitForAccept },
      ]);
      const { currentRoomId } = get();
      if (roomId === currentRoomId) return;

      if (waitForAccept && currentRoomId) {
        setTimeout(() => {
          get().decline(roomId, ownerId);
        }, 1000);
        return;
      }

      set({
        currentRoomId: roomId,
        currentRoomOwnerId: ownerId,
        autoDeclineFunction: null,
      });

      if (waitForAccept) {
        const callState =
          participants.length > 2 ? "gettingGroupCall" : "gettingCall";

        get().showVideoNotification(
          participants.map((participant) => participant.userId),
          callState
        );

        //Automatically decline the call after 15 seconds
        set({
          autoDeclineFunction: setTimeout(() => {
            if (
              !get().currentRoom ||
              (get().currentRoom && get().currentRoomId !== roomId)
            ) {
              get().decline(roomId, ownerId);
            }
          }, 15000),
        });
      } else {
        const { localParticipant, cameraAvailable } = get();
        localParticipant.hasCamera = cameraAvailable;
        localParticipant.isMuted = false;

        set({ localParticipant, isStaticRoom: ownerId.toString() === roomId });

        const ownUser: TUser | null = userService.getState().getOwnUser();
        if (!ownUser) return;

        get().onRoomInviteAccepted({
          participantId: ownUser.id,
          roomId,
        });
      }
    },

    getOwnDataTrack: () => {
      return get().currentRoom?.localParticipant.dataTracks.values().next()
        .value.track;
    },

    sendMessage: (message) => {
      const dataTrack: LocalDataTrack = get().getOwnDataTrack();
      if (message && dataTrack) {
        dataTrack.send(JSON.stringify(message));
      }
    },

    onRoomInviteAccepted: ({ participantId, roomId }) => {
      const { currentRoom, currentRoomId, isStaticRoom } = get();

      logState("onRoomInviteAccepted", [
        { name: "participantId", value: participantId },
        { name: "roomId", value: roomId },
      ]);

      if (!isStaticRoom && currentRoom && currentRoomId === roomId) return;

      if (currentRoom && currentRoomId) get().onRoomLeft();

      get().join(roomId);
    },
    onRoomInviteDeclined: ({ participantId, roomId }) => {
      logState("onRoomInviteDeclined", [
        { name: "participantId", value: participantId },
        { name: "roomId", value: roomId },
      ]);

      if (roomId !== get().currentRoomId) return;

      const {
        invitedUserIds,
        leave,
        removeVideoNotification,
        onRoomLeft,
        currentRoom,
      } = get();

      const filteredInvitedUserIds = invitedUserIds.filter(
        (userId) => userId.toString() !== participantId.toString()
      );

      if (
        filteredInvitedUserIds.length === 0 &&
        !currentRoom?.participants.size
      ) {
        leave(roomId);

        // Manually call onRoomLeft because we didn't join the room
        // so we don't get the VIDEO_ROOM_LEFT message
        onRoomLeft();

        set({ callState: "callDeclined" });
        setTimeout(removeVideoNotification, 1500);
        setTimeout(() => {
          set({ callState: "available" });
        }, 2100);
      }

      set({ invitedUserIds: filteredInvitedUserIds });
    },
    onRoomCanceled: ({ roomId }) => {
      logState("onRoomCanceled", [{ name: "roomId", value: roomId }]);

      if (get().currentRoomId !== roomId) return;

      const { leave, removeVideoNotification } = get();
      leave(roomId);

      set({ callState: "error" });

      setTimeout(removeVideoNotification, 1500);
      setTimeout(() => {
        set({ callState: "available" });
      }, 2100);
    },
    onRoomJoined: async ({ roomId, token }) => {
      logState("onRoomJoined", [
        { name: "roomId", value: roomId },
        { name: "token", value: token },
      ]);
      const {
        localParticipant,
        cameraAvailable,
        availableVideoDevices,
        availableAudioInputDevices,
        selectedCamera,
        selectedAudioInput,
        selectedAudioOutput,
      } = get();

      get().removeVideoNotification();

      const tracks = Array<any>();
      const dataTrack = new LocalDataTrack();
      tracks.push(dataTrack);

      if (!localParticipant.isMuted) {
        try {
          const localAudioTrack = await createLocalAudioTrack({
            ...(checkDeviceAvailability(
              availableAudioInputDevices,
              selectedAudioInput
            ) && { deviceId: { exact: selectedAudioInput } }),
          });
          tracks.push(localAudioTrack);
        } catch (error) {
          if (isPermissionError(error))
            notificationService
              .getState()
              .addNotification(
                <StatusNotification
                  text={
                    "Failed to access audio source. Please check you system permissions."
                  }
                  type={"danger"}
                />,
                { position: "right", autoRemoveTimeout: 8000 }
              );

          // eslint-disable-next-line no-console
          console.error(
            `videoService::onRoomJoined(): createLocalAudioTrack = ${error}`
          );
          get().onRoomLeft();
          return;
        }
      }
      if (localParticipant.hasCamera && cameraAvailable) {
        try {
          const localVideoTrack = await createLocalVideoTrack({
            width: 300,
            frameRate: 20,
            ...(checkDeviceAvailability(
              availableVideoDevices,
              selectedCamera
            ) && { deviceId: { exact: selectedCamera } }),
          });
          tracks.push(localVideoTrack);
        } catch (error) {
          if (isPermissionError(error))
            notificationService
              .getState()
              .addNotification(
                <StatusNotification
                  text={
                    "Failed to access video source. Please check you system permissions."
                  }
                  type={"danger"}
                />,
                { position: "right", autoRemoveTimeout: 8000 }
              );

          // eslint-disable-next-line no-console
          console.error(
            `videoService::onRoomJoined(): createLocalVideoTrack = ${error}`
          );
          get().onRoomLeft();
          return;
        }
      }

      try {
        // https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#collaboration-mode
        // https://www.twilio.com/docs/video/tutorials/using-bandwidth-profile-api
        const currentRoom = await connect(token, {
          //logLevel: "debug",
          name: roomId,
          tracks,
          //audio: !localParticipant.isMuted,
          //video: localParticipant.hasCamera && { width: 320, frameRate: 15 },
          maxAudioBitrate: 16000,
          // ToDo(Eric / Max) Can we use the default variable max outgoing video bitrate with the VP8 codec?
          maxVideoBitrate: 16000000,
          preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
          networkQuality: { local: 1, remote: 1 },
          preferredAudioCodecs: [{ codec: "opus", dtx: false }],
          bandwidthProfile: {
            video: {
              mode: "grid", // grid or presentation
              maxSubscriptionBitrate: 16000000,
              trackSwitchOffMode: "detected",
            },
          },
          automaticSubscription: true,
          dominantSpeaker: true,
        });

        console.log("onRoomJoined - currentRoom", currentRoom);

        // Catches a user leaving the call before connecting to the room
        if (get().callState === "leaving") {
          set({ currentRoom });
          get().onRoomLeft();
          return;
        }

        currentRoom.on("trackMessage", get().onMessage);
        currentRoom.on(
          "participantDisconnected",
          get().onParticipantDisconnected
        );
        currentRoom.on("participantConnected", get().onParticipantConnected);
        currentRoom.on("dominantSpeakerChanged", (participant) =>
          get().setDominantSpeaker(participant)
        );

        currentRoom.on("trackSubscribed", (track) => {
          if (track.kind === "audio") {
            const audioElement = track.attach();
            // @ts-ignore
            audioElement.setSinkId(selectedAudioOutput);
          }
        });

        currentRoom.on("reconnected", () => {
          logState("currentRoom - reconnected");
          get().reconnect();

          notificationService
            .getState()
            .addNotification(
              <StatusNotification text={"Reconnected to the call"} />
            );
        });

        currentRoom.on("reconnecting", (error) => {
          logState("currentRoom - reconnecting", [
            { name: "Error", value: error },
          ]);

          notificationService
            .getState()
            .addNotification(
              <StatusNotification
                text={"Trying to reconnect to the call"}
                type={"danger"}
              />
            );
        });

        currentRoom.on("disconnected", (room, error) => {
          const { currentRoomId, leave, onRoomLeft } = get();
          if (!error || !currentRoomId) return;

          // Disconnected from the Twilio room
          // this makes you unable to hear anything so we need to kick this person
          console.log("disconnected", room, error);

          leave(currentRoomId);

          //To be safe call on room left, as there is a high chance that you also disconnect from the reh
          // and won't get a ON_ROOM_LEFT message when calling leave
          onRoomLeft();

          notificationService
            .getState()
            .addNotification(
              <StatusNotification
                text={`We detected problems with your video call. Please reload the page and join again.`}
                type={"danger"}
              />,
              { autoRemove: false }
            );
        });

        set({
          currentRoom,
          currentRoomId: roomId,
          callState: "inCall",
          autoDeclineFunction: null,
          currentRoomActiveParticipants: currentRoom.participants.size + 1,
          isStaticRoom: roomId === get().currentRoomOwnerId,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`videoService::onRoomJoined(): connect = ${error}`);
        get().onRoomLeft();
        return;
      }
    },
    onRoomLeft: () => {
      const { currentRoom, isFullscreen, cameraAvailable } = get();
      logState("onRoomLeft");

      if (currentRoom) {
        currentRoom.removeAllListeners();

        currentRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.stop();
          publication.unpublish();
        });

        currentRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.stop();
          publication.unpublish();
        });

        currentRoom.disconnect();
      }

      if (isFullscreen) navService.getState().showNav();

      //Remove self from allUsersCallState map, as the reh message is not received when disconnected from reh
      get().onUserStateChanged({
        type: "video_leave",
        userId: userService.getState().ownUser?.id,
      });

      set({
        currentRoom: null,
        currentRoomId: null,
        currentShareAreaName: null,
        isStaticRoom: false,
        invitedUserIds: [],
        callState: "available",
        isShareActive: false,
        isSpaceShareActive: false,
        isFullscreen: false,
        shareParticipantSid: null,
        localParticipant: {
          isMuted: false,
          hasCamera: cameraAvailable,
          isSharing: false,
          isSpaceSharing: false,
          blurredVideoBackground: false,
          imageVideoBackground: false,
          hasRaisedHand: false,
        },
      });
    },

    onParticipantConnected: (participant) => {
      const { currentRoomActiveParticipants } = get();
      set({ currentRoomActiveParticipants: currentRoomActiveParticipants + 1 });
    },
    onParticipantDisconnected: (participant) => {
      const {
        currentRoomId,
        currentRoom,
        isStaticRoom,
        shareParticipantSid,
        isFullscreen,
        currentRoomActiveParticipants,
      } = get();

      set({ currentRoomActiveParticipants: currentRoomActiveParticipants - 1 });

      if (participant.sid === shareParticipantSid || !shareParticipantSid) {
        get().stopSpaceShare();
        set({ isShareActive: false });

        //Close fullscreen when other person is sharing and leaves call without ending screenShare
        if (isFullscreen) get().closeFullscreen();
      }

      // disconnect from room automatically when no participant is left in
      // and room is not a public call space
      if (isStaticRoom || currentRoom?.participants.size || !currentRoomId)
        return;

      console.log("leave - onParticipantDisconnected");
      get().leave(currentRoomId);
    },

    onMessage: (message) => {
      const _message: TMessage = JSON.parse(message);

      if (!_message.type || !_message.data) return;

      switch (_message.type) {
        case EMessageType.SPACE_SHARE_STARTED: {
          if (typeof _message.data !== "string") return;

          set({
            isSpaceShareActive: true,
            currentShareAreaName: _message.data,
          });

          get().startSpaceShare();

          // ToDo Do we need this gate?
          //if (get().isShareActive) get().startSpaceShare();

          break;
        }
        case EMessageType.SPACE_SHARE_STOPPED: {
          if (typeof _message.data !== "string") return;

          get().stopSpaceShare();

          /*
          set({
            isSpaceShareActive: false,
            shareParticipantSid: null,
          });
           */
          break;
        }
        default:
          break;
      }
    },

    onServerVideoCallState: ({ videoStateKeys, videoStateValues }) => {
      // Can't send a map over websocket so we send the keys and values in an array and create a map out of them
      const allUsersCallState = new Map();

      videoStateKeys.forEach((key, i) => {
        allUsersCallState.set(Number(key), videoStateValues[`${i}`]);
      });

      set({ allUsersCallState });
    },
    onUserStateChanged: ({ type, userId }) => {
      if (!userId) return;
      const allUsersCallState = get().allUsersCallState;

      switch (type) {
        case "video_join": {
          allUsersCallState.set(userId, {});
          set({ allUsersCallState });
          break;
        }
        case "video_leave": {
          allUsersCallState.delete(userId);
          set({ allUsersCallState });
          break;
        }
      }
    },
  };
});

export default videoService;
